import { useCallback } from 'react'
import { submitTransactionOneOwner } from 'utils/callHelpers'
import { useUserData } from 'state/hooks'

const useContractInteractionEncodeOneOwner = (contractSafe?: any) => {
  // const contractSafe = useContract(abi,safeAddress, cav)
  // const contractDeploy = new cav.klay.Contract(deployAbi as any)
  const { account } = useUserData()
  const handleContractInteractionOneOwner = useCallback(
    async (dataSelectAbi?: any,valueAddress?: any, dataParam?: string) => {
      try {
        // const data = await cav.abi.encodeFunctionCall(dataSelectAbi[0],dataParam)
       
        const tx =await submitTransactionOneOwner(contractSafe,account,valueAddress,0 , dataParam)
       
        return {...tx, contractData: dataParam}
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error handleContractInteraction OneOwner', e)
      }
    },
    [account, contractSafe],
  )

  return { onContractInteractionEncodeOneOwner: handleContractInteractionOneOwner }
}

export default useContractInteractionEncodeOneOwner
