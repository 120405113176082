import React, { useState } from 'react'
import styled from 'styled-components'
// import { Link } from "components/Link";
// import { HelpAltGobit } from "components/Svg";
import { useTranslation } from 'react-i18next'
import { Button } from 'components/Button'
import { Flex } from 'components/Box'
import { ConnectorNames } from "components/WalletModal";
import { connected, border } from 'theme/variables'
import { Modal } from '../Modal'
import WalletCard from './WalletCard'
import config from './config'
import { Login } from './types'

interface Props {
  login: Login
  onDismiss?: () => void
}

// const HelpLink = styled(Link)`
//   display: flex;
//   align-self: center;
//   align-items: center;
//   margin-top: 24px;
// `;

const ModalConnect = styled(Modal)`
  /* border: solid 2px #3affff; */
  border-radius: 20px;
  background: #fff;
  max-height: 85vh;
  /* overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
  };
  &::-webkit-scrollbar-track {
    background: #E4E6EB;
    border-radius: 0px 0px 9px 9px;
    margin: 18px

  }

  &::-webkit-scrollbar-thumb {
    background: #3A3B3C;
    border-radius: 10px;
  };

  &::-webkit-scrollbar-thumb:hover {
    background: #3A3B3C;
  }; */

  @media screen and (min-width: 320px) and (max-width: 720px) {
    min-width: 100%;
  }
`

const CancelButton = styled(Button)`
  flex: 1;
  margin-right: 8px;
  border: solid 1px ${border};
  background-color: transparent;
  color: #525356;
  font-weight: 500;
  box-shadow: none;
`

const ConnectButton = styled(Button)`
  flex: 1;
  margin-left: 8px;
  /* background-image: linear-gradient(to top, #03d3ef, #0096ea); */
  background-color: ${connected};
  font-weight: 500;
  box-shadow: none;
`

// console.log('config', config)

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => {
  const { t } = useTranslation('common')
  const [walletSelect, setWalletSelect] = useState<any>('')
  const [walletActive, setWalletActive] = useState<any>(-1)

  // let device = false
  const userAgent = navigator.userAgent.toLowerCase()
  const checkOpenDecent = userAgent.includes('kr.iotrust.dcent')
  // const checkWebview = userAgent.includes('wv')
  // console.log('checkWebview', checkWebview)
  // if (userAgent.includes('wv') || checkOpenDecent) {
  //   // Android webview
  //   device = true
  // } else {
  //   device = false
  // }
  // let dataConfig = device || !isMobile ? config.filter((el) => el.title !== `D'cent`) : config
  let dataConfig = []
  if(userAgent.includes('wv') || checkOpenDecent){
    dataConfig = config.filter((el) => el.connectorId === ConnectorNames.DECENT_APP)
  } else {
    dataConfig = config.filter((el) => el.connectorId !== ConnectorNames.DECENT_APP)
  }


  return (
    <ModalConnect title={t('Reuse.SellectAWallet')} onDismiss={onDismiss}>
      {dataConfig.map((entry, index) => (
        <WalletCard
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          id={index}
          login={login}
          walletConfig={entry}
          onDismiss={onDismiss}
          setWalletSelect={setWalletSelect}
          walletActive={walletActive}
          setWalletActive={setWalletActive}
          mb={index < config.length - 1 ? '8px' : '0'}
        />
      ))}

      <Flex style={{ marginTop: 50 }}>
        <CancelButton onClick={() => onDismiss()}>{t('Button.Cancel')}</CancelButton>
        <ConnectButton
          onClick={() => {
            login(walletSelect)
            onDismiss()
          }}
        >
          {t('Button.Connect')}
        </ConnectButton>
      </Flex>

      {/* <HelpLink
      href="https://docs.pancakeswap.finance/guides/faq#how-do-i-set-up-my-wallet-on-binance-smart-chain"
      external
      color="#ffffff"
    >
      <HelpAltGobit width="16px" color="#ffffff" mr="6px" />
      Learn how to connect
    </HelpLink> */}
    </ModalConnect>
  )
}

export default ConnectModal
