import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import userInfoReducer from './userInfo'
import PopupReducer from './Popup'
// eslint-disable-next-line import/no-unresolved
import IndicatorReducer from './Indicator'
import CheckStepReducer from './checkStep'
import CheckStepExistingReducer from './checkStepExisting'
import GetChainIdReducer from './getChainId'
import GetListOwnerSmartReducer from './getListOwnerSmart'
import SafeInfoReducer from './safeInfo'
import BalanceSafeReducer from './getBalanceSafe'
import SendFundReducer from './SendFund'
import getCurrencyReducer from './getCurrency'
import getNFTSafeReducer from './getNFTSafe'
import BookAddressReducer from './BookAddress'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    dataUser: userInfoReducer,
    popup: PopupReducer,
    indicator: IndicatorReducer,
    checkStep: CheckStepReducer,
    checkStepExisting: CheckStepExistingReducer,
    getChainId: GetChainIdReducer,
    listOwnerSmart: GetListOwnerSmartReducer,
    safeInfo: SafeInfoReducer,
    balanceSafe: BalanceSafeReducer,
    NFTSafe: getNFTSafeReducer,
    dataSendFund: SendFundReducer,
    dataCurrency: getCurrencyReducer,
    dataBookAddress: BookAddressReducer,
  },
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
