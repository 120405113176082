import { matchPath, generatePath } from 'react-router-dom'
import history from 'routerHistory'
import { SAFE_ROUTES, TRANSACTION_ID_SLUG, SAFE_ADDRESS_SLUG } from 'routes/routes'


export const DeeplinkedTx = (): boolean => {
  const deeplinkMatch = matchPath(history.location.pathname, {
    path: SAFE_ROUTES.TRANSACTIONS_SINGULAR,
  })

  return deeplinkMatch?.params?.[TRANSACTION_ID_SLUG]
}

export const isDeeplinkedTx = (): boolean => {
  const txMatch = matchPath(history.location.pathname, {
    path: [SAFE_ROUTES.TRANSACTIONS_HISTORY, SAFE_ROUTES.TRANSACTIONS_CONFIRM, SAFE_ROUTES.TRANSACTIONS_REJECT],
  })

  const deeplinkMatch = matchPath(history.location.pathname, {
    path: SAFE_ROUTES.TRANSACTIONS_SINGULAR,
  })

  return !txMatch && !!deeplinkMatch?.params?.[TRANSACTION_ID_SLUG]
}

export const navigateToTx = (safeAddr?: string, txDetails?: any) => {
  // const prefixedSafeAddress = getPrefixedSafeAddressSlug({ safeAddr })
  // console.log('prefixedSafeAddress', prefixedSafeAddress)

  const txRoute = generatePath(SAFE_ROUTES.TRANSACTIONS_SINGULAR, {
    [SAFE_ADDRESS_SLUG]: safeAddr,
    [TRANSACTION_ID_SLUG]: txDetails,
  })
  history.push(txRoute)
}