import { makeStyles } from '@mui/styles'
import { isAddress } from 'web3-utils'

// import { ScanQRWrapper } from 'src/components/ScanQRModal/ScanQRWrapper'
// import { ContractsAddressBookInput } from 'src/routes/safe/components/Balances/SendModal/screens/AddressBookInput'
import Field from 'components/forms/Field'
import TextField from 'components/forms/TextField'
// import {
//   composeValidators,
//   // mustBeEthereumAddress,
//   // mustBeEthereumContractAddress,
//   required,
//   Validator,
// } from 'components/forms/validator'
import { Col, Row } from 'style/DefaultStyled'

import { styles } from 'components/ModalSend/ContractInteraction/style'

const useStyles = makeStyles(styles)

export interface EthAddressInputProps {
  // isContract?: boolean
  // isRequired?: boolean
  name: string
  // onScannedValue?: (scannedValue: string) => void
  text?: string
}

const hasAddress = (address: string): undefined | string => {
  try {
    const checkIsAddress = isAddress(address)

    if (!checkIsAddress) {
      return 'Must resolve to a valid smart contract address.'
    }
  } catch (e) {
    return 'Must resolve to a valid smart contract address.'
  }
}

export const AddressInput = ({
  // isContract = true,
  // isRequired = true,
  name,
  // onScannedValue,
  text,
}: EthAddressInputProps): React.ReactElement => {
  const classes = useStyles()
  // const validatorsList = [
  //   isRequired && required,
  //   // mustBeEthereumAddress,
  //   isContract 
  //   // && mustBeEthereumContractAddress,
  // ] as Validator[]


  // const handleScan = (value, closeQrModal) => {
  //   let scannedAddress = value

  //   if (scannedAddress.startsWith('ethereum:')) {
  //     scannedAddress = scannedAddress.replace('ethereum:', '')
  //   }

  //   setSelectedEntry({ address: scannedAddress })
  //   onScannedValue(scannedAddress)
  //   closeQrModal()
  // }
 

  return (
    <>
      <Row>
        <Col className={classes.customInput}>
          <Field
            component={TextField}
            name={name}
            placeholder={text}
            // onChange={handleInputChange}
            testId={name}
            text={text}
            type="text"
            validate={hasAddress}
          />
          
        </Col>
        {/* <Col center="xs" className={classes.scanQR} middle="xs">
          <ScanQRWrapper handleScan={handleScan} />
        </Col> */}
      </Row>
    </>
  )
}
