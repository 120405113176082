import styled from 'styled-components'
import { Button as ButtonCustom } from 'components/Button'
import { connected,inputBorder } from 'theme/variables'

type Props = {
  active?: boolean
}

export const Wrapper = styled.div<Props>`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: ${(p) => (p.active ? 'fixed' : 'static')};
  background-color: #ffffff;
  @media (min-width: 768px) {
    height: 55px;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin: 0;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    @media (max-width: 1023px) {
      p {
        display: none;
      }
    }
  }
`

export const ItemConnect = styled.div`
  padding:  20px 15px;
  cursor: pointer;
  &.lang{
    padding:  0;
  }
  &.netword{
    cursor: default;
  }
  @media (min-width: 768px) {
    padding: 6px 26px;
    border-left: solid 1px #e5e5e5;
    }
`

export const WrapperConnect = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  min-width: 235px;
  /* padding: 20px 20px; */
  @media (min-width: 768px) {
      /* margin: 0 -24px; */
      /* padding: 20px 24px; */
      background: #fff;
    }
`

export const InfoConnect = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  margin-right: auto;

 
`

export const TitleItem = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #525356;
  font-family: OpenSans-SemiBold;
`

export const Title2Item = styled.div`
  font-family: OpenSans-Italic;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  /* font-style: italic; */
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: ${connected};
  text-decoration: underline;
`
export const IconArrowDown = styled.div`
  color: #b4b4b4;
  /* margin-left: 45px; */
`

export const ImgTitleItem = styled.img`
  width: 35px;
  margin-right: 12px;
`

export const WraperUseWallet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const WalletButton = styled(ButtonCustom)`
  /* height: 40px;
  border-radius: 4px;
  position: absolute;
  right: 10px;
  font-size: 12px;
  padding: 0px 10px; */
  height: 40px;
  object-fit: contain;
  border-radius: 6px;
  border: solid 1px #fff;
  background-color: #04002d;
  font-family: Noto Sans KR;
  font-size: 16px;
  line-height: 1.88;
  text-align: center;
  color: #fff;

  span {
    font-size: 16px;
    margin-left: 8px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 0px 5px;
  }
`
export const Content = styled.div`
  /* max-width: 1200px; */
  margin: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 99;
  position: relative;
  padding: 0 20px;

  /* @media (max-width: 1199px) {
    padding: 0px 20px;
  } */
  @media (min-width: 768px) {
    padding: 0 32px;
  }
`

export const ContentSidebar = styled.div`
z-index: 9;
width: 100%;
/* padding-bottom: 20px;  */
/* min-height: 100vh; */
position: absolute;
top: 80px;
background-color: #fff;
border-top: 1px solid #e6e6e6;
min-height: calc(100vh - 77px);
height: calc(100vh - 77px);
max-height: 100%;
overflow: auto;
/* border-bottom: 1px solid ${inputBorder}; */
@media (min-width: 768px) {
  height: calc(100vh - 77px);
  top: 55px;
  }
`

export const Button = styled.button`
  width: 136px;
  height: 40px;
  margin: 0 20px 0 0;
  object-fit: contain;
  border-radius: 6px;
  background-image: linear-gradient(152deg, #119bd4 -89%, #7c44c0 51%, #da709d 113%);
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  @media (max-width: 600px) {
    display: none;
  }
`

export const ImgLogo = styled.img`
  /* width: 191px; */
  height: 35px;
  margin-right: auto;
  &.center {
    margin: auto;
  }
  @media (max-width: 600px) {
    width: 133px;
    height: auto;
    margin-right: 0;
  }
`

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media (max-width: 768px) {
    display: none;
  }
`
export const WrapperButtonMobile = styled.div`
  display: none;
  align-items: center;
  /* padding-right: 20px; */
  @media (max-width: 768px) {
    display: flex;
  }
`


