import {  connected, colorTextSecondary, secondary, secondaryBackground } from 'theme/variables'
import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.MD]: {
    height: "45px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "36px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: "primary",
    color: "white",
  },
  [variants.SECONDARY]: {
    backgroundColor: "transparent",
    // border: "1px solid",
    borderColor: "primary",
    boxShadow: "none",
    color: colorTextSecondary,
    ":disabled": {
      backgroundColor: "transparent",
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: secondaryBackground,
    boxShadow: "none",
    color: secondary,
  },
  [variants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "white",
  },
  [variants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none",
  },
  [variants.CONNECTED]: {
    boxShadow: "none",
    // border: "solid 3px #3affff",
    backgroundColor: connected,
    color: "white",
  },
  [variants.OUTLINED]: {
    boxShadow: "none",
    // border: "solid 3px #3affff",
    backgroundColor: "transparent",
    border: '1px solid',
    borderColor: secondary,
    color: secondary,
  },
  
};
