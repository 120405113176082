import { useCallback } from 'react'
import { submitTransactionSelfContract } from 'utils/callHelpers'
import { useUserData } from 'state/hooks'
import BigNumber from 'bignumber.js'
import {  useContractSafe } from 'hooks/useContract'

const useSubmitTransactionSelf = ( _type?: any, _address?: any, _token?: any,
  isChangeConfirm?: boolean,
  ) => {
  const contract = useContractSafe()
  const { account } = useUserData()
  const handleSubmitTransactionSelf = useCallback(
    async (amount?: any) => {
      try {
        const _value = isChangeConfirm? amount: new BigNumber(amount).times(new BigNumber(10).pow(18))

        const tx = await submitTransactionSelfContract(contract, account, _type, _address, _token, _value)
        // if (tx?.transactionHash) {
        //   const dataTransaction = {
        //     chainId,
        //     safeAddress,
        //     type: 'SEND_KLAY',
        //     creatorAddress: account,
        //     hash: tx?.transactionHash,
        //     to: _address,
        //     amount: Number(new BigNumber(amount).times(new BigNumber(10).pow(18))),
        //   }

          // }
          
        return tx
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error handleConfirm', e)
      }
    },
    [_type, _address, _token, contract],
  )

  return { onSubmitTransactionSelf: handleSubmitTransactionSelf }
}

export default useSubmitTransactionSelf
