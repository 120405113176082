import styled from 'styled-components'
import { Container } from '@mui/material'
import { colorTextSecondary, secondaryBackground } from 'theme/variables'

export const Containers = styled(Container)`
  /* max-width: 1140px; */
  /* padding-left: 0;
  padding-right: 0; */
  @media (min-width: 1024px) {
    /* padding-left: 0;
    padding-right: 0; */
  }
`

export const StyleRow = styled.div<{
  alignItems?: string
  justifyContent?: string
  gutter?: number
  mb?: string
  mt?: string
  noWrap?: boolean
}>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  ${({ gutter }) =>
    gutter &&
    `
    flex-flow: row wrap;
    margin-left: -${gutter}px;
    margin-right: -${gutter}px;
  & >div.col{
    padding-left: ${gutter/2}px;
    padding-right ${gutter/2}px;
  }
  `}
  margin-bottom: ${({ mb }) => mb || 'auto'};
  margin-top: ${({ mt }) => mt || 'auto'};
  flex-wrap: ${({ noWrap }) => !noWrap && 'wrap'};
  /* width: 100%; */
  position: relative;
`

export const Row = (props) => {
  return <StyleRow {...props} />
}

export const StyleCol = styled.div<{ numberCol?: number; textAlign?: string; md?: number; lg?: number; mb?: string }>`
  flex: ${({ numberCol }) => (numberCol ? `0 0 ${(numberCol / 12) * 100}%` : '1 1 100%')};
  max-width: ${({ numberCol }) => (numberCol ? `${(numberCol / 12) * 100}%` : '100%')};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  @media (min-width: 768px) {
    flex: ${({ md }) => md && `0 0 ${(md / 12) * 100}%`};
    max-width: ${({ md }) => md && `${(md / 12) * 100}%`};
  }

  @media (min-width: 1024px) {
    flex: ${({ lg }) => lg && `0 0 ${(lg / 12) * 100}%`};
    max-width: ${({ lg }) => lg && `${(lg / 12) * 100}%`};
  }
`
export const Col = (props) => {
  const { className } = props
  return <StyleCol {...props} className={`col ${className ?? ''}`} />
}

export const StyleLine = styled.div<{ hide?: string }>`
  height: 1px;
  background-color: #e8e8e8;
  margin: 0 -24px;
  display: block;

  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
    display: ${({ hide }) => (hide === 'md' ? `none` : 'block')};
  }
`

export const Text = styled.div<{ fontSize?: string; mt?: string; mb?: string; color?: string; isSemiBold?: boolean }>`
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-family: ${({ isSemiBold }) => isSemiBold && 'OpenSans-SemiBold'};
  color: ${({ color }) => color ?? 'inherit'};
  margin-top: ${({ mt }) => mt ?? 'auto'};
  margin-bottom: ${({ mb }) => mb ?? 'auto'};
  width: 100%;
  line-height: normal;
`

export const StyleWarning = styled.div<{ hide?: string }>`
  margin: 0 -24px;
  padding: 30px 24px;
  color: ${colorTextSecondary};
  background-color: ${secondaryBackground};
  @media screen and (min-width: 768px) {
    margin: 0 -42px;
    padding: 30px 42px;
  }
`
