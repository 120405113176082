import styled from 'styled-components'

export const ComponentWp = styled.div<{type?: boolean}>`
    width: 100%;
    cursor: ${({type}) => type ? 'pointer' : 'auto'};
`;

export const Container = styled.div<{justifyContent?: string}>`
    display: flex;
    /* justify-content: center; */
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}

    align-items: center;
    .img{
        object-fit: cover;
        margin-right: 12px;
        position: relative;
        @media screen and (max-width: 560px) {
            margin-right: 10px;
        }
    }
    .name{
        color: #09bb9d;
        font-weight: bold;
        font-size: 15px;
    }
    .name-coin{
        color: #525356;
        font-weight: bold;
        margin-right: 5px;
    }
    .address{
        display: flex;
        font-family: OpenSans-Regular;
        align-items: center;
        .address-wallet{
            font-size: 15px;
            color: #525356;
            word-break: break-all;
        }
        .MuiButtonBase-root{
            min-width: 27px;
        }
    }
    .balance{  
        >button{
            min-width: 126px;
            height: 32px;
            border-radius: 30px;
            background-color: #f9fafb;
            text-align: center;
            margin-top: 5px;
        }
        .MuiButtonBase-root{
            font-family: OpenSans-Regular;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #525356;
            text-transform: none;
            display: inline-block;
            text-align: center;
        }
        .value{
            margin-left: 3px;
            font-family: OpenSans-Bold;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #525356;
        }
    }
    .icon{
        display: flex;
        align-items: center;
        margin-left: 5px;
        .box{
            display: flex;
        }
        >button{
            min-width: 0px;
            padding: 6px;
        }
    }
`

export const NumberOwner = styled.div`
    background: rgb(237 244 251);
    color: #547bfe;
    font-size: 12px;
    font-weight: bold;
    border-radius: 100%;
    padding: 0.25em;
    position: absolute;
    z-index: 2;
    top: -10px;
    left: -10px;
    min-width: 1.5em;
    min-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-family: OpenSans-Bold;
`