import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { inputBorder } from 'theme/variables'
import TextField from '@mui/material/TextField'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import { InputLabelProps } from './types'

const StyledInput = styled(TextField)`
  /* border: solid 1px ${inputBorder}; */
  /* padding: 13px 37px; */
  width: 100%;
  & .MuiInputBase-root {
    border-radius: 30px;
    & .MuiInputBase-input {
      padding: 13px 37px;
      height: auto;
      font-size: 14px;
      ::-webkit-inner-spin-button {
        display: none;
      }
    }
  }
  &:focus {
    outline: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: solid 1px #e8e8e8;
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  .MuiSvgIcon-root {
    border-radius: 50%;
    background-color: #547bfe;
    padding: 3px;
    path {
      fill: #fff;
    }
  }
`

const InputAmount: React.FC<InputLabelProps> = ({
  onChange,
  disabled,
  placeholder,
  name,
  defaultValue = '',
  titleRight,
  onFocus,
  onBlur,
}) => {
  const [valueInput, setValue] = useState('')

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    defaultValue ? setValue(defaultValue) : setValue('')
  }, [defaultValue])

  const handleChange = (event: any) => {
    const { value } = event.target
    setValue(value)
    onChange?.(value)
  }

  return (
    <StyledInput
      className="input-amount"
      type="number"
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled}
      value={valueInput}
      name={name}
      onKeyDown={(evt: any) =>
        (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '+' || evt.key === ',') &&
        evt.preventDefault()
      }
      InputProps={{
        endAdornment: titleRight && <StyledInputAdornment position="start">{titleRight}</StyledInputAdornment>,
      }}
    />
  )
}

export default InputAmount
