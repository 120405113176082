/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

// import { DataGasLimitState } from '../types'

const initialState: any = { symbol: ''}

export const SendFundSlice = createSlice({
  name: 'GetSendFund',
  initialState,
  reducers: {
    setDataSendFund: (state, action) => {
      // return { ...state, data: action.payload}
      state.symbol = action.payload.symbol || ''
      // return action.payload
    },
  },
})

// Actions
export const { setDataSendFund } = SendFundSlice.actions

// Thunks
export const fetchSendFund = (data?: any) => async (dispatch?: any) => {
    dispatch(setDataSendFund(data))
  
}

export default SendFundSlice.reducer
