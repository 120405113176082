import { Link } from 'react-router-dom'
import { secondary, secondaryBackground, colorText } from 'theme/variables'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 400px;
  padding-top: 37px;
`

export const Container = styled.div`
  padding: 0 42px;
`

export const AddSafe = styled.div`
  color: ${secondary};
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 540px){
    font-size: 14px;
  }
`

export const IconSafe = styled.div`
  padding: 12px;
  background-color: ${secondary};
  color: #fff;
  border-radius: 50%;
  display: flex;
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const Network = styled.div`
  padding: 8px 42px;
  background-color: ${secondaryBackground};
  margin: 15px 0;
  @media screen and (max-width: 540px){
    font-size: 14px;
  }
`

export const Create = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: ${colorText};
  padding: 0 42px;
  & span {
    color: ${secondary};
    font-family: OpenSans-Italic;
    text-decoration: underline;
  }
`

export const StyleLink = styled(Link)`
  text-decoration: none;
  padding: 5px 42px;
  display: flex;
  &:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }
`
