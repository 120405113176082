import React, { useState, useEffect, useContext, useCallback } from 'react'
// import throttle from 'lodash/throttle'
import { SafeListSidebarContext } from 'views/SafeListSidebar'
import { useWalletModal } from 'components/WalletModal'
import { Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Mobile } from 'components/Responsive'
import { WELCOME_ROUTE } from 'routes/routes'
import {
  Close,
  AlignJustify,
  AngleRight,
} from 'components/Svg'
import Sidebar from 'components/Sidebar'

/** Image */
import logo from 'assets/images/brand.png'
import kaikas from 'assets/images/kaikas.png'
import history from 'routerHistory'
import SwitchNetword from './SwitchNetword'
import SwitchLang from './SwitchLang'

/** Style */
import {
  ImgLogo,
  Wrapper,
  WrapperConnect,
  ItemConnect,
  TitleItem,
  Title2Item,
  ImgTitleItem,
  IconArrowDown,
  InfoConnect,
  // Button,
  Content,
  WrapperButton,
  WrapperButtonMobile,
  ContentSidebar,
} from './style'

/** Type */
import { NavProps } from './types'

const Menu: React.FC<NavProps> = ({ account, login, logout, errorr }) => {
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  const { toggleSidebar } = useContext(SafeListSidebarContext)

  // const { isXl } = useMatchBreakpoints();
  const [openSidebar, seOpenSidebar] = useState<boolean>(false)

  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4, account.length)}`
    : null

  useEffect(() => {
    if (openSidebar) {
      window.document.body.style.overflow = 'hidden'
    } else {
      window.document.body.style.overflow = 'auto'
    }
  }, [openSidebar])

  const handleClick = useCallback(() => {
    if (accountEllipsis) onPresentAccountModal()
    if (!accountEllipsis) onPresentConnectModal()
  }, [accountEllipsis])

  return (
    <Wrapper>
      <Content>
        <ImgLogo
          src={logo}
          alt="logo"
          className={errorr === true && 'center'}
          onClick={() => {
            history.push(WELCOME_ROUTE)
            seOpenSidebar(false)
          }}
        />
        <Mobile>
        <Button>
          <AddCircleIcon onClick={toggleSidebar} />
        </Button>
        </Mobile>
        {!errorr && (
          <>
            <div className="menu">{/* <p style={{ marginRight: 60 }}>예치</p> */}</div>
            <WrapperButton>
              <ItemConnect onClick={() => handleClick()}>
                <WrapperConnect>
                  {accountEllipsis ? (
                    <>
                      <ImgTitleItem src={kaikas} alt="" />
                      <InfoConnect>
                        <TitleItem>Klay</TitleItem>
                        <Title2Item>{accountEllipsis}</Title2Item>
                      </InfoConnect>
                    </>
                  ) : (
                    <>
                      <InfoConnect>
                        <TitleItem>Not Connected</TitleItem>
                        <Title2Item>Connect Wallet</Title2Item>
                      </InfoConnect>
                    </>
                  )}
                  <IconArrowDown>
                    <KeyboardArrowDownIcon />
                  </IconArrowDown>
                </WrapperConnect>
              </ItemConnect>
              <ItemConnect className="netword">
                {/* <UserWallet account={account} login={login} logout={logout} /> */}
                <SwitchNetword />
              </ItemConnect>
              <ItemConnect className="lang">
                {/* <UserWallet account={account} login={login} logout={logout} /> */}
                <SwitchLang />
              </ItemConnect>
              {/* <ButtonOutline onClick={() => setIsConnect(!isConnect)}>{account ? accountEllipsis : 'Connect Wallet'}</ButtonOutline> */}
              {/* <MenuMobile onClick={() => {
            setActive(!active); dispatch(
              fetchPopup({
                isPopup: false,
              }),
            );
          }}>
            {active ? <img src={iconX} alt="" /> : <MenuIcon />}
          </MenuMobile> */}
            </WrapperButton>
            <WrapperButtonMobile>
              <ItemConnect className="lang">
                {/* <UserWallet account={account} login={login} logout={logout} /> */}
                <SwitchLang />
              </ItemConnect>
              {!openSidebar && (
                <AlignJustify
                  color="#547bfe"
                  width={27}
                  onClick={() => {
                    seOpenSidebar(true)
                  }}
                />
              )}
              {openSidebar && (
                <Close
                  color="#547bfe"
                  width={30}
                  onClick={() => {
                    seOpenSidebar(false)
                  }}
                />
              )}
            </WrapperButtonMobile>
          </>
        )}
      </Content>
      {openSidebar && (
        <ContentSidebar>
          <ItemConnect onClick={() => handleClick()}>
            <WrapperConnect>
              {accountEllipsis ? (
                <>
                  <ImgTitleItem src={kaikas} alt="" />
                  <InfoConnect>
                    <TitleItem>Klay</TitleItem>
                    <Title2Item>{accountEllipsis}</Title2Item>
                  </InfoConnect>
                </>
              ) : (
                <>
                  <InfoConnect>
                    <TitleItem>Not Connected</TitleItem>
                    <Title2Item>Connect Wallet</Title2Item>
                  </InfoConnect>
                </>
              )}
              <IconArrowDown>
                <AngleRight color="#b2b2b2" width={16} />
              </IconArrowDown>
            </WrapperConnect>
          </ItemConnect>
          <Sidebar onChange={() => seOpenSidebar(!openSidebar)} />
        </ContentSidebar>
      )}
    </Wrapper>
  )
}

export default Menu
