import React, { Fragment, useCallback,  useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  createTxObject,
  formMutators,
  // handleSubmitError,
  isReadMethod,
  generateFormFieldKey,
  getValueFromTxInputs,
} from 'components/ModalSend/ContractInteraction/utils'
import SafeForm from 'components/forms/SafeForm'
import { useContractSafe } from 'hooks/useContract'
import useContractInteractionEncode from 'hooks/useContractInteractionEncode'
import useContractInteractionEncodeOneOwner from 'hooks/useContractInteractionEncodeOneOwner'
import { ButtonFooter } from 'components/Modal'
import { Modal } from 'components/Modal'
import InfoAddress from 'components/InfoAddress'
import { useGetSafeInfo, useGetBalanceSafe, useUserData } from 'state/hooks'

import { navigateToTx } from 'utils/transactions'
import { CreateTransaction,CreateTransactionOneOwner } from 'apis'
import { Row, Text } from 'style/DefaultStyled'
import { ButtonStatus } from 'components/Modal/ModalFooter'

import Buttons from './Buttons'
import { AddressInput } from './AddressInput'
import { MethodsDropdown } from './MethodsDropdown'
import ContractABI from './ContractABI'
import { RenderInputParams } from './RenderInputParams'
import { RenderOutputParams } from './RenderOutputParams'
import { Title, TextToken,  } from '../style'

interface Props {
  // eslint-disable-next-line react/require-default-props
  onDismiss?: () => void
}

const CTModal = styled(Modal)`
  @media screen and (max-width: 624px) {
    min-width: 100%;
  }
`

export const NO_DATA = 'no data'


const SendInteraction: React.FC<Props> = ({ onDismiss = () => null }) => {
  let setCallResults
  const { t } = useTranslation('common')
  const { fiatTotal } = useGetBalanceSafe()
  const { address: safeAddress,  chainId, threshold } = useGetSafeInfo()
  const { account } = useUserData()
  const [step2, setStep2] = useState(false)
  const [dataParam, setDataParam] = useState<any>({})
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>(ButtonStatus.READY)

  const contract = useContractSafe()
  const { onContractInteractionEncode } = useContractInteractionEncode(contract)
  const { onContractInteractionEncodeOneOwner } = useContractInteractionEncodeOneOwner(contract)

  const handleSubmit = async (
    { contractAddress, selectedMethod, value, ...values },
    submit = true,
  ): Promise<void | Record<string, string>> => {
    // console.log('contractAddress', contractAddress)
    // console.log('selectedMethod', selectedMethod)
    // console.log('values', values)
    if (value || (contractAddress && selectedMethod)) {
      try {
        const txObject = createTxObject(selectedMethod, contractAddress, values)
        const data = txObject.encodeABI()

        if (isReadMethod(selectedMethod) && submit) {
          const result = await txObject.call({ from: safeAddress })

          setCallResults(result)
          // this was a read method, so we won't go to the 'review' screen
          // eslint-disable-next-line no-useless-return
          return
        }
        setStep2(true)
        const param = { ...values, contractAddress, data, selectedMethod, value }
        setDataParam(param)
        // onNext({ ...values, contractAddress, data, selectedMethod, value }, submit)
      } catch (error) {
        // return handleSubmitError(error, values)
      }
    }
  }

  const dataTransaction = {
    chainId,
    safeAddress,
    type: 'CONTRACT_INTERACTION',
    creatorAddress: account,
    contractAddress: dataParam?.contractAddress,
    contractAbi: dataParam?.abi,
    contractFunction: dataParam?.selectedMethod?.name,
  }

  const handleContractInteractionOneOwner = useCallback(async () => {
    try {
      setButtonStatus(ButtonStatus.LOADING)
      const dataResult: any = await onContractInteractionEncodeOneOwner(
        dataParam?.abi,
        dataParam?.contractAddress,
        dataParam?.data,
      )
      setButtonStatus(ButtonStatus.READY)

      if (dataResult.status === true) {
        const result: any = await CreateTransactionOneOwner({
          ...dataTransaction,
          hash: dataResult?.transactionHash,
          contractData: dataResult?.contractData,
        })
        // if (result?.status === 200) setDataSubmit(result?.data)
        if (result?.status === 200) {
          navigateToTx(safeAddress, result?.data?.id)
          onDismiss()
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [dataParam, onContractInteractionEncodeOneOwner])

  const handleContractInteraction = useCallback(async () => {
    try {
      setButtonStatus(ButtonStatus.LOADING)
      const dataResult: any = await onContractInteractionEncode(
        dataParam?.abi,
        dataParam?.contractAddress,
        dataParam?.data,
      )
      setButtonStatus(ButtonStatus.READY)

      if (dataResult.status === true) {
        const result: any = await CreateTransaction({
          ...dataTransaction,
          hash: dataResult?.transactionHash,
          contractData: dataResult?.contractData,
        })
        // if (result?.status === 200) setDataSubmit(result?.data)
        if (result?.status === 200) {
          navigateToTx(safeAddress, result?.data?.id)
          onDismiss()
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [dataParam, onContractInteractionEncode])

  const submitTx = threshold === 1 ? handleContractInteractionOneOwner : handleContractInteraction
  // const submitTx = async (
  //   { contractAddress, selectedMethod, value, ...values },
  //   submit = true,
  // ): Promise<void | Record<string, string>> => {
  //   // console.log('contractAddress', contractAddress)
  //   // console.log('selectedMethod', selectedMethod)
  //   // console.log('values', values)
  //   if (value || (contractAddress && selectedMethod)) {
  //     try {
  //       const txObject = createTxObject(selectedMethod, contractAddress, values)
  //       const data = txObject.encodeABI()
  //       console.log('txObject', txObject)
  //       console.log('data', data)

  //       const result = await txObject.call({ from: safeAddress })
  //       console.log('result', result)

  //       // setCallResults(result)
  //       // this was a read method, so we won't go to the 'review' screen
  //       // eslint-disable-next-line no-useless-return
  //       const param = { ...values, contractAddress, data, selectedMethod, value }

  //       // onNext({ ...values, contractAddress, data, selectedMethod, value }, submit)
  //     } catch (error) {
  //       // return handleSubmitError(error, values)
  //     }
  //   }
  // }

  // const submitTx = (txParameters?: any, delayExecution?: any) => {
  //   if (safeAddress) {
  //    console.log('txParameters',txParameters)
  //    console.log('delayExecution',delayExecution)
  //   } else {
  //     console.error('There was an error trying to submit the transaction, the safeAddress was not found')
  //   }
  //   onClose()
  // }

  const onClose = () => {
    onDismiss()
  }

  const contentStep2 = () => {
    return (
      <>
        <Row mb="25px">
          <Title>{t('Reuse.SendingFrom')}</Title>
          <InfoAddress
            address={safeAddress}
            balance={fiatTotal}
            width={42}
          />
        </Row>
        <Row mb="25px">
          <Title>{t('Transaction.Recipient')}</Title>
          <InfoAddress
            address={dataParam?.contractAddress}
            // balance="0.005 ETH"
            width={42}
          />
        </Row>
        <Row mb="25px">
          <Title>{t('Reuse.Method')}</Title>
          <TextToken>{dataParam?.selectedMethod?.name}</TextToken>
          {/* <Row>
            <ImageToken src={kaikas} alt="" />
            <TextToken>(Token ID: 0)</TextToken>
          </Row> */}
        </Row>

        {/* {dataSortMethod.map((v, i) => {
          return (
            <Row mb="25px" key={i}>
              <Title>{v.name}</Title>
              <TextToken>{v.value}</TextToken>
            </Row>
          )
        })} */}
        {dataParam.selectedMethod?.inputs?.map(({ name, type }, index) => {
          const key = generateFormFieldKey(type, dataParam.selectedMethod?.signatureHash || '', index)
          const value: string = getValueFromTxInputs(key, type, dataParam)

          return (
            <Fragment key={key}>
              <Row>
                <Title>
                  {name} ({type})
                </Title>
              </Row>
              <Row mb="15px">
                <div>{value}</div>
              </Row>
            </Fragment>
          )
        })}
        <ButtonFooter
          cancelButtonProps={{ onClick: () => setStep2(false) }}
          confirmButtonProps={{
            status: buttonStatus
          }}
          // onClose={()=> setStep2(false)}
        />
      </>
    )
  }

  return (
    <CTModal title={t('Reuse.ContractInteraction')} 
    step={step2 ? `2 ${t('Reuse.of')} 2` : `1 ${t('Reuse.of')} 2`}
    onDismiss={onDismiss}
    >
      <SafeForm
        onSubmit={(e) => (step2 ? submitTx() : handleSubmit(e))}
        subscription={{ submitting: true, pristine: true, values: true }}
        formMutators={formMutators}
        // decorators={[ensResolver]}
      >
        {(submitting, validating, rest, mutators) => {
          setCallResults = mutators?.setCallResults

          return (
            <>
              {step2 ? (
                contentStep2()
              ) : (
                <>
                  {/* <InputAddress name="contractAddress" 
                  // onScannedValue={mutators.setContractAddress}
                  onChange={(e)=> console.log('e', e)}

              /> */}
                  <Text isSemiBold mb="10px">
                  {t('Reuse.ContractAddress')}*
                  </Text>
                  <AddressInput
                    name="contractAddress"
                    // onScannedValue={mutators.setContractAddress}
                    text="Contract address"
                  />
                  <Text isSemiBold mb="10px">
                    ABI*
                  </Text>
                  <ContractABI />
                  <MethodsDropdown onChange={mutators.setSelectedMethod} />

                  <RenderInputParams />
                  <RenderOutputParams />

                  <Buttons onClose={onClose} requiresMethod />
                </>
              )}
            </>
          )
        }}
      </SafeForm>
    </CTModal>
  )
}

export default SendInteraction
