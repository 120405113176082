/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { useUserData, useGetSafeInfo } from 'state/hooks'
import axios from 'axios'
import { GetSafeNFTs } from 'apis'

const initialState: any = { listNFT:[], nftActive:{},listFilterNFT: [],listAddressNFT : [] }

export const getNFTSafeSlice = createSlice({
  name: 'getNFTSafe',
  initialState,
  reducers: {
    setNFTSafe: (state, action?: any) => {
      state.listNFT = action.payload
    },
    setDataSendNFT: (state, action?: any) => {
      state.nftActive = action.payload
    },
    setNFTFilterSafe: (state, action?: any) => {
      state.listFilterNFT = action.payload
    },
    setNFTAddressSafe: (state, action?: any) => {
      state.listAddressNFT = action.payload
    },
  },
})

// Actions
export const { setNFTSafe,setDataSendNFT,setNFTFilterSafe,setNFTAddressSafe } = getNFTSafeSlice.actions

// Thunks
export const fetchNFTSafe = (params) => async (dispatch?: any) => {
  try {
    const response: any = await GetSafeNFTs(params)
    const result = response?.data ?? []

    const dataAddress: any = result.map(v => v?.address) ?? []
    const setChars: any = new Set(dataAddress);
    const uniqueChars = [...setChars];

    const listData = uniqueChars.map(v => {
      const data = result.filter(s => s?.address === v) ?? []
      return data
    })

    dispatch(setNFTSafe(result))
    dispatch(setNFTAddressSafe(dataAddress))
    dispatch(setNFTFilterSafe(listData))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`balance`, error)
  }
  // dispatch(setBalance(data))
}

export default getNFTSafeSlice.reducer
