import { md, sm, screenXsMax } from 'theme/variables'
import { createStyles } from '@mui/styles'


export const styles = createStyles({
  qrCodeBtn: {
    cursor: 'pointer',
  },
  formContainer: {
    padding: `${md} 42px`,
    wordBreak: 'break-word',
    '& .address .icon-color': {
      fill: '#547bfe',
    },
    [`@media (max-width: ${screenXsMax}px)`]: {
      padding: `8px 16px`,
    },
  },
  customInput: {
    '& .MuiInputBase-root': {
      border: 'solid 1px #e8e8e8',
      borderRadius: '30px',
      backgroundColor: '#fff',
      paddingLeft: '35px',
      paddingRight: '35px',
      // height: '45px',
      // paddingTop: '10px',
      '&.MuiFilledInput-underline::before,&.MuiFilledInput-underline::after': {
        borderBottomWidth: '0px !important',
      },
      '& .MuiInputBase-input': {
        padding: '0',
        // padding: '12px 0',
      },
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(38px, 4px) scale(0.75)',
    },
  },
  scanQR: {
    flex: '0 0 auto',
    alignItems: 'center',
  },
  value: {
    marginLeft: sm,
  },
  outerData: {
    minHeight: '21px',
  },
  data: {
    wordBreak: 'break-all',
    overflow: 'auto',
    fontSize: '16px',
    fontFamily: 'Averta',
    maxHeight: '100px',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
  },
  buttonRow: {
    height: '84px',
    justifyContent: 'center',
    gap: '16px',
  },
  dataInput: {
    '& TextField-root-294': {
      lineHeight: 'auto',
      border: 'green',
    },
  },
  selectAddress: {
    cursor: 'pointer',
  },
  fullWidth: {
    justifyContent: 'space-between',
  },
  form:{
    '& .modal-footer': {
      justifyContent: 'center',
    },
  },
  styleTitle: {
    fontFamily: 'OpenSans-SemiBold',
    color: '#525356',
    fontWeight: 600,
  },
})
