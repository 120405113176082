import { checkAddressChecksum, toChecksumAddress,isAddress, isHexStrict } from 'web3-utils'
// import { isValidAddress } from './isValidAddress'


const isValidAddress = (address?: string): boolean => {
  if (address) {
    // `isAddress` do not require the string to start with `0x`
    // `isHexStrict` ensures the address to start with `0x` aside from being a valid hex string
    return isHexStrict(address) && isAddress(address)
  }

  return false
}

export const checksumAddress = (address: string): string => {
  if (!isValidAddress(address)) {
    return ''
  }

  try {
    return toChecksumAddress(address)

  } catch (err) {
    // logError(Errors._102, err.message)
    return ''
  }
}

export const isChecksumAddress = (address?: string): boolean => {
  if (address) {
    return checkAddressChecksum(address)
  }

  return false
}

export const convertHash = (hash?: string) => {
  if(!hash || hash?.length < 21){
    return ''
  }
  const splitAddress = `${hash.substring(0, 10)}...${hash.substring(
    hash.length - 10,
    hash.length,
  )}`
  return splitAddress
}

export const SplitAddress = (address?: string) => {
  if(!address || address?.length < 11){
    return ''
  }
  const splitAddress = `${address.substring(0, 6)}...${address.substring(
    address.length - 4,
    address.length,
  )}`
  return splitAddress
}

export const tolowercaseAddress = (address?: string) => {
  if(!address || address?.length < 1 || address === undefined ){
    return ''
  }
  const convert: any = address
  return  convert?.toLowerCase()
}