/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { GetAddressBook } from 'apis'
import { BookAddressState } from '../types'

// import { DataGasLimitState } from '../types'

const initialState: BookAddressState = { name: '', address: '', listAddressBook: [] }

export const BookAddressSlice = createSlice({
  name: 'GetBookAddress',
  initialState,
  reducers: {
    setDataBookAddress: (state, action) => {
      state.name = action.payload.name || ''
      state.address = action.payload.address || ''
      // return action.payload
    },
    setDefaultAddress: (state) => {
      state.name = ''
      state.address = ''
    },
    setListBookAddress: (state,action) => {
      state.listAddressBook = action.payload || []
    },
  },
})

// Actions
export const { setDataBookAddress, setDefaultAddress, setListBookAddress } = BookAddressSlice.actions

// Thunks
export const fetchListBookAddress = (param?: any) => async (dispatch?: any) => {
  const respon: any = await GetAddressBook(param)
  const data = respon?.data?.address_Books ?? []
  dispatch(setListBookAddress(data))
}

export default BookAddressSlice.reducer
