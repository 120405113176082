/**
 * @author [Life]
 * @create date 2021-04-14 09:27:06
 * @modify date 2021-04-14 09:27:06
 * @desc [description]
 */
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 75 75" {...props}>
      {/* <defs>
    <style>
      .cls-1 {
        fill: #f9f9f9;
      }

      .cls-2 {
        fill: #90fee4;
      }

      .cls-3 {
        fill: #547bfe;
      }

      .cls-4 {
        fill: #09bb9d;
      }
    </style>
  </defs> */}
  <g id="Group_57" data-name="Group 57" transform="translate(-295.073 -241.5)">
    <circle id="Ellipse_21" data-name="Ellipse 21" className="cls-1" fill="#f9f9f9" cx="37.5" cy="37.5" r="37.5" transform="translate(295.073 241.5)"/>
    <g id="Group_52" data-name="Group 52" transform="translate(7762.784 12985.108)">
      <circle id="Ellipse_17" data-name="Ellipse 17" className="cls-2" fill="#90fee4" cx="6.689" cy="6.689" r="6.689" transform="translate(-7437.25 -12723.065)"/>
      <path id="Ellipse_17_-_Outline" data-name="Ellipse 17 - Outline" className="cls-3" fill="#547bfe" d="M6.689,2.361a4.328,4.328,0,1,0,4.328,4.328A4.333,4.333,0,0,0,6.689,2.361M6.689,0A6.689,6.689,0,1,1,0,6.689,6.689,6.689,0,0,1,6.689,0Z" transform="translate(-7437.25 -12723.065)"/>
      <circle id="Ellipse_18" data-name="Ellipse 18" className="cls-2" fill="#90fee4" cx="6.689" cy="6.689" r="6.689" transform="translate(-7458.496 -12727)"/>
      <path id="Ellipse_18_-_Outline" data-name="Ellipse 18 - Outline" className="cls-3" fill="#547bfe" d="M6.689,2.361a4.328,4.328,0,1,0,4.328,4.328A4.333,4.333,0,0,0,6.689,2.361M6.689,0A6.689,6.689,0,1,1,0,6.689,6.689,6.689,0,0,1,6.689,0Z" transform="translate(-7458.496 -12727)"/>
      <circle id="Ellipse_19" data-name="Ellipse 19" className="cls-2" fill="#90fee4" cx="6.689" cy="6.689" r="6.689" transform="translate(-7416.79 -12727)"/>
      <path id="Ellipse_19_-_Outline" data-name="Ellipse 19 - Outline" className="cls-3" fill="#547bfe" d="M6.689,2.361a4.328,4.328,0,1,0,4.328,4.328A4.333,4.333,0,0,0,6.689,2.361M6.689,0A6.689,6.689,0,1,1,0,6.689,6.689,6.689,0,0,1,6.689,0Z" transform="translate(-7416.79 -12727)"/>
      <path id="Path_6" data-name="Path 6" className="cls-3" fill="#547bfe" d="M-7417.439-12670.2l-2.2-.844c2.312-6.045,4.86-10.619,7.576-13.594,2.46-2.7,5.116-4.156,7.892-4.344q.305-.021.612-.021a10.841,10.841,0,0,1,5.45,1.586,20.134,20.134,0,0,1,4.622,3.831,31.838,31.838,0,0,1,4.123,5.643l-2.072,1.133,1.036-.566-1.035.566c-.055-.1-5.469-9.832-12.12-9.832-.152,0-.306,0-.46.016-2.148.146-4.27,1.352-6.306,3.583C-7412.841-12680.287-7415.234-12675.967-7417.439-12670.2Z" transform="translate(-26.308 -21.299)"/>
      <path id="Path_7" data-name="Path 7" className="cls-3" fill="#547bfe" d="M-7465.5-12679.8l-2.207-.838c2.319-6.111,4.905-10.754,7.688-13.8,2.5-2.739,5.22-4.259,8.076-4.517a11.094,11.094,0,0,1,6.1,1.312,19.7,19.7,0,0,1,4.712,3.5,29.656,29.656,0,0,1,4.233,5.243l-2.021,1.222h0c-.08-.132-5.518-8.957-12.149-8.957-.223,0-.446.01-.671.03-2.249.205-4.451,1.471-6.543,3.761C-7460.863-12690.013-7463.292-12685.625-7465.5-12679.8Z" transform="translate(0 -15.833)"/>
      <path id="Path_8" data-name="Path 8" className="cls-3" fill="#547bfe" d="M-7342.06-12679.446c-2.3-5.834-4.764-10.2-7.328-12.978-2.06-2.231-4.184-3.434-6.315-3.576-.144-.01-.287-.015-.429-.015-3.035,0-5.723,2.123-7.474,3.951a24.441,24.441,0,0,0-3.334,4.411l-2.062-1.149a26.623,26.623,0,0,1,3.691-4.895,17.67,17.67,0,0,1,4.2-3.317,10.288,10.288,0,0,1,4.973-1.361c.2,0,.4.006.595.02,2.754.184,5.41,1.641,7.893,4.33,2.764,2.993,5.385,7.606,7.79,13.714Z" transform="translate(-53.995 -16.168)"/>
      <path id="Line_15" data-name="Line 15" className="cls-4" fill="#09bb9d" d="M.574,16.3H-1V0H.574Z" transform="translate(-7411.707 -12701.877)"/>
      <path id="Line_16" data-name="Line 16" className="cls-4" fill="#09bb9d" d="M1.574,16.3H0V0H1.574Z" transform="translate(-7403.772 -12694.515) rotate(90)"/>
    </g>
  </g>
    </Svg>
  );
};

export default Icon;
