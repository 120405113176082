/**
 * @author [Life]
 * @create date 2021-04-14 09:27:06
 * @modify date 2021-04-14 09:27:06
 * @desc [description]
 */
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 75 75" {...props}>
      <defs>
    {/* <style>
      .cls-1 {
        fill: #f9f9f9;
      }

      .cls-2, .cls-4 {
        fill: #90fee4;
      }

      .cls-3, .cls-7 {
        fill: none;
      }

      .cls-3, .cls-4, .cls-7 {
        stroke-linecap: round;
      }

      .cls-5 {
        fill: #09bb9d;
      }

      .cls-6 {
        fill: #547bfe;
      }

      .cls-7 {
        stroke: #547bfe;
        stroke-width: 3px;
      }

      .cls-8, .cls-9 {
        stroke: none;
      }

      .cls-9 {
        fill: #547bfe;
      }
    </style> */}
  </defs>
  <g id="Group_54" data-name="Group 54" transform="translate(-549 -242)">
    <circle id="Ellipse_23" data-name="Ellipse 23" className="cls-1"  fill="#f9f9f9" cx="37.5" cy="37.5" r="37.5" transform="translate(549 242)"/>
    <g id="Group_53" data-name="Group 53" transform="translate(-0.331 2.26)">
      <path id="Path_11" data-name="Path 11" className="cls-2" fill="#90fee4" d="M-7383.045-12174.319l13.97,22.127,12.751-21.564-13.22,7.595Z" transform="translate(7944.622 12445.895)"/>
      <g id="Path_9" data-name="Path 9" className="cls-3" fill="none" strokeLinecap="round" transform="matrix(0.883, 0.469, -0.469, 0.883, 574.806, 244.739)">
        <path className="cls-8" stroke="none" d="M.692,0l24.37,16.9L23.881,43.612,0,30.227Z"/>
        <path className="cls-9" fill="#547bfe" stroke="none" d="M 3.563772201538086 5.642379760742188 L 3.04051399230957 28.491943359375 L 21.09903144836426 38.61340713500977 L 21.9918098449707 18.42273902893066 L 3.563772201538086 5.642379760742188 M 0.69219970703125 0 L 25.06202125549316 16.90114974975586 L 23.88095092773438 43.61169815063477 L 0 30.22686767578125 L 0.69219970703125 0 Z"/>
      </g>
      <g id="Path_24" data-name="Path 24" className="cls-4" fill="#90fee4" strokeLinecap="round" transform="translate(586 277)">
        <path className="cls-8" stroke="none" d="M 16 30.5 C 12.12691020965576 30.5 8.485639572143555 28.99173927307129 5.746950149536133 26.25304985046387 C 3.008260011672974 23.51436042785645 1.5 19.87309074401855 1.5 16 C 1.5 12.12691020965576 3.008260011672974 8.485639572143555 5.746950149536133 5.746950149536133 C 8.485639572143555 3.008260011672974 12.12691020965576 1.5 16 1.5 C 19.87309074401855 1.5 23.51436042785645 3.008260011672974 26.25304985046387 5.746950149536133 C 28.99173927307129 8.485639572143555 30.5 12.12691020965576 30.5 16 C 30.5 19.87309074401855 28.99173927307129 23.51436042785645 26.25304985046387 26.25304985046387 C 23.51436042785645 28.99173927307129 19.87309074401855 30.5 16 30.5 Z"/>
        <path className="cls-9" fill="#547bfe" stroke="none" d="M 16 3 C 12.5275707244873 3 9.262990951538086 4.352239608764648 6.807609558105469 6.807609558105469 C 4.352239608764648 9.262990951538086 3 12.5275707244873 3 16 C 3 19.47243118286133 4.352239608764648 22.73701095581055 6.807609558105469 25.19239044189453 C 9.262990951538086 27.64776039123535 12.5275707244873 29 16 29 C 19.47243118286133 29 22.73701095581055 27.64776039123535 25.19239044189453 25.19239044189453 C 27.64776039123535 22.73701095581055 29 19.47243118286133 29 16 C 29 12.5275707244873 27.64776039123535 9.262990951538086 25.19239044189453 6.807609558105469 C 22.73701095581055 4.352239608764648 19.47243118286133 3 16 3 M 16 0 C 24.8365592956543 0 32 7.163440704345703 32 16 C 32 24.8365592956543 24.8365592956543 32 16 32 C 7.163440704345703 32 0 24.8365592956543 0 16 C 0 7.163440704345703 7.163440704345703 0 16 0 Z"/>
      </g>
      <path id="Line_15" data-name="Line 15" className="cls-5" fill="#09bb9d" d="M.68,17.4H-1V0H.68Z" transform="translate(604.597 251.198)"/>
      <path id="Line_15-2" data-name="Line 15" className="cls-6" fill="#547bfe" d="M.68,17.4H-1V0H.68Z" transform="translate(602.1 284.145)"/>
      <path id="Line_16" data-name="Line 16" className="cls-5" fill="#09bb9d" d="M1.68,17.4H0V0H1.68Z" transform="translate(613.136 259.057) rotate(90)"/>
      <path id="Line_16-2" data-name="Line 16" className="cls-6" fill="#547bfe" d="M1.68,17.4H0V0H1.68Z" transform="translate(610.639 292.005) rotate(90)"/>
      <path id="Path_10" data-name="Path 10" className="cls-7" fill="none" strokeLinecap="round" stroke="#547bfe" strokeWidth="3px" d="M-7382.174-12173.833l12.477,7.212,12.528-7.212" transform="translate(7944.623 12444.976)"/>
    </g>
  </g>
    </Svg>
  );
};

export default Icon;
