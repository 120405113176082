import styled, { DefaultTheme } from "styled-components";
import { colorText } from 'theme/variables'
import { InputProps, scales } from "./types";

interface StyledInputProps extends InputProps {
  theme: DefaultTheme;
}

/**
 * Priority: Warning --> Success
 */

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
  switch (scale) {
    case scales.SM:
      return "32px";
    case scales.LG:
      return "48px";
    case scales.MD:
    default:
      return "45px";
  }
};

const Input = styled.input<InputProps>`
  border: 0;
  border-radius: 30px;
  color: ${colorText};
  display: block;
  font-size: 16px;
  height: ${getHeight};
  outline: 0;
  padding: 0 16px;
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  @media (max-width: 540px) {
    font-size: 14px;
  }
  &::placeholder {
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
  }
`;

Input.defaultProps = {
  scale: scales.MD,
  isSuccess: false,
  isWarning: false,
};

export default Input;
