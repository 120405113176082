import { useCallback } from 'react'
import { submitTransactionSelfOneOwnerContract } from 'utils/callHelpers'
import { useUserData } from 'state/hooks'
import BigNumber from 'bignumber.js'
import {  useContractSafe } from 'hooks/useContract'

const useSubmitTransactionSelfOneOwner = ( _type?: any, _address?: any, _token?: any,
  isChangeConfirm?: boolean,
  ) => {
  const contract = useContractSafe()
  const { account } = useUserData()
  const handleSubmitTransactionSelfOneOwner = useCallback(
    async (amount?: any) => {
   
      try {
        const _value = isChangeConfirm? amount: new BigNumber(amount).times(new BigNumber(10).pow(18))

        const tx = await submitTransactionSelfOneOwnerContract(contract, account, _type, _address, _token, _value)
        return tx
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error handle submit OneOwner', e)
      }
    },
    [_type, _address, _token, contract],
  )

  return { onSubmitTransactionSelfOneOwner: handleSubmitTransactionSelfOneOwner }
}

export default useSubmitTransactionSelfOneOwner
