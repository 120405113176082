import { useMemo, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import { currentSafeFeaturesEnabled, currentSafeWithNames } from 'src/logic/safe/store/selectors'
// import { grantedSelector } from 'src/routes/safe/container/selector'
import {
  extractPrefixedSafeAddress,
  ADDRESSED_ROUTE,
  SAFE_SUBSECTION_ROUTE,
  generatePrefixedAddressRoutes,
} from 'routes/routes'
// import { hasFeature } from 'src/logic/safe/utils/safeVersion'

const useSidebarItems = () => {
  const { t } = useTranslation('common')
  // const featuresEnabled = useSelector(currentSafeFeaturesEnabled)
  // const safeAppsEnabled = hasFeature(FEATURES.SAFE_APPS)
  // const isCollectiblesEnabled = hasFeature(FEATURES.ERC721)
  // const isSpendingLimitEnabled = hasFeature(FEATURES.SPENDING_LIMIT)
  // const { needsUpdate } = useSelector(currentSafeWithNames)
  const safeAddress = extractPrefixedSafeAddress()
  // const granted = useSelector(grantedSelector)
  const matchSafe = useRouteMatch(ADDRESSED_ROUTE)

  // Question mark makes matching [SAFE_SUBSECTION_SLUG] optional
  const matchSafeWithSidebarSection = useRouteMatch(`${SAFE_SUBSECTION_ROUTE}?`)


  const makeEntryItem = useCallback(
    ({ label, disabled, badge, 
      // iconType, 
      href, subItems }) => ({
      label,
      badge,
      disabled,
      // icon: <ListIcon type={iconType} />,
      selected: href === matchSafeWithSidebarSection?.url,
      href,
      subItems,
    }),
    [matchSafeWithSidebarSection],
  )

  return useMemo((): any=> {
    if (!matchSafe || !matchSafeWithSidebarSection 
      // || !featuresEnabled 
      || !safeAddress
      ) {
      return []
    }

    const currentSafeRoutes = generatePrefixedAddressRoutes(
      safeAddress
    )

    const assetsSubItems = [
      makeEntryItem({
        label: t('Balances.Coins'),
        iconType: 'assets',
        href: currentSafeRoutes.ASSETS_BALANCES,
      }),
      makeEntryItem({
        // disabled: !isCollectiblesEnabled,
        label: t('Balances.Collectibles'),
        iconType: 'collectibles',
        href: currentSafeRoutes.ASSETS_BALANCES_COLLECTIBLES,
      }),
    ]

    const settingsSubItems = [
      makeEntryItem({
        label: t('Balances.SafeDetails'),
        // badge: needsUpdate && granted,
        iconType: 'info',
        href: currentSafeRoutes.SETTINGS_DETAILS,
      }),
      // makeEntryItem({
      //   label: 'Appearance',
      //   iconType: 'eye',
      //   href: currentSafeRoutes.SETTINGS_APPEARANCE,
      // }),
      makeEntryItem({
        label: t('Reuse.Owners'),
        iconType: 'owners',
        href: currentSafeRoutes.SETTINGS_OWNERS,
      }),
      makeEntryItem({
        label: t('Balances.Policies'),
        iconType: 'requiredConfirmations',
        href: currentSafeRoutes.SETTINGS_POLICIES,
      }),
      // makeEntryItem({
      //   // disabled: !isSpendingLimitEnabled,
      //   label: 'Spending Limit',
      //   iconType: 'fuelIndicator',
      //   href: currentSafeRoutes.SETTINGS_SPENDING_LIMIT,
      // }),
      // makeEntryItem({
      //   label: 'Advanced',
      //   iconType: 'settingsTool',
      //   href: currentSafeRoutes.SETTINGS_ADVANCED,
      // }),
    ].filter(Boolean)

    const protocolsSubItems = [
      makeEntryItem({
        label: t('Balances.KlaySwap'),
        href: currentSafeRoutes.PROTOCOLS_SWAP,
      }),
    ].filter(Boolean)

    return [
      makeEntryItem({
        label: t('Balances.Asset'),
        iconType: 'assets',
        href: currentSafeRoutes.ASSETS_BALANCES,
        subItems: assetsSubItems,
      }),
      makeEntryItem({
        label: t('Balances.Transactions'),
        iconType: 'transactionsInactive',
        href: currentSafeRoutes.TRANSACTIONS_HISTORY,
      }),
      makeEntryItem({
        label: t('Balances.TokenMinter'),
        href: currentSafeRoutes.TOKENMINTER,
      }),
      makeEntryItem({
        label: t('Balances.TokenLock'),
        href: currentSafeRoutes.TOKENLOCK,
      }),
      makeEntryItem({
        label: t('Balances.Protocols'),
        href: currentSafeRoutes.PROTOCOLS_SWAP,
        subItems: protocolsSubItems,
      }),
      makeEntryItem({
        label: t('Balances.AddressBook'),
        iconType: 'addressBook',
        href: currentSafeRoutes.ADDRESS_BOOK,
      }),
      // makeEntryItem({
      //   // disabled: !safeAppsEnabled,
      //   label: 'Apps',
      //   iconType: 'apps',
      //   href: currentSafeRoutes.APPS,
      // }),
      makeEntryItem({
        label: t('Balances.Settings'),
        iconType: 'settings',
        href: currentSafeRoutes.SETTINGS_DETAILS,
        subItems: settingsSubItems,
      }),
    ]
  }, [
    // featuresEnabled,
    // granted,
    // isCollectiblesEnabled,
    // isSpendingLimitEnabled,
    makeEntryItem,
    matchSafe,
    matchSafeWithSidebarSection,
    // needsUpdate,
    safeAddress,
    // safeAppsEnabled,
  ])
}

export { useSidebarItems }
