// import Web3 from 'web3'
// import web3NoAccount from 'utils/web3'
import cavNoAccount from 'utils/useCaver'
import CaverJSExtKAS from 'caver-js-ext-kas'

// Addresses
import {
  getSmartContractAddress
} from 'utils/addressHelpers'

// ABI
import tokenStakeAbi from 'config/abi/abi_smart_contract.json'

import deployAbi from 'config/abi/deploy.json'

// const getContract = (abi: any, address: string, web3?: Web3) => {
//   const _web3 = web3 ?? web3NoAccount
//   return new _web3.eth.Contract((abi as unknown) as AbiItem, address)
// }

const accessKey = 'KASK2GSN30J8N3E5YWAAGVWJ';
const secretKey = 'U8_7lhepvaZK9cj0Wezpyedw78_Kp0WM6Rr-Wzrq';

export const getContractKaikas = (abi: any, address: string, cav?: any) => {
  const _cav = cav ?? cavNoAccount
  return new _cav.klay.Contract((abi as unknown) as any, address)
}

const deployContractKaikas = (abi: any,cav?: any) => {
  const _cav = cav ?? cavNoAccount
  return new _cav.klay.Contract((abi as unknown) as any)
}

export const getDeployContract = (cav?: any) => {
  return deployContractKaikas(deployAbi, cav)
}

export const getContractSafe = (address?: string, cav?: any) => {
  return getContractKaikas(deployAbi,address, cav)
}


export const getStakeSmartContractCaverKas = () => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10) || 8217
  const abiJson = tokenStakeAbi
    const caver = new CaverJSExtKAS(chainId, accessKey, secretKey);
    // eslint-disable-next-line new-cap
    const contractStakeToken = new caver.contract(abiJson, getSmartContractAddress());
  return contractStakeToken
}

export const getCaverKas = () => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10) || 8217
    const caver = new CaverJSExtKAS(chainId, accessKey, secretKey);
    // eslint-disable-next-line new-cap
  return caver
}