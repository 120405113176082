import OpenSansBold from './OpenSans-Bold.ttf'
import OpenSansExtraBold from './OpenSans-ExtraBold.ttf'
import OpenSansRegular from './OpenSans-Regular.ttf'
import OpenSansSemiBold from './OpenSans-SemiBold.ttf'
import OpenSansItalic from './OpenSans-Italic.ttf'
import OpenSansBoldItalic from './OpenSans-BoldItalic.ttf'
import OpenSansExtraBoldItalic from './OpenSans-ExtraBoldItalic.ttf'
import SegoeUIRegular from './SegoeUI-Regular.ttf'
import SegoeUIBold from './SegoeUI-Bold.ttf'
import SegoeUIItalic from './SegoeUI-Italic.ttf'

const Fonts = {
  OpenSansBold,
  OpenSansExtraBold,
  OpenSansRegular,
  OpenSansSemiBold,
  OpenSansItalic,
  OpenSansBoldItalic,
  OpenSansExtraBoldItalic,
  SegoeUIRegular,
  SegoeUIBold,
  SegoeUIItalic
}
export default Fonts
