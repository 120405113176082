import { useCallback } from 'react'
import { confirmTransaction } from 'utils/callHelpers'
import { useUserData } from 'state/hooks'
import { ConfirmTransaction } from 'apis'

const useConfirmTransaction = (
  contractSafe?: any,
  safeAddress?: any,
  chainId?: any,
  _txIndex?: any,
  idTransaction?: any,
) => {
  const { account } = useUserData()
  const handleConfirmTransaction = useCallback(async () => {

    try {
      const tx = await confirmTransaction(contractSafe, account, _txIndex)

      if (tx?.transactionHash) {
        const dataTransaction = {
          chainId,
          safeAddress,
          ownerAddress: account,
          hash: tx?.transactionHash,
          idTransaction,
        }

        const result = await ConfirmTransaction(dataTransaction)
        return result
      }
      return tx
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error handleSubmit', e)
      return false
    }
  }, [contractSafe, chainId, _txIndex, idTransaction])

  return { onConfirmTransaction: handleConfirmTransaction }
}

export default useConfirmTransaction
