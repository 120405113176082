import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// import { BrowserRouter } from 'react-router-dom';
import { Router } from 'react-router-dom'
import history from 'routerHistory'
import common_kr from "translations/kr/common.json";
import common_en from "translations/en/common.json";
import { StyledEngineProvider  } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components';
import { SafeListSidebar } from 'views/SafeListSidebar'
// import Loading from 'views/Loading'
import Loading from 'components/LoadingNew'
import App from './App'
import Providers from './Providers'


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
    kr: {
      common: common_kr
    },
    en: {
      common: common_en               // 'common' is our custom namespace
    },
  },
});

const theme ={
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
}
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme} >

      <Router history={history}>
      <Providers>
        <Suspense fallback={<Loading show />}>
          <SafeListSidebar>
            <App />
          </SafeListSidebar>
        </Suspense>
      </Providers>
      </Router>
    </ThemeProvider>

    </StyledEngineProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
