import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import CheckIcon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import reactLocalStorage from 'utils/localStorageConfig/localStorage'
import { Mobile, Default } from 'components/Responsive'
import langEn from 'assets/images/lang-en.png'
import langKr from 'assets/images/lang-kr.png'

const listOptions = [
  {
    src: langKr,
    name: 'Korea',
    lang: 'kr',
  },
  {
    src: langEn,
    name: 'English',
    lang: 'en',
  },
]

const Warraper = styled.div`
  width: 100%;
`
const StyledItemTextLang = styled.div`
  /* color: #525356; */
  text-transform: uppercase;
`

const StyledImageLang = styled.img`
  width: 24px;
`
const StyledTextLang = styled.div`
  /* color: #525356; */
  font-family: OpenSans-ExtraBold;
  font-size: 16px;
  text-transform: uppercase;
`

const StyledMenuItem = styled(MenuItem)`
  /* color: #525356; */
  min-width: 80px;
  min-height: 36px;
`

const SwitchLang: React.FC = () => {
  const { i18n } = useTranslation('common')
  const [langActive, setLangActive] = useState(listOptions[0])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSwitch = (item) => {
    handleClose()
    setLangActive(item)
    i18n?.changeLanguage(item?.lang)
    getLangSelected(item)
  }

  useEffect(() => {
    const lang = JSON.parse(reactLocalStorage.get('langKlaysafe', JSON.stringify(langActive)))
    i18n?.changeLanguage(lang?.lang ?? 'en')
    setLangActive(lang)
  }, [])

  const getLangSelected = (e?: any) => {
    i18n?.changeLanguage(e.lang)
    reactLocalStorage.set('langKlaysafe', JSON.stringify(e))
  }

  return (
    <Warraper>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Default>
          <StyledImageLang src={langActive?.src} alt="language" />
        </Default>
        <Mobile>
          <StyledTextLang>{langActive?.lang}</StyledTextLang>
        </Mobile>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {listOptions.map((item: any, index: any) => {
          return (
            <StyledMenuItem key={index} onClick={() => handleSwitch(item)}>
              <Default>
                <StyledImageLang style={{ marginRight: 5 }} src={item.src} alt="language" />
                {item.name}
              </Default>
              <Mobile>
                <StyledItemTextLang>{item?.lang}</StyledItemTextLang>
              </Mobile>
              {item?.lang ===  langActive?.lang && <CheckIcon style={{ color: '#547bfe', marginLeft: 10 }} />}
            </StyledMenuItem>
          )
        })}
      </Menu>
    </Warraper>
  )
}

export default React.memo(SwitchLang)
