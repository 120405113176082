import React, { createRef, Suspense } from 'react'
import BigNumber from 'bignumber.js'
import { Router, Route, Redirect, useLocation,Switch } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import useEagerConnect from 'hooks/useEagerConnect'
import { useUserData,useFetchPublicData } from 'state/hooks'
import { useAppDispatch } from 'state'
import { fetchCreateUser } from 'state/actions'
import {
  extractPrefixedSafeAddress,
  WELCOME_ROUTE,
  OPEN_SAFE_ROUTE,
  CREATION_PROCESS,
  CREATE_NEW_SAFE,
  EXISTING_SAFE,
  SAFE_ROUTES,
  generateSafeRoute,
  ROOT_ROUTE,
} from 'routes/routes'
import { wrapInSuspense } from 'utils/wrapInSuspense'

import Loading from 'components/LoadingNew'
import { SnackbarProvider } from 'notistack'
import ClearIcon from '@mui/icons-material/Clear'
import { Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import useAuth from 'hooks/useAuth'

/** Page */
// import HomePage from 'views/Global'
import MixLayout from 'views/MixLayout'
import NotFound from 'views/NotFound'

import history from './routerHistory'
import GlobalStyle from './style/Global'

const Welcome = React.lazy(() => import('routes/welcome/Welcome'))
const CreateSafePage = React.lazy(() => import('routes/CreateSafePage'))
const HomePage = React.lazy(() => import('views/Global'))
const PageTest = React.lazy(() => import('views/Test'))
const CreationProcess = React.lazy(() => import('views/CreationProcess'))
const CreateNewSafe = React.lazy(() => import('views/CreateNewSafe'))
const SideBarApps = React.lazy(() => import('views/SideBarApps'))
const SideBarSafeDetail = React.lazy(() => import('views/SideBarSettings/SafeDetail'))
const SideBarPolicies = React.lazy(() => import('views/SideBarSettings/Policies'))
const SpendingLimit = React.lazy(() => import('views/SideBarSettings/SpendingLimit'))
const SideBarAppearance = React.lazy(() => import('views/SideBarSettings/Appearance'))
const SideBarOwners = React.lazy(() => import('views/SideBarSettings/Owners'))
const SideBarAdvanced = React.lazy(() => import('views/SideBarSettings/Advanced'))
const ExistingSafe = React.lazy(() => import('views/ExistingSafe'))
const AssetCoin = React.lazy(() => import('views/Assets/Coin'))
const AssetCollectibles = React.lazy(() => import('views/Assets/Collectibles'))
// const Transactions = React.lazy(() => import('views/Transactions'))
const AddressBook = React.lazy(() => import('views/AddressBook'))
const TxList = React.lazy(() => import('views/Transactions/TxList'))
const Tokenlocker = React.lazy(() => import('views/TokenLocker'))

//v2
const TokenMinter = React.lazy(() => import('views/TokenMinter'))
const Protocols = React.lazy(() => import('views/Protocols'))

declare global {
  interface Window {
    klaytn?: any
  }
}

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
  FORMAT: {
    groupSize: 3,
    groupSeparator: ',',
    decimalSeparator: ',',
  },
})

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { safeAddress } = extractPrefixedSafeAddress(pathname)
  const { logout } = useAuth()
  
  const { account,  } = useUserData()
  
  window?.klaytn?.on('accountsChanged', function (accounts) {
    const accountChange = accounts[0]
    if (accountChange !== account && account) {
      dispatch(fetchCreateUser())
    }
  })
  window?.klaytn?.on('networkChanged', function () {
    dispatch(fetchCreateUser())
  })

  window?.klaytn.on("disconnected", function () {
    // Use the `disconnected` event to check the wallet connection status.
    logout()
  });

  const notistackRef: any = createRef()
  useEagerConnect()
  useFetchPublicData()
  
  const errorr = false

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          <ClearIcon />
        </Button>
      )}
      iconVariant={{
        success: <CheckIcon className="message-success" />,
        info: <ErrorOutlineIcon className="message-info" />,
      }}
      autoHideDuration={3000}
    >
      <Router history={history}>
        <GlobalStyle />
        <Loading />
        {/* <Popup /> */}
        {/* <Menu account={account} login={login} logout={logout} errorr={errorr} /> */}
        {errorr ? (
          <Route component={NotFound} />
        ) : (
          <MixLayout>
            {/* <StakeComponent /> */}
            {/* {location.pathname === "/" &&
              <Redirect exact from="/" to={WELCOME_ROUTE} />
            } */}
            <Switch>
            <Route
              exact
              path={ROOT_ROUTE}
              render={() => {
                // if (!safeAddress) {
                //   return (
                //     <LoadingContainer>
                //       <Loader size="md" />
                //     </LoadingContainer>
                //   )
                // }

                if (safeAddress) {
                  return (
                    <Redirect
                      to={generateSafeRoute(SAFE_ROUTES.ASSETS_BALANCES, {
                        safeAddress,
                      })}
                    />
                  )
                }

                return <Redirect to={WELCOME_ROUTE} />
              }}
            />

            <Route component={Welcome} exact path={WELCOME_ROUTE} />
            <Route component={CreateSafePage} exact path={OPEN_SAFE_ROUTE} />
            <Route component={PageTest} exact path="/test" />
            <Route component={SideBarApps} exact path={SAFE_ROUTES.APPS} />
            <Route component={SideBarSafeDetail} exact path={SAFE_ROUTES.SETTINGS_DETAILS} />
            <Route component={SideBarAppearance} exact path={SAFE_ROUTES.SETTINGS_APPEARANCE} />
            <Route component={SideBarPolicies} exact path={SAFE_ROUTES.SETTINGS_POLICIES} />
            <Route component={SpendingLimit} exact path={SAFE_ROUTES.SETTINGS_SPENDING_LIMIT} />
            <Route component={SideBarOwners} exact path={SAFE_ROUTES.SETTINGS_OWNERS} />
            <Route component={SideBarAdvanced} exact path={SAFE_ROUTES.SETTINGS_ADVANCED} />
            <Route component={CreationProcess} exact path={CREATION_PROCESS} />
            <Route component={CreateNewSafe} exact path={CREATE_NEW_SAFE} />
            <Route component={ExistingSafe} exact path={EXISTING_SAFE} />
            <Route component={AssetCoin} exact path={SAFE_ROUTES.ASSETS_BALANCES} />
            <Route exact component={AssetCollectibles} path={SAFE_ROUTES.ASSETS_BALANCES_COLLECTIBLES} />
            {/* <Route component={Transactions} path={SAFE_ROUTES.TRANSACTIONS_HISTORY} /> */}
            <Route
              exact
              path={[
                SAFE_ROUTES.TRANSACTIONS,
                SAFE_ROUTES.TRANSACTIONS_HISTORY,
                SAFE_ROUTES.TRANSACTIONS_CONFIRM,
                SAFE_ROUTES.TRANSACTIONS_REJECT,
                SAFE_ROUTES.TRANSACTIONS_SINGULAR,
              ]}
              render={() => wrapInSuspense(<TxList />, null)}
            />
            <Route component={AddressBook} exact path={SAFE_ROUTES.ADDRESS_BOOK} />
            
            <Route component={Tokenlocker} exact path={SAFE_ROUTES.TOKENLOCK} />
            <Route component={TokenMinter} exact path={SAFE_ROUTES.TOKENMINTER} />
            <Route component={Protocols} exact path={SAFE_ROUTES.PROTOCOLS_SWAP} />
            
            <Route exact path="/">
              <HomePage />
            </Route>
            </Switch>
          </MixLayout>
        )}
      </Router>
    </SnackbarProvider>
  )
}

export default React.memo(App)
