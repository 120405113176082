import React from 'react'
import styled from 'styled-components'
import Flex from 'components/Box/Flex'
import { Box } from 'components/Box'
import { CloseGobit, ArrowLeftGobit } from 'components/Svg'
import { IconButton } from 'components/Button'
import { secondaryBackground } from 'theme/variables'
import { ModalProps } from './types'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ background }) => background || 'transparent'};
  /* border-bottom: 1px solid #4b4b4b; */
  display: flex;
  padding: 0 24px;
  margin-bottom: 30px;
  @media screen and (min-width: 320px) and (max-width: 425px) {
    /* padding: 10px 0px; */
  }
  @media screen and (min-width: 768px) {
    padding: 0 42px;
  }
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  padding: 0 24px;
  max-width: 100%;

  overflow-y: auto;
  max-height: calc(90vh - 180px);

  &::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: #f1f1f1,
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d4d4d4;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media screen and (min-width: 320px) and (max-width: 720px) {
    /* padding: 30px 0px; */
  }
  @media screen and (min-width: 768px) {
    padding: 0 42px;
  }
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  return (
    <IconButton scale="sm" variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseGobit width="16px" color="#bebebf" />
    </IconButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <IconButton
      variant="text"
      onClick={onBack}
      // area-label="go back" mr="8px"
    >
      <ArrowLeftGobit width="16px" />
    </IconButton>
  )
}

export const ModalContainer = styled(Box)<{ minWidth?: string; maxWidth?: string; placement?: string }>`
  border-radius: 20px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.26);
  width: 100%;
  padding: 32px 0;
  z-index: 99;
  /* z-index: 106000; */
  /* max-width: 630px; */
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  /* overflow-y: auto; */
  /* max-width: 100%; */
  ${({ placement }) =>
    placement === 'top' &&
    `
    top: 5vh;
    transform: translate(-50%,0);
    max-height: calc(100% - 100px);
  `}
`

export const ModalComponentContainer = styled(Box)<{ minWidth?: string; maxWidth?: string; withoutPadding?: boolean }>`
  border-radius: 20px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.26);
  width: 100%;
  /* padding: 42px; */
  padding: ${({ withoutPadding }) => (withoutPadding ? 0 : '32px 24px')};
  z-index: 99;
  /* max-width: 630px; */
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* max-width: 100%; */
  max-height: 90vh;
  overflow: auto;
  :focus-visible,
  :focus {
    outline: 0;
  }
  @media screen and (min-width: 768px) {
    padding: ${({ withoutPadding }) => (withoutPadding ? 0 : '42px')};
  }
  @media screen and (min-width: 320px) and (max-width: 720px) {
    /* padding: 20px 30px; */
    min-height: auto;
  }
  @media screen and (max-width: 540px) {
    /* padding: 32px 16px ; */
    /* max-width: 331px; */
  }
  &::-webkit-scrollbar {
    width: 3px;
    /* display: block; */
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: #e4e6eb;
    border-radius: 0px 0px 9px 9px;
    margin: 18px;
  }

  &::-webkit-scrollbar-thumb {
    background: #547bfe;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #547bfe;
  }
`

export const NoteFooter = styled.div`
  padding: 30px 42px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  background-color: ${secondaryBackground};
`
