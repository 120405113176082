import React, { useCallback } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// import { useWalletModal } from "components/WalletModal";
import styled from 'styled-components'
import { network } from 'components/Menu/config'
// import { useAppDispatch } from 'state'
// import {  setChainId } from 'state/actions'
import { useUserData } from 'state/hooks'
import { Button } from '@mui/material'
import { useAppDispatch } from 'state'
import { setChainId } from 'state/getChainId'

const Warraper = styled.div`
  width: 100%;
  /* margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 720px) {
    margin: auto;
  } */
`

const StyleMenuItem = styled(MenuItem)`
  padding-left: 30px;
  position: relative;
  &::before {
    width: 7px;
    height: 8px;
    content: '';
    position: absolute;
    background: transparent;
    left: 15px;
  }
  &.active {
    color: #547bfe;
    &::before {
      background: #547bfe;
    }
  }
`
const Label = styled.div`
  min-width: 64px;
  padding: 6px 8px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: #547bfe;
`

const SwitchNetword: React.FC = () => {
  const { networkVersion } = useUserData()

  const dispatch = useAppDispatch()
  // const dataChainId = useGetChainId()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (item) => {
    setAnchorEl(null)
    dispatch(setChainId(item))
    handleNetwork(item?.chainId)
  }

  const currentNetwork = network.filter((el) => el.chainId === networkVersion) ?? network[0]
  const labelNetwork = currentNetwork[0]?.name

  const handleNetwork = useCallback(
    async (chainId) => {
      const hexString = chainId?.toString(16)
      if (networkVersion !== chainId)
        window?.klaytn.sendAsync(
          {
            method: 'wallet_switchKlaytnChain',
            params: [
              {
                chainId: `0x${hexString}`,
              },
            ],
          },
          (err, result) => console.log(err, result),
        )
    },
    [networkVersion],
  )

  return (
    <Warraper>
      <Button
        id="basic-button"
        // aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {labelNetwork}
      </Button>
      {/* <Label onClick={handleClick}>{labelNetwork}</Label> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {network.map((item: any, index: any) => {
          return (
            <StyleMenuItem
              key={index}
              className={networkVersion === item?.chainId ? 'active' : ''}
              onClick={() => handleChange(item)}
            >
              {item.name}
            </StyleMenuItem>
          )
        })}
      </Menu>
    </Warraper>
  )
}

export default React.memo(SwitchNetword)
