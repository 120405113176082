/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useMemo, useState} from 'react'
import { isAddress } from 'web3-utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import SelectComponent from 'components/Select'
import InfoAddress from 'components/InfoAddress'
import InputAmount from 'components/InputAmount'

import { useGetSendFund, useGetSafeInfo, useUserData, useGetBalanceSafe } from 'state/hooks'
import useSubmitTransactionSelf from 'hooks/useSubmitTransactionSelf'
import useSubmitTransactionSelfOneOwner from 'hooks/useSubmitTransactionSelfOneOwner'

import { CreateTransaction, CreateTransactionOneOwner } from 'apis'
import { navigateToTx } from 'utils/transactions'

import { ButtonFooter } from 'components/Modal'
import InputAddress from 'components/InputAddress'
import { tolowercaseAddress } from 'utils/checksumAddress'
import tokenDefault from 'assets/images/token_default.png'
import { Modal } from '../Modal'
import { Title, Row, ImageToken, TextToken,  } from './style'

interface Props {
  // eslint-disable-next-line react/require-default-props
  onDismiss?: () => void
}

const CTModal = styled(Modal)`
  /* &.fixed{
       overflow-y: scroll;
    max-height: 70%;
    background-color: red;
  } */
  &.keyboard {
    overflow-y: scroll;
    max-height: 70%;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  @media screen and (max-width: 624px) {
    min-width: 100%;
    /* overflow-y: scroll;
    max-height: 90%; */
  }
`

const SendFunds: React.FC<Props> = ({ onDismiss = () => null }) => {
  const { t } = useTranslation('common')
  const { account } = useUserData()
  const { listCoin } = useGetBalanceSafe()
  const { address: safeAddress, nameSafe, chainId, threshold } = useGetSafeInfo()
  const { symbol } = useGetSendFund()

  const [amount, setAmount] = useState('0')
  const [address, setAddress] = useState('')
  const [valueAsset, setValueAsset] = useState('')
  const [onFocus, setOnFocus] = useState(false)
  const [step2, setStep2] = useState(false)

  const [dataSelect, setDataSelect] = useState<any>([])
  const [txPending, setTxPending] = useState(false)

  // eslint-disable-next-line no-unneeded-ternary
  const valueSymbol = valueAsset ? valueAsset : symbol
  const _type = valueSymbol === 'KLAY' ? 3 : 4

  const { _token, balanceToken, decimalsToken, _logoUri } = useMemo(() => {
    const dataOption: any = dataSelect.filter((v: any) => v?.label === valueSymbol) ?? []
    const token = dataOption[0]?.tokenInfo?.address
    const decimals = dataOption[0]?.tokenInfo?.decimals
    const logoUri = dataOption[0]?.tokenInfo?.logoUri
    const _balance = dataOption[0]?.balance
    return { _token: token, balanceToken: _balance, decimalsToken: decimals, _logoUri: logoUri }
  }, [dataSelect, valueSymbol])

  const { onSubmitTransactionSelf } = useSubmitTransactionSelf(_type, address, _token)
  const { onSubmitTransactionSelfOneOwner } = useSubmitTransactionSelfOneOwner(_type, address, _token)

  // const { onConfirmTransactionSelf } = useConfirmTransactionSelf(
  //   safeAddress,
  //   chainId,
  //   dataSubmit?.tx_index,
  //   dataSubmit?.id,
  //   // dataSubmit?.tx_hash_create,
  // )
  // const { onExecuteTransactionSelf } = useExecuteTransactionSelf(
  //   safeAddress,
  //   chainId,
  //   _type,
  //   address,
  //   _token,
  //   amount,
  //   dataSubmit?.tx_index,
  //   dataSubmit?.id,
  //   dataSubmit?.tx_hash_create,
  // )

  useEffect(() => {
    const dataList = listCoin.map((v: any) => {
      return {
        ...v,
        label: v?.tokenInfo?.symbol,
        value: v?.tokenInfo?.symbol,
        // value: v,
      }
    })
    setDataSelect(dataList)
  }, [listCoin])

  // console.log('dataEncode', dataEncode)
  const dataTransaction = {
    chainId,
    safeAddress,
    type: valueSymbol === 'KLAY' ? 'SEND_KLAY' : 'SEND_TOKEN',
    creatorAddress: account,
    to: address,
    amount: Number(new BigNumber(amount).times(new BigNumber(10).pow(18))),
    tokenSymbol: valueSymbol,
    tokenDecimals: decimalsToken,
    tokenContract: _token,
  }

  const handleSendFundOwner = useCallback(async () => {
    try {
      setTxPending(true)
      const dataResult = await onSubmitTransactionSelf(amount)
      setTxPending(false)
      if (dataResult?.status) {
        const result: any = await CreateTransaction({ ...dataTransaction, hash: dataResult?.transactionHash })
        if (result?.status === 200) {
          navigateToTx(safeAddress, result?.data?.id)
          onDismiss()
        }
        // setDataSubmit(result?.data)
      }

      // const dataResult = await onExecuteTransactionSelf()
    } catch (e) {
      console.error(e)
    }
  }, [amount, onSubmitTransactionSelf])

  const handleSendFundOneOwner = useCallback(async () => {
    try {
      setTxPending(true)
      const dataResult = await onSubmitTransactionSelfOneOwner(amount)
      setTxPending(false)
      if (dataResult?.status) {
        const result: any = await CreateTransactionOneOwner({ ...dataTransaction, hash: dataResult?.transactionHash })
        if (result?.status === 200) {
          navigateToTx(safeAddress, result?.data?.id)
          onDismiss()
        }
        // setDataSubmit(result?.data)
      }
      // const dataResult = await onExecuteTransactionSelf()
    } catch (e) {
      console.error(e)
    }
  }, [amount, onSubmitTransactionSelfOneOwner])

  const handleSendFund = threshold === 1 ? handleSendFundOneOwner : handleSendFundOwner

  const handleConfirm = () => {
    // eslint-disable-next-line no-unused-expressions
    !step2 ? setStep2(true) : handleSendFund()
    // handleDeposit()
  }

  // const [checked, setChecked] = useState(false)
  const checkAddress = address && tolowercaseAddress(safeAddress) === tolowercaseAddress(address)
  // const checkAddress = address && safeAddress? === tolowercaseAddress(address)
  const userAgent = navigator.userAgent.toLowerCase()
  const checkVwAndroid = userAgent.includes('wv')
  

  const contentStep2 = () => {
    return (
      <>
        <Row className="mb">
          <Title>{t('Reuse.SendingFrom')}</Title>
          <InfoAddress
            address={safeAddress}
            width={42}
          />
        </Row>
        <Row className="mb">
          <Title> {t('Transaction.Recipient')}</Title>
          <InfoAddress
            // name="Sam"
            address={address}
            width={42}
          />
        </Row>
        <Row className="mb">
          <Title> {t('Reuse.Token')}</Title>
          <Row>
            <ImageToken src={_logoUri || tokenDefault} alt="" />
            <TextToken>
              {amount} {valueSymbol}
            </TextToken>
          </Row>
        </Row>
      </>
    )
  }

  return (
    <CTModal
      title={t('Reuse.SendFunds')}
      step={step2 ? `2 ${t('Reuse.of')} 2` : `1 ${t('Reuse.of')} 2`}
      onDismiss={onDismiss}
      id="send_fund"
      className={onFocus && checkVwAndroid ? 'keyboard' : ''}
      footer={
        <ButtonFooter
          textAlign="right"
          cancelButtonProps={{
            onClick: () => {
              return step2 ? setStep2(false) : onDismiss()
            },
            text: step2 ? t('Button.Back') : t('Button.Cancel'),
          }}
          confirmButtonProps={{
            onClick: handleConfirm,
            disabled:
              !amount ||
              amount === '0' ||
              !isAddress(address) ||
              txPending ||
              Number(balanceToken) < Number(amount) ||
              checkAddress,
            //   submitting ||
            //   validating ||
            //   ((!valid || !!submitError) && !modifiedSinceLastSubmit) ||
            //   (requiresMethod && !method),
            // status: submitting || validating ? ButtonStatus.LOADING : ButtonStatus.READY,
            // testId: `${isReadMethod(method) ? 'call' : 'review'}-tx-btn`,
            text: step2 ? t('Button.Submit') : t('Button.Review'),
          }}
        />
      }
    >
      {step2 ? (
        contentStep2()
      ) : (
        <>
          <Row className="mb">
            <InfoAddress
              name={nameSafe}
              address={safeAddress}
              width={42}
            />
          </Row>

          <Row className="mb">
            <Title> {t('Transaction.Recipient')}*</Title>
            <InputAddress
              placeholder="Recipient"
              onChange={(e) => setAddress(e)}
              onFocus={() => setOnFocus(true)}
              onBlur={() => setOnFocus(false)}
            />
          </Row>

          <Row className="mb">
            <Title> {t('Transaction.SelectAnAsset')} *</Title>
            <SelectComponent
              text="Asset"
              type="token"
              dataSelect={dataSelect}
              onChange={(e) => setValueAsset(e)}
              defaultValue={symbol}
            />
          </Row>

          <Row className="mb">
            <Title>
              <span>{t('Reuse.Amount')} *</span>
              <span
                className="send-max"
                onClick={() => {
                  setAmount(balanceToken)
                  // amountRef.current.value = balanceToken
                }}
              >
                {t('Reuse.SendMax')}
              </span>
            </Title>
            {/* <InputLabel placeholder='Amount' valueInput={amount} number onChange={(e) => setAmount(e)} titleRight={valueSymbol} /> */}
            <InputAmount
              onFocus={() => setOnFocus(true)}
              onBlur={() => setOnFocus(false)}
              placeholder="Amount"
              defaultValue={amount}
              onChange={(e) => setAmount(e)}
              titleRight={valueSymbol}
            />
          </Row>
        </>
      )}
    </CTModal>
  )
}

export default SendFunds
