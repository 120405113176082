/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { GetSafeInfo } from 'apis'

// import { DataGasLimitState } from '../types'

const initialState: any = { address: '', chainId: null, owners: [], threshold: 0, nameSafe: '' }

export const safeInfoSlice = createSlice({
  name: 'GetSafeInfo',
  initialState,
  reducers: {
    setDataSafeInfo: (state, action: any) => {
      const { address, chainId, threshold, owners, name } = action.payload
      // return { ...state, data: action.payload}
      if (address) {
        state.address = address
      }
      if (chainId) {
        state.chainId = chainId
      }
      if (threshold) {
        state.threshold = threshold
      }
      if (owners) {
        state.owners = owners
      }
      if (name) {
        state.nameSafe = name
      }
      // state.chainId = action.payload.chainId
      // state.threshold = action.payload.threshold
      // state.owners = action.payload.owners
      // state.nameSafe = action.payload.name

      // return action.payload
    },
  },
})

// Actions
export const { setDataSafeInfo } = safeInfoSlice.actions

// Thunks
export const fetchSafeInfo = (params?: any) => async (dispatch?: any) => {
  const result: any = await GetSafeInfo(params)

  dispatch(setDataSafeInfo(result?.data ?? {}))
}

export default safeInfoSlice.reducer
