import { useMemo } from 'react'
import {
  getContractKaikas,
  getContractSafe
} from 'utils/contractHelpers'
import { isChecksumAddress} from 'utils/checksumAddress'
import {  useGetSafeInfo } from 'state/hooks'
import { useCaver } from 'utils/useCaver'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useContract = (abi?: any, address?: string, cav ?: any) => {
  const checkAddress = isChecksumAddress(address)
  const addr = checkAddress ?address : ''
  return useMemo(() => getContractKaikas(abi, addr, cav), [abi, addr, cav])
}


export const useContractSafe = () => {
  const { address } = useGetSafeInfo()
  const cav: any = useCaver()
  return useMemo(() => getContractSafe(address, cav), [ address, cav])
}
