import React, { useCallback, useState } from "react";
import { useTranslation } from 'react-i18next'
import styled from "styled-components";
import useDeposit from 'hooks/useDeposit'
import { extractPrefixedSafeAddress, generateSafeRoute, SAFE_ROUTES } from 'routes/routes'
import { useLocation } from 'react-router-dom'
import history from 'routerHistory'

import InputLabel from "components/InputLabel";
import SelectComponent from "components/Select";
import InfoAddress from "components/InfoAddress";
import { ButtonFooter } from "components/Modal";
import { useUserData, useGetSafeInfo } from 'state/hooks'
import kaikas from 'assets/images/kaikas.png'

import { Modal } from "../Modal";
import {
  Title,
  Row,
  ImageToken,
  TextToken,
} from "./style"


interface Props {
  // eslint-disable-next-line react/require-default-props
  onDismiss?: () => void;
}

const CTModal = styled(Modal)`
  @media screen and (max-width: 624px) {
    min-width: 100%;
  }
`;

const dataValue = [
  {
    label: 'Klay',
    value: '0',
  },
]

const Deposit: React.FC<Props> = ({ onDismiss = () => null }) => {
  const { t } = useTranslation('common')
  const [amount, setAmount] = useState('')
  const { account, nameOwner } = useUserData()
  const { address, nameSafe, chainId } = useGetSafeInfo()
  const [step2, setStep2] = useState(false)
  const [txPending, setTxPending] = useState(false)

  const { pathname } = useLocation()
  const linkHistory = generateSafeRoute(SAFE_ROUTES.TRANSACTIONS_HISTORY, extractPrefixedSafeAddress())

  // console.log('nameOwner', nameOwner)
  const { onDeposit } = useDeposit(address,chainId)

  const handleDeposit  = useCallback(async () => {
    try {
      setTxPending(true)
      const resultTx: any = await onDeposit(amount)
      setTxPending(false)
      if(resultTx?.status){
        onDismiss()
        if(pathname === linkHistory){
          history.push(linkHistory, {type: 'reload'})
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [onDeposit,amount])
  // const [checked, setChecked] = useState(false)
  // console.log('address', address, nameSafe)
  // useEffect(() => {
  //   const fetchByOwner = async () => {
  //     const params = {
  //       ownerAddress: account,
  //       chainId: networkVersion
  //     }
  //     const result = await GetNameOwner(params)
  //     dispatch(setListOwnerSmart(result))
  //   }
  //   // eslint-disable-next-line no-unused-expressions
  //   account && networkVersion && fetchByOwner()
  // }, [account, networkVersion])
  const handleAmount = (value) => {
    setAmount(value)
  };

  const contentStep2 = () => {
    return (
      <>
        <Row className="mb">
          <Title>
          {t('Reuse.SendingFrom')}
          </Title>
          <InfoAddress
            name={nameOwner}
            address={account}
            width={42}
          />
        </Row>
        <Row className="mb">
          <Title>
          {t('Transaction.Recipient')}
          </Title>
          <InfoAddress
            name={nameSafe}
            address={address}
            width={42}
          />
        </Row>
        <Row className="mb">
          <Title>
          {t('Reuse.Token')}
          </Title>
          <Row>
            <ImageToken src={kaikas} alt='' />
            <TextToken>
              {amount} Klay
            </TextToken>
          </Row>
        </Row>


      </>)
  }

  return (
    <CTModal title={t('Reuse.Deposit')} step={step2 ? `2 ${t('Reuse.of')} 2` : `1 ${t('Reuse.of')} 2`} onDismiss={onDismiss}
      footer={
        <ButtonFooter
          textAlign='right'
          cancelButtonProps={{
            onClick:
              () => {
                return step2 ? setStep2(false) : onDismiss()
              },
            text: step2 ? t('Button.Back') : t('Button.Cancel') ,
          }}
          confirmButtonProps={{
            onClick: () => {
              return !step2 ? setStep2(true) : (
                // onDismiss(),
                handleDeposit()
                )
            },
            disabled: !amount || txPending,
            //   submitting ||
            //   validating ||
            //   ((!valid || !!submitError) && !modifiedSinceLastSubmit) ||
            //   (requiresMethod && !method),
            // status: submitting || validating ? ButtonStatus.LOADING : ButtonStatus.READY,
            // testId: `${isReadMethod(method) ? 'call' : 'review'}-tx-btn`,
            text: step2 ? t('Button.Submit') : t('Button.Review'),
          }}
        />
      }
      // note={step2 ? `
      // You're about to create a transaction and will have to confirm it with your currently connected wallet. make sure you have <0.001 BNB in this wallet to fund the associated transaction fee.
      // ` : ''}
    >
      {
        step2 ? contentStep2() :
          <>
            <Row className="mb">
              <InfoAddress
                name={nameOwner}
                address={account}
                width={42}
              />
            </Row>

            <Row className="mb">
              <Title>
                {t('Transaction.Recipient')}
              </Title>
              <InfoAddress
                name={nameSafe}
                address={address}
                width={42}
              />
            </Row>

            <Row className="mb">
              <Title>
              {t('Transaction.SelectAnAsset')} *
              </Title>
              <SelectComponent
                defaultValue='0'
                dataSelect={dataValue}
                // onChange={(e) => setValueAsset(e)}
              />
            </Row>

            <Row className="mb">
              <Title>
                <span> {t('Reuse.Amount')} *</span>
                {/* <span className="send-max">
                  {t('Reuse.SendMax')}
                </span> */}
              </Title>
              <InputLabel
                valueInput={amount}
                number
                onChange={handleAmount}
                titleRight='Klay'
              />
            </Row>


          </>
      }


    </CTModal>
  )
};

export default Deposit;

