import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useLocation } from 'react-router-dom'
import { setListOwnerSmart, setDataSafeInfo, fetchBalanceSafe, setDataUserCreate,fetchNFTSafe } from 'state/actions'
import {
  extractPrefixedSafeAddress,
} from 'routes/routes'
import { GetByOwner, GetSafeInfo, GetNameOwner } from 'apis'
import { State } from './types'

// const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { currency } = useGetCurrency()
  // const { slowRefresh } = useRefresh()
  const { safeAddress } = extractPrefixedSafeAddress(pathname)
  // const safeAddress =''
  const { account, networkVersion } = useUserData()

  // useEffect(() => {
  //   // dispatch(fetchBalance())
  //   // dispatch(fetchPakageIdInfo())
  //   // dispatch(fetchPriceKlay())
  // }, [dispatch, slowRefresh])
  useEffect(() => {
    const fetchByOwner = async () => {
      const params = {
        ownerAddress: account,
        chainId: networkVersion,
      }
      const result: any = await GetByOwner(params)
      dispatch(setListOwnerSmart(result?.data ?? []))
    }
    const fetchOwnerName = async () => {
      const params = {
        ownerAddress: account,
        chainId: networkVersion,
      }
      const result: any = await GetNameOwner(params)
      const name = result?.data ?? ''
      dispatch(setDataUserCreate({ nameOwner: name }))
    }
    if (account && networkVersion) {
      fetchByOwner()
      fetchOwnerName()
    }
  }, [account, networkVersion,dispatch])

  useEffect(() => {
    const fetchSafeInfo = async () => {
      const params = {
        safeAddress,
        chainId: networkVersion,
      }
      const result: any = await GetSafeInfo(params)
      if (result?.status === 200) {
        dispatch(setDataSafeInfo(result?.data ?? {}))
      }
    }
    // eslint-disable-next-line no-unused-expressions
    if (safeAddress && networkVersion) {
      const params = {
        safeAddress,
        chainId: networkVersion,
        country: currency,
      }
      fetchSafeInfo()
      dispatch(fetchBalanceSafe(params))
    }
  }, [safeAddress, networkVersion, currency,dispatch])
  useEffect(() => {
    const fetchByOwner = async () => {
      const params = {
        safeAddress,
        chainId: networkVersion
      }
      dispatch(fetchNFTSafe(params))

      // const result: any = await GetSafeNFTs(params)
      // setDataListNFT(result?.data ?? [])
      // dispatch(setListOwnerSmart(result))
    }
    // eslint-disable-next-line no-unused-expressions
    account && safeAddress && fetchByOwner()
  }, [account, safeAddress])
}


export const usePoolFromName = (name) => {
  const pool = useSelector((state: State) =>
    state?.listPools?.data.filter((f) => f?.name?.toUpperCase() === name?.toUpperCase()),
  )
  return pool
}

export const useUserData = () => {
  return useSelector((state: State) => {
    return state.dataUser
  })
}

export const useGetListPoolsData = () => {
  return useSelector((state: State) => {
    return state.listPools
  })
}

export const useGetPopupData = () => {
  return useSelector((state: State) => {
    return state.popup
  })
}

export const useGetIndicatorData = () => {
  return useSelector((state: State) => {
    return state.indicator
  })
}


export const useGetBalanceSafe = () => {
  return useSelector((state: State) => {
    return state.balanceSafe
  })
}

export const useGetNFTSafe = () => {
  return useSelector((state: State) => {
    return state.NFTSafe
  })
}

export const useCheckStep = () => {
  return useSelector((state: State) => {
    return state.checkStep
  })
}

export const useCheckStepExisting = () => {
  return useSelector((state: State) => {
    return state.checkStepExisting
  })
}

export const useGetChainId = () => {
  return useSelector((state: State) => {
    return state.getChainId
  })
}

export const useGetListOwnerSmart = () => {
  return useSelector((state: State) => {
    return state.listOwnerSmart.dataList
  })
}

export const useGetSafeInfo = () => {
  return useSelector((state: State) => {
    return state.safeInfo
  })
}

export const useGetSendFund = () => {
  return useSelector((state: State) => {
    return state.dataSendFund
  })
}

export const useGetCurrency= () => {
  return useSelector((state: State) => {
    return state.dataCurrency
  })
}

export const useGetBookAddress = () => {
  return useSelector((state: State) => {
    return state.dataBookAddress
  })
}
