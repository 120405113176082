import { Mutator, } from 'final-form'
// import { ContractSendMethod } from 'web3-eth-contract'
import {
  getContractKaikas,
} from 'utils/contractHelpers'
import { AbiItemExtended } from 'logic/contractInteraction/sources/ABIService'
// import { getAddressFromDomain, getWeb3 } from 'src/logic/wallets/getWeb3'

import { BigNumber } from 'bignumber.js'

export const isValidEnsName = (name: string): boolean => /^([\w-]+\.)+(eth|test|xyz|luxe|ewc)$/.test(name)
export const isValidCryptoDomainName = (name: string): boolean => /^([\w-]+\.)+(crypto)$/.test(name)

export type TransactionReviewType = {
  abi?: string
  contractAddress?: string
  data?: string
  value?: string
  selectedMethod?: AbiItemExtended
}

export const NO_CONTRACT = 'no contract'


export const formMutators: Record<string, Mutator<{ selectedMethod: { name: string } }>> = {
  setMax: (args, state, utils) => {
    utils.changeValue(state, 'value', () => args[0])
  },
  setContractAddress: (args, state, utils) => {
    utils.changeValue(state, 'contractAddress', () => args[0])
  },
  setSelectedMethod: (args, state, utils) => {
    const modified =
      state.lastFormState?.values.selectedMethod && state.lastFormState.values.selectedMethod.name !== args[0].name

    if (modified) {
      utils.changeValue(state, 'callResults', () => '')
      utils.changeValue(state, 'value', () => '')
    }

    utils.changeValue(state, 'selectedMethod', () => args[0])
  },
  setCallResults: (args, state, utils) => {
    utils.changeValue(state, 'callResults', () => args[0])
  },
  setAbiValue: (args, state, utils) => {
    utils.changeValue(state, 'abi', () => args[0])
  },
}

export const isAddress = (type: string): boolean => type.indexOf('address') === 0
export const isBoolean = (type: string): boolean => type.indexOf('bool') === 0
export const isString = (type: string): boolean => type.indexOf('string') === 0
export const isUint = (type: string): boolean => type.indexOf('uint') === 0
export const isInt = (type: string): boolean => type.indexOf('int') === 0
export const isByte = (type: string): boolean => type.indexOf('byte') === 0

export const isArrayParameter = (parameter: string): boolean => /(\[\d*])+$/.test(parameter)
export const getParsedJSONOrArrayFromString = (parameter: string): (string | number)[] | null => {
  try {
    const arrayResult = JSON.parse(parameter)
    return arrayResult.map((value) => {
      if (Number.isInteger(value)) {
        return new BigNumber(value).toString()
      }
      return value
    })
  } catch (err) {
    return null
  }
}

// export const handleSubmitError = (error: SubmissionErrors, values: Record<string, string>): Record<string, string> => {
//   for (const key in values) {
//     if (values.hasOwnProperty(key) && error !== undefined && values[key] === error.value) {
//       return { [key]: error.reason }
//     }
//   }

//   // .call() failed and we're logging a generic error
//   return { [FORM_ERROR]: error ? error.message : undefined }
// }

export const generateFormFieldKey = (type: string, signatureHash: string, index: number): string => {
  const keyType = isArrayParameter(type) ? 'arrayParam' : type
  return `methodInput-${signatureHash}_${index}_${keyType}`
}

const extractMethodArgs =
  (signatureHash: string, values: Record<string, string>) =>
  ({ type }, index) => {
    const key = generateFormFieldKey(type, signatureHash, index)

    return getParsedJSONOrArrayFromString(values[key]) || values[key]
  }

  export const createTxObject = (
    method: AbiItemExtended,
    contractAddress: string,
    values: Record<string, string>,
  ): any => {
    const contract = getContractKaikas([method], contractAddress)
    const { inputs, name = '', signatureHash } = method
    const args = inputs?.map(extractMethodArgs(signatureHash, values)) || []
  
    return contract.methods[name](...args)
  }
  
export const isReadMethod = (method: AbiItemExtended): boolean => method && method.action === 'read'

export const getValueFromTxInputs = (key?: string, type?: string, tx?: TransactionReviewType): string => {
  if (isArrayParameter(type)) {
  // eslint-disable-next-line no-param-reassign
    key = key.replace('[]', '')
  }

  let value = tx[key]

  if (type === 'bool') {
    value = String(value)
  }

  return value
}
