import { mainAxios } from 'libs/axios'

interface dataStake {
  chainId?:any;
  safeName?:any;
  safeAddress?:any;
  owners?: any[];
  threshold?: any;
  creatorAddress?: any;
  hash?: any;
}

interface nameSafe {
  chainId?:any;
  newSafeName?:any;
  safeAddress?:any;
  ownerAddress?: any;
}


export const CreateSafe = async (data?: dataStake) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/safe/create', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}


export const GetByOwner = async (params?: any) => {
  // eslint-disable-next-line no-console

  return mainAxios.get('/v1/safe/get-by-owner', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    // console.log("response BetByOwner", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.BetByOwner`, error.response);
  });
}

export const GetSafeInfo = async (params?: any) => {
  // eslint-disable-next-line no-console

  return mainAxios.get('/v1/safe/get', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    // console.log("response GetSafeInfo", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.GetSafeInfo`, error.response);
    return error
  });
}

export const GetSafeBalance = async (params?: any) => {
  return mainAxios.get('/v1/safe/balances', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response GetSafeBalance", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.GetSafeInfo`, error.response);
  });
}

export const GetSafeNFTs = async (params?: any) => {
  // eslint-disable-next-line no-console

  return mainAxios.get('/v1/safe/getNFTs', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response GetSafeNFTs", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.GetSafeNFTs`, error.response);
  });
}

export const GetNameOwner = async (params?: any) => {
  // eslint-disable-next-line no-console

  return mainAxios.get('/v1/owner/suggest-name-by-address', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response GetNameOwner", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.GetNameOwner`, error.response);
  });
}


export const UpdateNameOwner = async (data?: nameSafe) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/owner/update-name-owner', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}

export const UpdateNameSafe = async (data?: nameSafe) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/safe/update-name-safe', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}

export const RemoveSafe = async (data?: nameSafe) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/safe/delete', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}

export const GetAddressBook = async (params?: any) => {
  // eslint-disable-next-line no-console

  return mainAxios.get('/v1/address/book', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response GetAddressBook", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.Getaddress book`, error.response);
  });
}