import BigNumber from 'bignumber.js/bignumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const LOCAL_CONFIG_KEY = 'config'

export const LINK_URL_DECENT = 'https://klaysafe.com/welcome'


const chains: any[] = [];
export const getChains = (): any => {
  return chains
}



export const getChainById = (chainId: any): any => {
  return getChains().find((chain) => chain.chainId === chainId) || {}
}