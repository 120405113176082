import { ReactElement } from 'react'
import { useField } from 'react-final-form'
import { Row } from 'style/DefaultStyled'

// import Row from 'components/layout/Row'
import { AbiItemExtended } from 'logic/contractInteraction/sources/ABIService'
import { InputComponent } from './InputComponent'
import { generateFormFieldKey } from '../utils'


export const RenderInputParams = (): ReactElement | null => {
  const {
    meta: { valid: validABI },
  } = useField('abi', { subscription: { valid: true, value: true } })
  const {
    input: { value: method },
  }: { input: { value: AbiItemExtended } } = useField('selectedMethod', { subscription: { value: true } })
  const renderInputs = validABI && !!method && method.inputs?.length

  return !renderInputs ? null : (
    <>
      {method.inputs?.map(({ name, type }, index) => {
        const placeholder = name ? `${name} (${type})` : type
        const key = generateFormFieldKey(type, method.signatureHash, index)

        return (
          <Row key={key} 
          mb="10px"
          >
            <InputComponent type={type} keyValue={key} placeholder={placeholder} />
          </Row>
        )
      })}
    </>
  )
}
