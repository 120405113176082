import {  useUserData } from 'state/hooks'
import { network } from 'components/Menu/config'


export const NameNetwork = () => {
  const { networkVersion } = useUserData()

  const currentNetwork = network.filter((el) => el?.chainId === networkVersion) ?? network[0]
  const labelNetwork = currentNetwork[0]?.name ?? ''
  return labelNetwork
}

