import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, fixed?:number, decimals = 18) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed(fixed)
}

export const getConvertNumber = (num: any, fixed?: number) => {
  const digits = fixed || 6
  // eslint-disable-next-line no-restricted-properties
  const pow = Math.pow(10, digits)
  // eslint-disable-next-line no-useless-escape
  // const re = new RegExp(`^-?\\d+(?:\.\\d{0,${fixed || 6}})?`)
  // const withDecimal = num.toString().match(re) ? num.toString().match(re)[0] : 0
  const withDecimal = Math.floor(num * pow) / pow
  // console.log(`withDecimal`, withDecimal.toLocaleString('fullwide', {useGrouping:false}))
  return Number(withDecimal).toString()
}

/**
 * Formating
 * @param {number} number Number to be formatted
 * @param {number} decimals Decimal to be formatted
 * @param {number} type Type get value (0 => fomat data, 1 => old value data)
 */

export const getFormat = (balance, decimals = 18, type = 0) => {
  const b: any = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals)).toFormat()
  const bf = String(b).replace(/,/g, '')
  if (type === 0) return b
  if (type === 1) return bf
}


/**
 * Sum
 * @param {number} val1 the first value
 * @param {number} val2 the second value
 * @param {number} type Type get value (0 => fomat data, 1 => old value data)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getSum = (val1, val2, decimals = 18, type = 0) => {
  const v1: any = new BigNumber(val1).dividedBy(new BigNumber(10).pow(decimals)).toFixed()
  const v2: any = new BigNumber(val2).dividedBy(new BigNumber(10).pow(decimals)).toFixed()
  let sum: any = BigNumber.sum(v1, v2)
  sum = new BigNumber(sum).dividedBy(1).toFixed()
  return sum
}
