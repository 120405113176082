/**
 * @author [Life]
 * @create date 2021-04-14 09:27:06
 * @modify date 2021-04-14 09:27:06
 * @desc [description]
 */
import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <g />

      <path d="M17 2v1h-17v-1h17zM0 7h17v-1h-17v1zM0 11h17v-1h-17v1zM0 15h17v-1h-17v1z" />
    </Svg>
  )
}

export default Icon
