// import { useField, useForm } from 'react-final-form'
import makeStyles from '@mui/styles/makeStyles';
import { TextAreaField } from 'components/forms/TextAreaField'

// import { TextAreaField } from 'src/components/forms/TextAreaField'
// import { mustBeEthereumAddress, mustBeEthereumContractAddress } from 'src/components/forms/validator'
// import Col from 'src/components/layout/Col'
// import Row from 'src/components/layout/Row'
// import { getContractABI } from 'src/config'
import { extractUsefulMethods } from 'logic/contractInteraction/sources/ABIService'
// import { parsePrefixedAddress } from 'src/utils/prefixedAddress'

export const NO_DATA = 'no data'

const useStyles = makeStyles({
  customTextArea: {
    color: 'red',
    '&::-webkit-scrollbar ': {
      width: '10px',
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }
} as any)

const hasUsefulMethods = (abi: string): undefined | string => {
  try {
    const parsedABI = extractUsefulMethods(JSON.parse(abi))

    if (parsedABI.length === 0) {
      return NO_DATA
    }
  } catch (e) {
    return NO_DATA
  }
}

const ContractABI = (): React.ReactElement => {

  const classes = useStyles()

  // useEffect(() => {
  //   const validateAndSetAbi = async () => {
  //     const isEthereumAddress = mustBeEthereumAddress(contractAddress) === undefined
  //     const isEthereumContractAddress = (await mustBeEthereumContractAddress(contractAddress)) === undefined

  //     if (isEthereumAddress && isEthereumContractAddress) {
  //       const { address } = parsePrefixedAddress(contractAddress)
  //       const abi = await getContractABI(address)
  //       const isValidABI = hasUsefulMethods(abi) === undefined

  //       // this check may help in scenarios where the user first pastes the ABI,
  //       // and then sets a Proxy contract that has no useful methods
  //       if (isValidABI) {
  //         setAbiValue.current(abi)
  //       }
  //     }
  //   }

  //   if (contractAddress) {
  //     validateAndSetAbi()
  //   }
  // }, [contractAddress])

  return (
    <TextAreaField
    className={classes.customTextArea}
    name="abi"
    placeholder="ABI*"
    text="ABI*"
    type="text"
    validate={hasUsefulMethods}
  />
  )
}

export default ContractABI
