import { Tooltip, Button } from '@mui/material'
import React, { useState } from 'react' //lazy
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Layer, NewWindow } from 'components/Svg'
import { useMediaQuery } from 'react-responsive'
import Identicon from 'components/Identicon'
import { useGetCurrency, useUserData } from 'state/hooks'

import { getConvertNumber } from 'utils/formatBalance'

import * as S from './style'

interface Props {
  name?: string
  nameCoin?: string
  address: string
  balance?: any
  width?: any
  onClick?: () => void
  type?: string
  avatarSize?: any
  fullAddressMobile?: boolean
  numberOwner?: string
  justifyContent?: string
}

function InfoAddress({
  name,
  nameCoin,
  address,
  balance,
  width = 32,
  onClick,
  type,
  avatarSize,
  fullAddressMobile,
  justifyContent,
  numberOwner
}: Props): React.ReactElement {
  const { linkScan } = useUserData()
  const { currency } = useGetCurrency()

  // const nameCoin = address.split(':')[0]
  // const addressWallet = address.split(':')[1]
  const [copied, setCopied] = useState(false)
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  const numberLength = address?.length > 42 ? 10 : 6
  const splitAddress = address
    ? `${address.substring(0, numberLength)}...${address.substring(address.length - numberLength, address.length)}`
    : null

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const checkFullAddress = (!!fullAddressMobile && isMobile)

  const linkKlay = address?.length > 42 ? `${linkScan}/tx/${address}` : `${linkScan}/account/${address}`
  return (
    <S.ComponentWp onClick={onClick} type={!!type}>
      <S.Container justifyContent={justifyContent} style={{ alignItems: name ? '' : 'center' }}>
        {width ? (
          <div className="img" style={{ width, flex: `0 0 ${width}px`, marginRight: `${!width || width==='0' && 0}`}}>
            <Identicon address={address} size={avatarSize || 'md'} />
            {/* <img src={url} alt={name} /> */}
          {  numberOwner && <S.NumberOwner>
              {numberOwner}
            </S.NumberOwner>}
          </div>
        ) : null}
        <div>
          <div className="name">{name}</div>
          <div className="address">
            {nameCoin && <span className="name-coin">{`${nameCoin} :`}</span>}
            <span className="address-wallet">
              {(isMobile || type === 'no-icon' || type === 'split-address') && !checkFullAddress ? splitAddress : address}
            </span>
            {type !== 'no-icon' && (
              <div className="icon">
                {/* <Tooltip placement="top" title={clipboard}>
                <Button
                  className="tooltip"
                  // eslint-disable-next-line no-sequences
                  onClick={() => ( copyToClipboard && copyToClipboard(address),setClipboard && setClipboard('Copied'))}
                  onMouseOut={() => setClipboard&& setClipboard('Copy to cliboard')}
                >
                  <Layer color="#547bfe" width={15} />
                </Button>
              </Tooltip> */}
                <CopyToClipboard
                  text={address}
                  onCopy={() => {
                    setCopied(true)
                    setTooltipIsOpen(true)
                    
                  }}
                >
                  <Tooltip
                    open={tooltipIsOpen}
                    onOpen={() => setTooltipIsOpen(true)}
                    onClose={() => setTooltipIsOpen(false)}
                    placement="top"
                    title={copied ? 'Copied' : 'Copy to cliboard'}
                  >
                    <Button
                      onMouseOut={() => {
                        setCopied(false)
                        setTooltipIsOpen(false)
                      }}
                      className="box"
                    >
                      <Layer color="#547bfe" width={15} />
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
                <Button className="link" href={linkKlay} target="_blank">
                  <NewWindow color="#547bfe" width={15} />
                </Button>
              </div>
            )}
          </div>
          {balance && (
            <div className="balance">
              <Button>
                Balance:{' '}
                <span className="value">
                  {getConvertNumber(balance)} {currency}
                </span>
              </Button>
            </div>
          )}
        </div>
      </S.Container>
    </S.ComponentWp>
  )
}

export default InfoAddress
