
export const network = [
  {
    name: "Klay",
    chainId: 8217,
  },
  {
    name: "Klay Testnet",
    chainId: 1001,
  },
];

export const socials = [
  {
    label: "Telegram",
    icon: "TelegramIcon",
    href: "https://t.me/FPOfficail",
  },
  {
    label: "Twitter",
    icon: "TwitterAltGobit",
    href: "https://twitter.com/FrontPointDEFI",
  },
];




export const MENU_HEIGHT = 150;
export const MENU_ENTRY_HEIGHT = 48;
export const SIDEBAR_WIDTH_FULL = 240;
export const SIDEBAR_WIDTH_REDUCED = 56;
