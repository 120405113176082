/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';

import makeBlockie from 'ethereum-blockies-base64';
import styled from 'styled-components';

const theme = {
  identicon: {
    size: {
      xs: '10px',
      sm: '28px',
      md: '32px',
      lg: '40px',
      xl: '48px',
      xxl: '60px',
    },
  },
};
export type Theme = typeof theme;
export type ThemeIdenticonSize = keyof Theme['identicon']['size'];

type Props = {
  address: string;
  size?: ThemeIdenticonSize;
};

const StyledImg = styled.img<{ size: ThemeIdenticonSize }>`
  height: ${({ size }) => theme.identicon.size[size]};
  width: ${({ size }) => theme.identicon.size[size]};
  /* min-width: ${({ size }) => theme.identicon.size[size]}; */
  border-radius: 50%;
`;

const Identicon = ({
  size = 'md',
  address,
  ...rest
}: Props): React.ReactElement => {
  // const iconSrc = React.useMemo(() => makeBlockie(address), [address]);
  const [iconSrc,setIconSrc] = React.useState<any>('')
  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    address && setIconSrc(makeBlockie(address))
  }, [address])

  return address ? <StyledImg src={iconSrc} size={size} {...rest} /> : <></>;
};

export default Identicon;
