/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = { address: '',  }

export const getAddressSmartSlice = createSlice({
  name: 'getAddressSmart',
  initialState,
  reducers: {
    setAddressSmart: (state, action?: any) => {
      return action.payload
    },
  },
})

// Actions
export const { setAddressSmart } = getAddressSmartSlice.actions


export default getAddressSmartSlice.reducer
