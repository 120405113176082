/* eslint-disable react/react-in-jsx-scope */
import { useState, ReactElement, useContext} from 'react'
import { SafeListSidebarContext } from 'views/SafeListSidebar'
import ModalCollectibles from 'components/ModalCollectibles'
import {  useGetSafeInfo, useGetBalanceSafe, useGetCurrency, useUserData } from 'state/hooks'
import { useTranslation } from 'react-i18next'
import { getConvertNumber } from 'utils/formatBalance'
import { useLocation } from 'react-router-dom'
import { useSidebarItems } from 'components/Sidebar/useSidebarItems'
import { Button, Tooltip } from '@mui/material'

import { Lock, AngleRight, AngleLeft } from 'components/Svg'
import { Mobile, Default } from 'components/Responsive'
import Identicon from 'components/Identicon'
import { extractPrefixedSafeAddress } from 'routes/routes'
import { NameNetwork } from 'utils/nameNetwork'
import { CheckOwner } from 'utils/checkOwner'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import sideBarColor2 from 'assets/images/sideBarColor2.png'
import sideBarColor3 from 'assets/images/sideBarColor3.png'
import List from './List'

import {
  Wrapper,
  ChangeSafe,
  ChangeSafeNotConnect,
  EthereumName,
  EthereumSpan,
  Wallet,
  WalletKey,
  WalletContent,
  ListIcons,
  ImgTitleItem,
  ReadOnly,
  ReadOnlyText,
  Money,
  MoneyContent,
  Transaction,
  TransactionText,
  LineDiv,
  NameContentSidebar,
  ContentSafe,
  ContentUser1,
  ButtonRead,
  InfoSafe,
  Name,
  WrapperAvatar,
  NumberOwner,
} from './style'

type Props = {
  onChange?: () => void
}

// const dataItemMain = ['Assets', 'Transactions', 'Address book', 'Apps', 'Settings']
// const dataItemAssets = ['Coins', 'Collectibles']
// const dataItemSetting = ['Safe details', 'Appearance', 'Owners', 'Policies', 'Spending Limit', 'Advanced']

const Sidebar = ({ onChange }: Props): ReactElement => {
  const { t } = useTranslation('common')
  const location = useLocation()
  const { currency } = useGetCurrency()
  const { linkScan } = useUserData()

  const pathname = location?.pathname
  const { safeAddress } = extractPrefixedSafeAddress(pathname)
  const sidebarItems = useSidebarItems()

  // const dispatch = useAppDispatch()
  const { toggleSidebar } = useContext(SafeListSidebarContext)
  const [visible, setVisible] = useState(false)
  const [copied, setCopied] = useState(false)
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  // const dataListOwner = useGetListOwnerSmart()
  const { nameSafe, threshold, owners } = useGetSafeInfo()
  const { fiatTotal } = useGetBalanceSafe()
  // const [itemMain, setItemMain] = useState('')
  // const [itemAssets, setItemAssets] = useState('Coins')
  // const [itemSetting, setItemSetting] = useState('Safe details')
  // const [assets, setAssets] = useState(false)
  // const [setting, setSetting] = useState(false)

  // const pathname: string = window.location.pathname

  const splitAddress = safeAddress
    ? `${safeAddress.substring(0, 6)}...${safeAddress.substring(safeAddress.length - 4, safeAddress.length)}`
    : ''

  const checkOwner = CheckOwner()
  const nameNetwork = NameNetwork()

  const handleScan = () => {
    window.open(`${linkScan}/account/${safeAddress}`)
  }

  return (
    <>
      {safeAddress ? (
        <Wrapper>
          <EthereumName>
            <EthereumSpan>{nameNetwork}</EthereumSpan>
  
          </EthereumName>
          <ContentSafe>
            <InfoSafe>
              <ChangeSafe onClick={toggleSidebar}>
                <Default>
                  <AngleLeft color="#b2b2b2" width={16} className="left" />
                </Default>
            
                <WrapperAvatar>
                  <Identicon address={safeAddress} size="lg" />
                  <NumberOwner>
                    {threshold}/{owners?.length}
                  </NumberOwner>
                </WrapperAvatar>

                <AngleRight color="#b2b2b2" width={16} className="right" />
              </ChangeSafe>
              <Default>
                <Name>{nameSafe}</Name>
              </Default>

              <ContentUser1>
                <Mobile>
                  <Name>{nameSafe}</Name>
                </Mobile>
                <NameContentSidebar>
                  <Wallet>
                    <WalletKey>Klay:</WalletKey>
                    <WalletContent>{splitAddress} </WalletContent>
                  </Wallet>
                  <ListIcons>
                    {/* <ImgTitleItem alt="qr" src={sideBar1} /> */}
                    <CopyToClipboard
                      text={safeAddress}
                      onCopy={() => {
                        setCopied(true)
                        setTooltipIsOpen(true)
                      }}
                    >
                      <Tooltip
                        open={tooltipIsOpen}
                        placement="top"
                        onOpen={() => setTooltipIsOpen(true)}
                        onClose={() => setTooltipIsOpen(false)}
                        title={copied ? 'Copied' : 'Copy to cliboard'}
                      >
                        <Button
                          style={{minWidth: 40}}
                          onMouseOut={() => {
                            setCopied(false)
                            setTooltipIsOpen(false)
                          }}
                          className="box"
                        >
                          <ImgTitleItem alt="file" src={sideBarColor2} />
                        </Button>
                      </Tooltip>
                    </CopyToClipboard>
                    <ImgTitleItem alt="link" src={sideBarColor3} onClick={handleScan} />
                  </ListIcons>
           
                </NameContentSidebar>
                {!checkOwner && (
                  <Mobile>
                    <ReadOnly>
                      <ReadOnlyText>{t('Button.ReadOnly')}</ReadOnlyText>
                    </ReadOnly>
                  </Mobile>
                )}
                {/* {!checkOwner && <ButtonRead disabled>Read Only</ButtonRead>} */}
              </ContentUser1>
              <Mobile>
                <AngleRight onClick={toggleSidebar} color="#b2b2b2" width={16} />
              </Mobile>
            </InfoSafe>

            <Money>
              <MoneyContent>
                {getConvertNumber(fiatTotal)} {currency}
              </MoneyContent>
            </Money>
            <>
              {checkOwner ? (
                <Transaction onClick={() => setVisible(!visible)}>
                  <TransactionText>{t('Button.NewTransaction')}</TransactionText>
                </Transaction>
              ) : (
                <Default>
                  <ButtonRead disabled>{t('Button.ReadOnly')}</ButtonRead>
                </Default>
              )}
            </>
          </ContentSafe>

          <LineDiv />
     
          <List items={sidebarItems} onChange={onChange} />
          <ModalCollectibles visible={visible} onCancel={() => setVisible(false)} />
        </Wrapper>
      ) : (
        <Wrapper className="not_connect">
          <ChangeSafeNotConnect onClick={toggleSidebar}>
            <Lock color="#547bfe" width={16} className="icon_clock" />
            <AngleRight color="#b2b2b2" width={16} />
          </ChangeSafeNotConnect>
        </Wrapper>
      )}
    </>
  )
}

export default Sidebar
