/**
 * @author [Life]
 * @create date 2021-04-14 09:27:06
 * @modify date 2021-04-14 09:27:06
 * @desc [description]
 */
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 75 75" {...props}>
<defs>
    {/* <style>
      .cls-1 {
        fill: #f9f9f9;
      }

      .cls-2 {
        fill: #90fee4;
      }

      .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
        fill: none;
        stroke-linecap: round;
      }

      .cls-3, .cls-4 {
        stroke: #547bfe;
      }

      .cls-3, .cls-4, .cls-6 {
        stroke-width: 3px;
      }

      .cls-4, .cls-5, .cls-6 {
        stroke-linejoin: round;
      }

      .cls-6, .cls-7 {
        stroke: #09bb9d;
      }

      .cls-7 {
        stroke-width: 2px;
      }

      .cls-8, .cls-9 {
        stroke: none;
      }

      .cls-9 {
        fill: #09bb9d;
      }
    </style> */}
  </defs>
  <g id="Group_56" data-name="Group 56" transform="translate(-764 -242)">
    <circle id="Ellipse_24" data-name="Ellipse 24" className="cls-1" fill="#f9f9f9" cx="37.5" cy="37.5" r="37.5" transform="translate(764 242)"/>
    <g id="Group_55" data-name="Group 55" transform="translate(1.333 9.231)">
      <path id="Path_18" data-name="Path 18" className="cls-2" fill="#90fee4" d="M-7169.676-12156.79l12.1-10.288,11.585,10.288Z" transform="translate(7945.44 12448.43)"/>
      <path id="Path_12" data-name="Path 12" className="cls-3" strokeWidth="3px" stroke="#547bfe" strokeLinecap="round" fill="none" d="M-7177.333-12202.231h36.93" transform="translate(7946 12451)"/>
      <path id="Path_16" data-name="Path 16" className="cls-3" strokeWidth="3px" stroke="#547bfe" strokeLinecap="round" fill="none" d="M-7177.333-12202.231h23.688" transform="translate(7953.097 12463.977)"/>
      <path id="Path_14" data-name="Path 14" className="cls-3" strokeWidth="3px" stroke="#547bfe" strokeLinecap="round" fill="none" d="M-7177.333-12202.231h36.93" transform="translate(7946 12493.871)"/>
      <path id="Path_13" data-name="Path 13" className="cls-3" strokeWidth="3px" stroke="#547bfe" strokeLinecap="round" fill="none" d="M-7174.254-12202.231s-2.447,11.4,10.489,18.047c6.643,3.671,0,7.692,0,7.692s-10.489,4.153-10.489,17.132" transform="translate(7945.789 12451)"/>
      <path id="Path_15" data-name="Path 15" className="cls-3" strokeWidth="3px" stroke="#547bfe" strokeLinecap="round" fill="none" d="M-7161-12202.231s2.447,11.4-10.489,18.047c-6.643,3.671,0,7.692,0,7.692s10.489,4.153,10.489,17.132" transform="translate(7964.327 12451)"/>
      <path id="Path_17" data-name="Path 17" className="cls-4" strokeWidth="3px" strokeLinejoin="round" stroke="#547bfe" strokeLinecap="round" fill="none" d="M-7170.279-12156.814l12.455-10.6,12.31,10.6" transform="translate(7945.484 12448.454)"/>
      <g id="Path_19" data-name="Path 19" className="cls-5" strokeLinejoin="round" strokeLinecap="round" fill="none" transform="translate(804.197 257.325)">
        <path className="cls-8" stroke="none" d="M13.9,0A13.9,13.9,0,1,1,0,13.9,13.9,13.9,0,0,1,13.9,0Z"/>
        <path className="cls-9" stroke="none" fill="#09bb9d" d="M 13.90329837799072 2.999998092651367 C 10.99091911315918 2.999998092651367 8.252859115600586 4.134138107299805 6.193498611450195 6.193498611450195 C 4.134138107299805 8.252859115600586 2.999998092651367 10.99091911315918 2.999998092651367 13.90329837799072 C 2.999998092651367 16.81567764282227 4.134138107299805 19.55373764038086 6.193498611450195 21.61309814453125 C 8.252859115600586 23.67245864868164 10.99091911315918 24.80659866333008 13.90329837799072 24.80659866333008 C 16.81567764282227 24.80659866333008 19.55373764038086 23.67245864868164 21.61309814453125 21.61309814453125 C 23.67245864868164 19.55373764038086 24.80659866333008 16.81567764282227 24.80659866333008 13.90329837799072 C 24.80659866333008 10.99091911315918 23.67245864868164 8.252859115600586 21.61309814453125 6.193498611450195 C 19.55373764038086 4.134138107299805 16.81567764282227 2.999998092651367 13.90329837799072 2.999998092651367 M 13.90329837799072 -1.9073486328125e-06 C 21.58187866210938 -1.9073486328125e-06 27.80659866333008 6.22471809387207 27.80659866333008 13.90329837799072 C 27.80659866333008 21.58187866210938 21.58187866210938 27.80659866333008 13.90329837799072 27.80659866333008 C 6.22471809387207 27.80659866333008 -1.9073486328125e-06 21.58187866210938 -1.9073486328125e-06 13.90329837799072 C -1.9073486328125e-06 6.22471809387207 6.22471809387207 -1.9073486328125e-06 13.90329837799072 -1.9073486328125e-06 Z"/>
      </g>
      <path id="Path_20" data-name="Path 20" className="cls-1" fill="#f9f9f9" d="M-7122.537-12197.765l-2.286,16.011,9.733-10.114Z" transform="translate(7942.161 12450.674)"/>
      <path id="Path_21" data-name="Path 21" className="cls-6" stroke="#09bb9d" strokeWidth="3px" strokeLinejoin="round" strokeLinecap="round" fill="none" d="M-7129.722-12175.537l5.793-3.827v-7.24" transform="translate(7942.519 12449.857)"/>
      <path id="Path_22" data-name="Path 22" className="cls-7" stroke="#09bb9d" strokeWidth="2px" strokeLinecap="round" fill="none" d="M-7115.437-12182.094c0-.93.856-7.88.856-7.88l7.212,1.364" transform="translate(7939.621 12450.104)"/>
    </g>
  </g>
    </Svg>
  );
};

export default Icon;
