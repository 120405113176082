import styled from 'styled-components'

export const ComponentWp = styled.div`
  width: 100%;
`

export const ContainerModal = styled.div`
  .title {
    color: #272727;
    font-family: 'OpenSans-Bold';
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    @media screen and (max-width: 540px){
      text-align: center;
    }
  }
  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .btn {
      width: 209px;
      height: 45px;
      border-radius: 30px;
      background-color: #547bfe;
      line-height: 45px;
      text-align: center;
      color: #fff;
      font-family: 'OpenSans-SemiBold';
      font-size: 14px;
      font-weight: 600;
      margin-top: 20px;
      @media screen and (max-width: 540px){
      width: 100%;
    }
    }
    .btn-outline {
      width: 209px;
      height: 45px;
      border-radius: 30px;
      background-color: #f5f5f5;
      line-height: 45px;
      text-align: center;
      color: #fff;
      font-family: 'OpenSans-SemiBold';
      font-size: 14px;
      font-weight: 600;
      margin-top: 20px;
      color: #547bfe;
      @media screen and (max-width: 540px){
      width: 100%;
    }
    }
  }
`
export const ModelActions = styled.div`
    width: 560px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      color: #272727;
      font-family: 'OpenSans-Bold';
      font-size: 24px;
      font-weight: bold;
    }
    .index {
      font-family: OpenSans-Regular;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #acacac;
    }
    .net-work {
      font-family: SegoeUI-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #525356;
      position: relative;
      &::before {
            content: '';
            width: 13px;
            height: 13px;
            background-color: #e8673c;
            position: absolute;
            left: -20px;
            top: 5px;
            border-radius: 50%;
      }
    }
  }
`
