import { useField, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ButtonFooter, ButtonStatus } from 'components/Modal/ModalFooter'

import { isReadMethod } from 'components/ModalSend/ContractInteraction/utils'

export interface ButtonProps {
  onClose: () => void
  requiresMethod?: boolean
}

const Buttons = ({ onClose, requiresMethod }: ButtonProps): React.ReactElement => {
  const { t } = useTranslation('common')

  const {
    input: { value: method },
  } = useField('selectedMethod', { subscription: { value: true } })
  const { modifiedSinceLastSubmit, submitError, submitting, valid, validating } = useFormState({
    subscription: {
      modifiedSinceLastSubmit: true,
      submitError: true,
      submitting: true,
      valid: true,
      validating: true,
    },
  })

  return (
    <ButtonFooter
      cancelButtonProps={{ onClick: onClose }}
      confirmButtonProps={{
        disabled:
          submitting ||
          validating ||
          ((!valid || !!submitError) && !modifiedSinceLastSubmit) ||
          (requiresMethod && !method),
        status: submitting || validating ? ButtonStatus.LOADING : ButtonStatus.READY,
        testId: `${isReadMethod(method) ? 'call' : 'review'}-tx-btn`,
        text: isReadMethod(method) ? t('Button.Call') : t('Button.Review'),
      }}
    />
  )
}

export default Buttons
