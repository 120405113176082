/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import styled from 'styled-components'
import { inputBorder } from 'theme/variables'
import TextField from '@mui/material/TextField'
import Text from 'components/Text/Text'
import { Input } from 'components/Input'
import { InputLabelProps } from './types'

const StyledInput = styled(Input)`
  border: solid 1px ${inputBorder};
  padding: 13px 37px;
  ::placeholder {
    color: #949494;
  }

  &.text-right {
    padding-right: 70px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`

const StyledTextArea = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const TextArea = styled(TextField)`
  border: 0;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  & .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    /* border-color: ${inputBorder}; */
  }
  & .MuiOutlinedInput-root {
    border-radius: 30px;
    padding: 14px 23px;
    & .MuiOutlinedInput-input {
      /* width */
      ::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #d4d4d4;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`

const Row = styled.div`
  /* margin-bottom: 10px; */
  width: 100%;
  position: relative;
`

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  font-family: OpenSans-SemiBold;
`

const StyledRight = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  position: absolute;
  right: 37px;
  top: 10px;
`

const InputLabel: React.FC<InputLabelProps> = ({
  label,
  onChange,
  type,
  valueInput,
  titleRight,
  number,
  error,
  disabled,
  placeholder,
  // onFocus,
  // onBlur,
  name
}) => {
  const handleChange = (event: any) => {
    const { value } = event.target
    let convertValue = value
    if (number) {
      convertValue = value.replace(/[^0-9.]/g, '')
    }
    // eslint-disable-next-line no-unused-expressions
    onChange?.(convertValue)
  }

  return (
    <Row>
      {label && (
        <StyledText fontSize="15px" bold>
          {label}
        </StyledText>
      )}
      {type === 'textarea' ? (
        <StyledTextArea>
          <TextArea
            id="outlined-multiline-static"
            placeholder="ABI*"
            multiline
            rows={4}
            // defaultValue=""
            onChange={handleChange}
            value={valueInput}
            error={error}
            helperText={error && 'no data'}
          />
        </StyledTextArea>
      ) : (
        <StyledInput
          // onFocus={onFocus}
          // onBlur={onBlur}
          className={titleRight ? 'text-right' : ''}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          value={valueInput}
          name={name}
        />
      )}
      {titleRight && <StyledRight>{titleRight}</StyledRight>}
    </Row>
  )
}

export default InputLabel
