/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { useUserData, useGetSafeInfo } from 'state/hooks'
import axios from 'axios'
import { GetSafeBalance } from 'apis'

const initialState: any = { fiatTotal: '0', listCoin: [] }

export const getBalanceSafeSlice = createSlice({
  name: 'getBalanceSafe',
  initialState,
  reducers: {
    setBalanceSafe: (state, action?: any) => {
      state.fiatTotal = action.payload.fiatTotal
      state.listCoin = action.payload.listCoin
    },
  },
})

// Actions
export const { setBalanceSafe } = getBalanceSafeSlice.actions

// Thunks
export const fetchBalanceSafe = (params) => async (dispatch?: any) => {
  try {
    const response: any = await GetSafeBalance(params)
    const result = response?.data
    if (result) {dispatch(setBalanceSafe({
      fiatTotal: result?.fiatTotal,
      listCoin: result?.items,
    }))}
    else {
      dispatch(setBalanceSafe(initialState))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`balance`, error)
  }
  // dispatch(setBalance(data))
}

export default getBalanceSafeSlice.reducer
