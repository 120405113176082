import React from "react";
import styled from "styled-components";
import Heading from "components/Heading/Heading";

import { ModalBody, 
  ModalHeader, 
  ModalTitle, 
  ModalContainer, 
  ModalCloseButton, 
  ModalBackButton, 
  NoteFooter
 } from "./styles";
import { ModalProps } from "./types";

const ModalHeading = styled(Heading)`
  font-size: 20px;
  font-family: OpenSans-Bold;
  line-height: 1.38;
  color: #272727;
  margin: 0;
  margin-right: 10px;
  @media screen and (min-width: 768px) {
    margin-right: 28px;
    font-size: 24px;
  }
`
const ModalStep = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  @media screen and (min-width: 768px) {
    font-size: 15px;
  }
`

// const ModalFooter = styled.div`
//   text-align: center;
//   border-top: solid 1px ${borderSecondary};
//   padding-top: 23px;
//   margin: 0 -42px;
// `


const Modal: React.FC<ModalProps> = ({
  title,
  step,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  headerBackground = "transparent",
  minWidth = "494px",
  maxWidth="630px",
  note,
  footer,
  placement,
  ...props
}) => (
  <>
  <ModalContainer placement={placement} minWidth={minWidth} maxWidth={maxWidth} {...props}>
    {
      title &&
      <ModalHeader background={headerBackground}>
        <ModalTitle>
          {onBack && <ModalBackButton onBack={onBack} />}
          <ModalHeading>{title}</ModalHeading>
          {step && <ModalStep>{step}</ModalStep>}
        </ModalTitle>
        {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
      </ModalHeader>
    }

    <ModalBody
    // p={bodyPadding}
    >{children}</ModalBody>
    {
      note && <NoteFooter>
        {note}
      </NoteFooter>
    }
    {
      footer
    }
  </ModalContainer>
  
  </>
);

export default Modal;
