import { useGetSafeInfo, useUserData } from 'state/hooks'

export const CheckOwner = () => {
  const { account } = useUserData()
  const { owners } = useGetSafeInfo()

  const dataCheck = owners.filter((v:any) => v?.address?.toLowerCase() === account?.toLowerCase()) ?? []
  
  return dataCheck.length > 0
}
