import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { depositContract } from 'utils/callHelpers'
import { useCaver } from 'utils/useCaver'
import { getContractSafe } from 'utils/contractHelpers'
import { useGetCurrency, useUserData } from 'state/hooks'
import { CreateTransaction } from 'apis'
import BigNumber from 'bignumber.js'
import { fetchBalanceSafe } from 'state/actions'

const useDeposit = (safeAddress?: any, chainId?: number) => {
  const dispatch = useAppDispatch()
  const { currency } = useGetCurrency()
  const cav: any = useCaver()
  const contractSafe = getContractSafe(safeAddress, cav)
  // const contractDeploy = new cav.klay.Contract(deployAbi as any)
  const { account } = useUserData()
  const handleDeposit = useCallback(
    async (amount) => {
      try {
        const tx = await depositContract(contractSafe, account, amount)
        if (tx?.transactionHash) {
          const dataCreateTransaction = {
            chainId,
            safeAddress,
            type: 'DEPOSIT',
            creatorAddress: account,
            hash: tx?.transactionHash,
            amount: Number(new BigNumber(amount).times(new BigNumber(10).pow(18))),
          }
          const params = {
            safeAddress,
            chainId,
            country: currency,
          }
          dispatch(fetchBalanceSafe(params)) // load balance token

          const create = await CreateTransaction(dataCreateTransaction)
          return {...tx,...create}
          // console.log('create', create)
        }

      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error handleDeposit', e)
      }
    },
    [account, dispatch, contractSafe],
  )

  return { onDeposit: handleDeposit }
}

export default useDeposit
