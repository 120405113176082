import Caver from "caver-js";
import getRpcUrl from 'utils/getRpcUrl';

// const RPC_URL = 'https://node-api.klaytnapi.com/v1/klaytn'
const RPC_URL = getRpcUrl()

const cavNoAccount = new Caver(new Caver.providers.HttpProvider(RPC_URL));

const useCaver = () => {
  const caverNoAccount = new Caver();
  const caver = window?.klaytn ? new Caver(window?.klaytn) : caverNoAccount
  return caver;
}

export { useCaver }

export default cavNoAccount
