import React from "react";
import { useModal } from "../Modal";
import ContractInteraction from "./ContractInteraction";

import SendFunds from "./SendFunds";
import SendCollectiblet from "./SendCollectiblet";
import Deposit from "./Deposit";

interface ReturnType {
  onPresentInteractionModal: () => void;
  onPresentSendFundsModal: () => void;
  onPresentSendCollectibletModal: () => void;
  onPresentDepositModal: () => void;
}

const useSendModal = (): ReturnType => {
  const [onPresentDepositModal] = useModal(<Deposit />);
  const [onPresentInteractionModal] = useModal(<ContractInteraction />);
  const [onPresentSendFundsModal] = useModal(<SendFunds />);
  const [onPresentSendCollectibletModal] = useModal(<SendCollectiblet />);
  
  return { onPresentInteractionModal,onPresentSendFundsModal,onPresentSendCollectibletModal,onPresentDepositModal };
};

export default useSendModal;
