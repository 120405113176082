/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  data: {
    step: 1,
  },
}

export const indicatorSlice = createSlice({
  name: 'CheckStepExisting',
  initialState,
  reducers: {
    setCheckStepExisting: (state, action?: any) => {
      state.data = action.payload
    },
  },
})

// Actions
export const { setCheckStepExisting } = indicatorSlice.actions

// Thunks
export const fetchCheckStepExisting = (data) => async (dispatch?: any) => {
  dispatch(setCheckStepExisting(data))
}

export default indicatorSlice.reducer
