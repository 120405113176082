import React, { useEffect,  useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAddress } from 'web3-utils'
import styled from 'styled-components'
import { inputBorder } from 'theme/variables'
import TextField from '@mui/material/TextField'
import CheckIcon from '@mui/icons-material/Check'
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabelProps } from './types'

const StyledInput = styled(TextField)`
  /* border: solid 1px ${inputBorder}; */
  /* padding: 13px 37px; */
  width: 100%;
  & .MuiInputBase-root {
    border-radius: 30px;
    & .MuiInputBase-input {
      padding: 13px 37px;
      height: auto;
      font-size: 14px;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: solid 1px #e8e8e8;
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  .MuiSvgIcon-root{
    border-radius: 50%;
    background-color: #547bfe;
    padding: 3px;
    path {
      fill: #fff;
    }
  }
`

const InputAddress: React.FC<InputLabelProps> = ({
  onChange,
  disabled,
  placeholder,
  helperText,
  error,
  name,
  defaultValue='',
  iconCheck,
  onFocus,
  onBlur
}) => {
  const { t } = useTranslation('common')
  const [valueAddress, setValueAAddress] = useState('')

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    defaultValue ? setValueAAddress(defaultValue) :  setValueAAddress('')
  }, [defaultValue])
  
  const handleChange = (event: any) => {
    const { value } = event.target
    const checkAddress = value ? isAddress(value) : true
    if(checkAddress){
    // eslint-disable-next-line no-unused-expressions
      onChange && onChange(value, event)
    } else{
    // eslint-disable-next-line no-unused-expressions
      onChange && onChange('',event)
    }
    // checkAddress && onChange ? onChange(value) :  onChange('')
    setValueAAddress(value)
  }

  const checkAddress = valueAddress ? isAddress(valueAddress) : true

  return (
    <StyledInput
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled}
      value={valueAddress}
      error={error || !checkAddress}
      helperText={helperText || (!checkAddress && t('Reuse.MustResolve'))}
      name={name}
      InputProps={{
        endAdornment: iconCheck && valueAddress && checkAddress && !error &&<StyledInputAdornment position="start"><CheckIcon /></StyledInputAdornment>,
      }}
    />
  )
}

export default InputAddress
