import React, { ReactNode, useState } from 'react' //lazy
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip, Button } from '@mui/material'
import { Layer } from 'components/Svg'

interface Props{
  address?: string
}

function ButtonCopy({address}: Props): React.ReactElement {
  const [copied, setCopied] = useState(false)
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  return (
    <CopyToClipboard
      text={address}
      onCopy={() => {
        setCopied(true)
        setTooltipIsOpen(true)
      }}
    >
      <Tooltip
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        placement="top"
        title={copied ? 'Copied' : 'Copy to cliboard'}
      >
        <Button
          onMouseOut={() => {
            setCopied(false)
            setTooltipIsOpen(false)
          }}
          className="box"
        >
          <Layer color="#547bfe" width={15} />
        </Button>
      </Tooltip>
    </CopyToClipboard>
  )
}

export default ButtonCopy
