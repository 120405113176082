import React from 'react'
import Sidebar from 'components/Sidebar'
import { Menu } from 'components/Menu'
import useAuth from 'hooks/useAuth'
import { useUserData } from 'state/hooks'

/** Store */

/** Style */
import { Content, Wrapper, ContentSideBar } from './style'


const MixLayout: React.FC = ({ children }) => {

  const { login, logout } = useAuth()
  const { account } = useUserData()

  const errorr = false

  return (
    <>
    <Menu account={account} login={login} logout={logout} errorr={errorr} />
    <Wrapper id='wrapperContent'>
      <ContentSideBar>

      <Sidebar />
      </ContentSideBar>
      {/* <Button onClick={toggleSidebar}>aaaaaaaaaaaaaaa</Button> */}
      <Content>{children}</Content>
    </Wrapper>
    </>
  )
}

export default MixLayout
