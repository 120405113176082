import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { deploy } from 'config/bytecode/deploy'

export const approveVault = async (lpContract, vault, account) => {
  return lpContract.methods.approve(vault.options.address, ethers.constants.MaxUint256).send({ from: account })
}

export const approve = async (lpContract, spenderAddress, account) => {
  return lpContract.methods.approve(spenderAddress, ethers.constants.MaxUint256).send({ from: account })
}

export const approveTicket = async (lpContract, spenderAddress, account) => {
  // console.log('lpContract', lpContract, spenderAddress, account)
  return lpContract.methods.setApprovalForAll(spenderAddress, true).send({ from: account, gas: 3000000 })
}

export const stake = async (contract, amount, account, packageId) => {
  const amounts = new BigNumber(amount).times(new BigNumber(10).pow(18))
  // console.log(`contract`, contract, amounts, account, packageId)
  // const gasLimit = 500000
  return contract.methods.deposit(amounts.toString(), packageId).send({ from: account })
  // .on('transactionHash', (tx) => {
  //   return tx.transactionHash
  // })
}

export const stakeTicket = async (contract, type, account, tokenId) => {
  const gas = 3000000
  // eslint-disable-next-line no-console
  return contract.methods.stake(type, tokenId).send({ from: account, gas })
  // .on('transactionHash', (tx) => {
  //   return tx.transactionHash
  // })
}

export const claimTicket = async (contract, type, account, tokenId) => {
  // console.log(`contract`, contract,type,account, tokenId)

  const gas = 3000000
  return contract.methods.claim(type, tokenId).send({ from: account, gas })
}

export const withdrawalTicket = async (contract, type, account, tokenId) => {
  // console.log(`contract`, contract,type,account, tokenId)
  const gas = 3000000
  return contract.methods.withdrawal(type, tokenId).send({ from: account, gas })
}

export const buyTicket = async (contract, amount, account, numberTicket, type) => {
  const amounts = new BigNumber(amount).times(new BigNumber(10).pow(18))
  const gas = 500000 * Number(numberTicket)
  // console.log('contract', contract, amount, account,numberTicket, type);
  return contract.methods.buyTicket(type, numberTicket).send({ from: account, value: amounts.toString(), gas })
  // .on('transactionHash', (tx) => {
  //   return tx.transactionHash
  // })
}

export const getClaim = async (contract, account) => {
  // console.log(`vaultContract`, vaultContract)
  return contract.methods
    .claim()
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

// eslint-disable-next-line no-shadow-restricted-names
export const deployContract = async (contract, account, _arguments ) => {
  return (
    contract
      .deploy({
        data: deploy,
        arguments: _arguments,
      })
      .send(
        {
          from: account,
          gas: 8000000,
          // value: 0,
        },
        // function (error, transactionHash, receipt) {
        //   console.log('transactionHash', transactionHash, receipt) // instance with the new contract address
        // },
      )
      // // eslint-disable-next-line func-names
      // .on('error', function (error) {
      //   // eslint-disable-next-line no-console
      //   console.log('error', error)
      // })
      // // eslint-disable-next-line func-names
      // .on('transactionHash', function (transactionHash) {
      //   // eslint-disable-next-line no-console
      //   console.log('transactionHash', transactionHash)
      // })
      // // eslint-disable-next-line func-names
      // .on('receipt', function (receipt) {
      //   // eslint-disable-next-line no-console
      //   console.log(receipt.contractAddress) // contains the new contract address
      // })
      // eslint-disable-next-line func-names
      .then(function (newContractInstance) {
        // eslint-disable-next-line no-console
        console.log(newContractInstance.options.address) // instance with the new contract address
      })
  )
}


export const depositContract = async (contract, account,amount) => {
  const amounts = new BigNumber(amount).times(new BigNumber(10).pow(18))
  const gas = 8000000 
  return contract.methods
    .deposit()
    .send({ from: account, value: amounts.toString(), gas}  )
    // .on('transactionHash', (tx) => {
    //   return tx.transactionHash
    // })
}

export const submitTransactionSelfContract = async (contract, account,_type ,_address,_token,amount) => {
  // const _value = new BigNumber(amount ?? 0).times(new BigNumber(10).pow(18))
  const gas = 1000000 
  return contract.methods
    .submitTransactionSelf(_type ,_address,_token, amount)
    .send({ from: account, gas})
 
}

export const submitTransactionSelfOneOwnerContract = async (contract, account,_type ,_address,_token,amount) => {
  // const _value = new BigNumber(amount ?? 0).times(new BigNumber(10).pow(18))
  const gas = 1000000 
  return contract.methods
    .submitTransactionSelfOneOwner(_type ,_address,_token, amount)
    .send({ from: account, gas})
 
}

export const confirmTransactionSelfContract = async (contract, account,_txIndex ) => {
  const gas = 1000000 
  return contract.methods
    .confirmTransactionSelf(_txIndex)
    .send({ from: account, gas})
 
}


export const executeTransactionSelfContract = async (contract, account,_txIndex,dataEncode ) => {
  const gas = 1000000 
  return contract.methods
    .executeTransactionSelf(_txIndex,dataEncode)
    .send({ from: account, gas})
 
}

export const submitTransaction = async (contract,account,_to, _value, _data) => {
  // console.log('firstdddddddddddd', contract,account,_to, _value, _data)
  const gas = 1000000 
  return contract.methods
    .submitTransaction(_to,_value,_data)
    .send({ from: account , gas})
 
}

export const submitTransactionOneOwner = async (contract,account,_to, _value, _data) => {
  const gas = 1000000 
  return contract.methods
    .submitTransactionOneOwner(_to,_value,_data)
    .send({ from: account , gas})
 
}

export const confirmTransaction = async (contract, account,_txIndex ) => {
  const gas = 1000000 
  return contract.methods
    .confirmTransaction(_txIndex)
    .send({ from: account, gas})
 
}

export const executeTransactionContract = async (contract, account,_txIndex,data ) => {
  const gas = 1000000 
  return contract.methods
    .executeTransaction(_txIndex,data)
    .send({ from: account, gas})
 
}