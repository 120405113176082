import { useCallback, } from 'react'
import {LINK_URL_DECENT} from 'config'

/** Store */
import {  fetchCreateUser, setDataUserCreate } from 'state/actions'
import { useAppDispatch } from 'state'
import { ConnectorNames } from "components/WalletModal";

const useAuth = () => {
  const dispatch = useAppDispatch()
  const logout = () => {
    // deactivate();
    dispatch(setDataUserCreate({ account: '' }))
  }
  const network = 'klaytn-mainnet'
  const deepLink = `https://link.dcentwallet.com/DAppBrowser/?url=${LINK_URL_DECENT}&network=${network}`


  // const { toastError } = useToast()
  const login = useCallback((connectorID: ConnectorNames) => {
    if (connectorID === 'Kaikas' || connectorID === 'DecentApp') {
      dispatch(fetchCreateUser())
    }
    if (connectorID === 'Decent') {
      // dispatch(fetchCreateUser())
      window.open(deepLink)
      dispatch(fetchCreateUser())
    }
  }, [])

  return { login, logout }
}

export default useAuth
