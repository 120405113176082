import styled from "styled-components";
// import Dialog from '@mui/material/Dialog';
import { colorTextSecondary, secondaryBackground, connected } from 'theme/variables'


export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  &.mb{
    margin-bottom: 25px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: OpenSans-SemiBold;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: ${colorTextSecondary};
  margin-bottom: 10px;
  .send-max{
    font-family: OpenSans-Bold;
    font-size: 15px;
    color: ${connected};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ImageToken = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 20px;
`;

export const TextToken = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: ${colorTextSecondary};
  /* margin-left: 20px; */
`;

export const NoteFooter = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  background-color: ${secondaryBackground};
`;