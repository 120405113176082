import { useCallback } from 'react'
import { executeTransactionContract } from 'utils/callHelpers'
import { useUserData } from 'state/hooks'
import { ExecuteTransaction } from 'apis'

const useExecuteTransaction = (
  contractSafe?: any,
  safeAddress?: any,
  chainId?: any,
  _txIndex?: any,
  idTransaction?: any,
  data?: any
) => {
  // const contractDeploy = new cav.klay.Contract(deployAbi as any)
  const { account } = useUserData()

  const handleExecuteTransaction = useCallback(async () => {
    try {
      const tx = await executeTransactionContract(contractSafe, account, _txIndex, data)

      if (tx?.transactionHash) {
        const dataTransaction = {
          chainId,
          safeAddress,
          ownerAddress: account,
          hash:tx?.transactionHash,
          idTransaction,
        }

        const result = await ExecuteTransaction(dataTransaction)
        return result
      }
      return tx
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error handleExecute', e)
      return false

    }
  }, [contractSafe, chainId, _txIndex, idTransaction,data])

  return { onExecuteTransaction: handleExecuteTransaction }
}

export default useExecuteTransaction
