import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connected, inputBorder } from 'theme/variables'
import { Button } from 'components/Button'

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 7px);
  /* padding-bottom: 10px; */
  background-color: #fff;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  /* padding: 0px 12px; */
  /* @media (max-width: 1024px) {
    display: none;
  } */
  @media (max-width: 768px) {
    height: auto;
    border-bottom: 1px solid ${inputBorder};
    margin-top: 0px;
    min-height: auto;
    &.not_connect{
      border-top: 1px solid ${inputBorder};
    }
  }
`

export const EthereumName = styled.div`
  width: 100%;
  min-height: 32px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    border-top: 1px solid ${inputBorder};
    border-bottom: 1px solid ${inputBorder};
    justify-content: space-between;
    background-color: #fff;
    min-height: 84px;
    padding: 0 15px;
  }
`

export const EthereumSpan = styled.div`
  font-family: OpenSans-Regular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #525356;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const ChangeSafe = styled.div`
  /* padding: 0 12px; */
  display: flex;
  justify-content: space-between;
  width: auto;
  /* height: 42px; */
  /* border-radius: 30px; */
  /* background-color: #f5f5f5; */
  /* padding: 9px 22px; */
  cursor: pointer;
  /* margin-top: 40px; */
  /* & .lock{
    color: #547bfe;
  } */
  .left {
    margin-top: 7px;
    visibility: hidden;
  }
  .right {
    @media (max-width: 768px) {
      visibility: hidden;
    }
  }
  .avatar {
    width: 42px;
    height: 42px;
  }
  .show {
    margin-top: 7px;
  }
  @media (min-width: 768px) {
    width: 100%;
    /* margin-top: 20px; */
  }
`

export const ChangeSafeNotConnect = styled.div`
  margin: 0 30px;
  margin-top: 20px;
  /* width:131px; */
  height: 37px;
  border-radius: 30px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  @media (max-width: 768px) {
    justify-content: space-between;
    margin: 25px 15px;
    background-color: transparent;
    .icon_clock {
      background-color: #f5f5f5;
      padding: 6px 25px 11px 21px;
      width: 66px;
      height: 37px;
      border-radius: 30px;
    }
  }
`

export const Wallet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 40px; */
  width: auto;
  height: 20px;
  @media (min-width: 768px) {
    width: 100%;
    margin: 12px;
    /* margin-top: 12px; */
  }
`
export const WalletKey = styled.span`
  font-family: OpenSans-Bold;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #525356;
  font-weight: bold;
`
export const WalletContent = styled.span`
  font-family: OpenSans-Regular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #525356;
  margin-left: 3px;
`
export const ListIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 16px;
  /* margin-top: 40px; */
  @media (min-width: 768px) {
    width: 100%;
    text-align: center;
    /* margin-top: 12px; */
  }
`
export const ImgTitleItem = styled.img`
  margin: 0 7.5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const ReadOnly = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  height: 32px;
  margin: 15px 41px 0px 41px;
  background-color: #e8e8e8;
  border-radius: 16px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 86px;
    height: 21px;
    margin: 5px 0 0px 0px;
  }
`

export const ReadOnlyText = styled.span`
  font-family: OpenSans-Regular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  @media (max-width: 768px) {
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    text-align: center;
  }
`

export const Name = styled.div`
  width: 100%;
  margin-top: 12px;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #09bb9d;
  @media screen and (max-width: 767px) {
    margin-top: 0;
    text-align: left;
  }
`

export const Money = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-top: 12px;
  @media (max-width: 767px) {
    justify-content: start;
    margin-left: 60px;
  }
`

export const MoneyContent = styled.span`
  font-family: OpenSans-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #525356;
`
export const Transaction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  height: 45px;
  margin: 13px 0 0px;
  background-color: #547bfe;
  border-radius: 22.5px;
  cursor: pointer;
  @media (min-width: 767px) {
    margin-top: 10px;
  }
`
export const TransactionText = styled.span`
  font-family: OpenSans-Bold;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`
export const LineDiv = styled.div`
  height: 1px;
  background-color: #e8e8e8;
`

export const ListItem = styled.div`
  margin-top: 12px;
`

export const LinkItem = styled(Link)`
  /* margin: 12.5px 0px; */
  height: 19px;
  text-decoration: none;
  &.hide-asset {
    display: none;
  }
  &.hide-setting {
    display: none;
  }
`
export const Item = styled.div`
  border-left: solid 37px #fff;
  height: 8px;
  margin: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #525356;
  &.activeAsset,
  &:hover {
    color: #547bfe;
    border-left: solid 37px #547bfe;
  }
  &.mini {
    &.activeAsset,
    &:hover {
      color: #547bfe;
      border-left: solid 8px #547bfe;
      margin-left: 29px;
    }
  }
`

export const ItemName = styled.div`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &.mini {
    font-family: OpenSans-Bold;
    font-weight: bold;
  }
`
export const ItemIcon = styled.div`
  display: flex;
`

export const ItemIconUp = styled.div`
  margin: 5px 26px 0px 0px;
  display: none;
  &.active {
    display: block;
  }
`
export const ItemIconDown = styled.div`
  margin: 5px 26px 0px 0px;
  display: none;
  &.active {
    display: block;
  }
`

export const NameContentSidebar = styled.div`
  display: block;
  justify-content: center;
  .hideIMG {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .hideIMGMobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: start;
  }
`

export const ContentSafe = styled.div`
  width: 100%;
  padding: 20px 15px;
  text-align: center;
  /* @media (max-width: 768px)  {
    display: flex;
    justify-content: start;
  } */
`

export const InfoSafe = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const ContentUser1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  @media (min-width: 768px) {
    width: 100%;
    align-items: center;
  }
`

export const ButtonRead = styled(Button)`
  height: 21px;
  background-color: ${connected};
  margin-top: 2px;
  padding: 0;
  width: 84px;
  font-size: 12px;
  @media (min-width: 768px) {
    width: 110px;
    font-size: 15px;
    margin: 15px 0;
    height: 32px;
    text-transform: uppercase;
  }
`

export const WrapperAvatar = styled.div`
  position: relative;
`

export const NumberOwner = styled.div`
    background: rgb(237 244 251);
    color: #547bfe;
    font-size: 12px;
    font-weight: bold;
    border-radius: 100%;
    padding: 0.25em;
    position: absolute;
    z-index: 2;
    top: -10px;
    left: -10px;
    min-width: 1.5em;
    min-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-family: OpenSans-Bold;
`