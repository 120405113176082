import { createStyles, makeStyles } from '@mui/styles'
import { ReactElement } from 'react'

import Field from 'components/forms/Field'
import TextField from 'components/forms/TextField'

const styles = createStyles({
  textarea: {
    '& > div': {
      height: '140px',
      // paddingTop: '0',
      // paddingBottom: '0',
      padding: '0',
      alignItems: 'auto',
      '& > textarea': {
        fontSize: '15px',
        letterSpacing: '-0.5px',
        lineHeight: '20px',
        height: '102px',
        '&::-webkit-scrollbar ': {
          width: '7px',
        },
    
        /* Track */
        '&::-webkit-scrollbar-track': {
          // background: '#f1f1f1',
        },
    
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
          background: '#d4d4d4',
        },
    
        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      },
    },
  },
})

const useStyles = makeStyles(styles)

export const TextAreaField = ({ ...props }): ReactElement => {
  const classes = useStyles()
  return <Field {...props} className={classes.textarea} component={TextField} multiline rows="5" />
}
