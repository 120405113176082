import React from 'react'
import Overlay from 'components/Overlay/Overlay'
import {
  useGetIndicatorData,
} from 'state/hooks'
import { Indicator } from './style'

interface Props {
  colorIndi?: string
  colorBG?: string
  show?: boolean
}

const LoadingNew: React.FC<Props> = ({ colorBG, colorIndi, show }) => {
  // console.log('dataIndicator ==>', isShow)
  const {isShow} = useGetIndicatorData()
  return (
    <Overlay show={show || isShow} colorBG={colorBG}>
      <Indicator colorBD={colorIndi} />
    </Overlay>
  )
}

export default LoadingNew
