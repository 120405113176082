import { useState, ReactElement, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { NameNetwork } from 'utils/nameNetwork'
import AddIcon from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'
import { useGetListOwnerSmart } from 'state/hooks'
import InfoAddress from 'components/InfoAddress'
import { generateSafeRoute, WELCOME_ROUTE, ASSETS, COINS, SAFE_ROUTES } from 'routes/routes'
import { Wrapper, Container, AddSafe, IconSafe, Network, Create, StyleLink } from './style'

export const SafeListSidebarContext = createContext({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSidebar: () => {},
})

type Props = {
  children: ReactElement
}

const hashRegExp = '0x[0-9A-Fa-f]'
export const ADDRESSED_ROUTE = `${ASSETS}/:${hashRegExp}/${COINS}`

export const SafeListSidebar = ({ children }: Props): ReactElement => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const dataListOwner = useGetListOwnerSmart()
  const nameNetwork = NameNetwork()

  // const { trackEvent } = useAnalytics()

  const toggleSidebar = () => {
    // if (!isOpen) {
    //   // trackEvent(SAFE_EVENTS.SIDEBAR)
    // }
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }



  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsOpen(!isOpen)
  }

  return (
    <>
      <SafeListSidebarContext.Provider value={{ isOpen, toggleSidebar }}>
        <SwipeableDrawer
          // ModalProps={{ onClose: toggleSidebar }}
          // onKeyDown={handleEsc}
          open={isOpen}
          // anchor={anchor}
          // open={state[anchor]}
          // onClose={toggleDrawer(anchor, false)}
          // anchor={anchor}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          {/* {list(anchor)} */}
          <Wrapper>
            <Container>
              <AddSafe
                onClick={() => {
                  toggleSidebar()
                  history.push(WELCOME_ROUTE)
                }}
              >
                <IconSafe>
                  <AddIcon />
                </IconSafe>
                <span>{t('Button.AddSafe')}</span>
              </AddSafe>
            </Container>
            <Network>{nameNetwork}</Network>
            <div>
              {dataListOwner?.length > 0 ? (
                dataListOwner.map((item, index) => (
                  <StyleLink key={index} to={generateSafeRoute(SAFE_ROUTES.ASSETS_BALANCES, { safeAddress: item?.address })}>
                    <InfoAddress
                      key={index}
                      address={item?.address}
                      numberOwner={`${item.threshold}/${item?.owners?.length}`}
                      onClick={() => {
                        // history.push(generateSafeRoute(ASSETS_COINS, { safeAddress: item?.address }))
                        toggleSidebar()
                      }}
                      type="no-icon"
                      width={34}
                      name={item?.name}
                    />
                  </StyleLink>
                ))
              ) : (
                <Create>
                  <span>{t('Reuse.CreateOrAdd')}</span> {t('Reuse.CreateOrAdd2')}
                </Create>
              )}
            </div>
          </Wrapper>
        </SwipeableDrawer>
        {children}
      </SafeListSidebarContext.Provider>
    </>
  )
}
