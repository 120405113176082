import React from "react";
import styled from "styled-components";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { CheckmarkIcon } from "components/Svg";
import { border } from 'theme/variables'
import { connectorLocalStorageKey } from "./config";
import { Login, Config } from "./types";

interface Props {
  id: any;
  walletConfig: Config;
  login?: Login;
  onDismiss?: () => void;
  setWalletSelect: any;
  walletActive: any;
  setWalletActive: any;
  mb: string;
}

const ButtonWallet = styled(Button)`
  height: 62px;
  &:hover {
    border-color: #0cd3ff !important;
  };
  @media screen and (min-width: 320px) and (max-width: 720px) {
    height: 50px;
  }
`;

const ActiveCheck = styled.div`
  width: 38px;
  height: 38px;
  background-image: linear-gradient(to top, #3affff -38%, #0096ea 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .hideCheck {
    display: none;
  }
`;

const DisableCheck = styled.div`
  width: 38px;
  height: 38px;
`;

const WalletCard: React.FC<Props> = ({
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login,
  walletConfig,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDismiss,
  setWalletSelect,
  walletActive,
  setWalletActive,
  mb,
}) => {
  const { title, icon: Icon } = walletConfig;
  const width = window.innerWidth;

  return (
    <ButtonWallet
      width="100%"
      variant="tertiary"
      onClick={() => {
        // login(walletConfig.connectorId);
        setWalletSelect(walletConfig.connectorId);
        window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        // onDismiss();
        setWalletActive(id);
      }}
      style={{
        justifyContent: "space-between",
        // border: `${active ? "solid 3px #0cd3ff" : "solid 3px #444444"}`,
        border: `solid 1px ${border}`,
        backgroundColor: "transparent",
        fontSize: "16px",
        padding: width <= 375 ? "0px 15.2px 0px 20px" : "0px 47px",
      }}
      mb={mb}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      {/* <Icon width="38px" /> */}
      <div 
        style={{
          // width: '130px', 
          height: '38px',
          maxHeight: '100%',
      }}
      >
      <img src={Icon} alt="icon"  style={{maxHeight: '100%'}} />
      </div>
      <Text bold color="#525356">
        {title}
      </Text>
      {walletActive === id ? (
        <ActiveCheck>
          <CheckmarkIcon style={{ fontWeight: "bold" }} />
        </ActiveCheck>
      ) : (
        <DisableCheck />
      )}
    </ButtonWallet>
  );
};

export default React.memo(WalletCard);
