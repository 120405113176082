import { generatePath, matchPath } from 'react-router-dom'
import { checksumAddress } from 'utils/checksumAddress'
import history from 'routerHistory'

// import { getChains } from 'src/config/cache/chains'
// import { ChainId, ShortName } from 'src/config/chain.d'
// import { checksumAddress } from 'utils/checksumAddress'
// import { PUBLIC_URL } from 'utils/constants'
// import { parsePrefixedAddress } from 'utils/prefixedAddress'
// export const PUBLIC_URL = process.env.PUBLIC_URL

export const ADDRESS_SMART = ''

// Routes independant of safe/network
export const ROOT_ROUTE = '/'
export const WELCOME_ROUTE = '/welcome'
export const OPEN_SAFE_ROUTE = '/open'
export const CREATION_PROCESS = '/creation-process'
export const CREATE_NEW_SAFE = '/create-new-safe'
export const EXISTING_SAFE = '/existing-safe'
export const ASSETS = `assets`
export const COINS = `coins`
export const TRANSACTIONS = '/transactions'
export const ADDRESS_BOOK = '/address-book'

// export const history = createBrowserHistory({
//   basename: PUBLIC_URL,
// })

// Safe specific routes
const hashRegExp = '0x[0-9A-Fa-f]'
const hashId = '[0-9A-Fa-f]'

const chainSpecificSafeAddressPathRegExp = `${hashRegExp}{40}`

export const SAFE_ADDRESS_SLUG = 'prefixedSafeAddress'
export const ADDRESSED_ROUTE = `/:${SAFE_ADDRESS_SLUG}(${chainSpecificSafeAddressPathRegExp})`
// export const ADDRESSED_ROUTE = `/:${SAFE_ADDRESS_SLUG}`

export const ASSETS_COINS = `/assets/:${SAFE_ADDRESS_SLUG}/coins`
export const ASSETS_COLLECTIBLES = `/assets/:${SAFE_ADDRESS_SLUG}/collectibles`

// Safe section routes, i.e. /:prefixedSafeAddress/settings
const SAFE_SECTION_SLUG = 'safeSection'
export const SAFE_SECTION_ROUTE = `${ADDRESSED_ROUTE}/:${SAFE_SECTION_SLUG}`

// Safe subsection routes, i.e. /:prefixedSafeAddress/settings/advanced
export const SAFE_SUBSECTION_SLUG = 'safeSubsection'
export const SAFE_SUBSECTION_ROUTE = `${SAFE_SECTION_ROUTE}/:${SAFE_SUBSECTION_SLUG}`

export const TRANSACTION_ID_SLUG = `safeTxHash`

// URL: gnosis-safe.io/app/:[SAFE_ADDRESS_SLUG]/:[SAFE_SECTION_SLUG]/:[SAFE_SUBSECTION_SLUG]
export type SafeRouteSlugs = {
  [SAFE_ADDRESS_SLUG]?: string
  [SAFE_SECTION_SLUG]?: string
  [SAFE_SUBSECTION_SLUG]?: string
  [TRANSACTION_ID_SLUG]?: string
}

export const LOAD_SPECIFIC_SAFE_ROUTE = `/load/:${SAFE_ADDRESS_SLUG}?` // ? = optional slug

export const LOAD_SAFE_ROUTE = generatePath(LOAD_SPECIFIC_SAFE_ROUTE) // By providing no slug, we get '/load'

// [SAFE_SECTION_SLUG], [SAFE_SUBSECTION_SLUG] populated safe routes
export const SAFE_ROUTES = {
  ASSETS_BALANCES: `${ADDRESSED_ROUTE}/balances`, // [SAFE_SECTION_SLUG] === 'balances'
  ASSETS_BALANCES_COLLECTIBLES: `${ADDRESSED_ROUTE}/balances/collectibles`, // [SAFE_SUBSECTION_SLUG] === 'collectibles'
  TRANSACTIONS: `${ADDRESSED_ROUTE}/transactions`,
  TOKENMINTER: `${ADDRESSED_ROUTE}/token-minter`,
  TOKENLOCK: `${ADDRESSED_ROUTE}/token-lock`,
  PROTOCOLS: `${ADDRESSED_ROUTE}/protocols`,
  PROTOCOLS_SWAP: `${ADDRESSED_ROUTE}/protocols-swap`,
  TRANSACTIONS_HISTORY: `${ADDRESSED_ROUTE}/transactions/history`,
  TRANSACTIONS_REJECT: `${ADDRESSED_ROUTE}/transactions/reject`,
  TRANSACTIONS_CONFIRM: `${ADDRESSED_ROUTE}/transactions/confirm`,
  TRANSACTIONS_SINGULAR: `${ADDRESSED_ROUTE}/transactions-detail/:${TRANSACTION_ID_SLUG}(${hashId}+)`, // [TRANSACTION_HASH_SLUG] === 'safeTxHash'
  ADDRESS_BOOK: `${ADDRESSED_ROUTE}/address-book`,
  APPS: `${ADDRESSED_ROUTE}/apps`,
  SETTINGS: `${ADDRESSED_ROUTE}/settings`,
  SETTINGS_APPEARANCE: `${ADDRESSED_ROUTE}/settings/appearance`,
  SETTINGS_DETAILS: `${ADDRESSED_ROUTE}/settings/details`,
  SETTINGS_OWNERS: `${ADDRESSED_ROUTE}/settings/owners`,
  SETTINGS_POLICIES: `${ADDRESSED_ROUTE}/settings/policies`,
  SETTINGS_SPENDING_LIMIT: `${ADDRESSED_ROUTE}/settings/spending-limit`,
  SETTINGS_ADVANCED: `${ADDRESSED_ROUTE}/settings/advanced`,
}

export type SafeRouteParams = { safeAddress: string }


export const extractPrefixedSafeAddress = (path = history?.location?.pathname, route = ADDRESSED_ROUTE): SafeRouteParams => {
  const match = route && matchPath<SafeRouteSlugs>(path, {
    path: route,
  })

  const prefixedSafeAddress = match?.params?.[SAFE_ADDRESS_SLUG] || ''
  // const { prefix, address } = parsePrefixedAddress(prefixedSafeAddress || '')
  const address = prefixedSafeAddress

  return {
    // shortName: prefix,
    safeAddress: checksumAddress(address) || '',
  }
}
export const extractSafeAddress = (): string => extractPrefixedSafeAddress().safeAddress

// export const getPrefixedSafeAddressSlug = ({ safeAddress }): string => `${safeAddress}`
export const getPrefixedSafeAddressSlug = ({ safeAddress }: any) => {
  // console.log('====================================');
  // console.log("safeAddress ==>",safeAddress);
  // console.log('====================================');
  return `${safeAddress}`
}

// Populate `/:[SAFE_ADDRESS_SLUG]` with current 'shortName:safeAddress'
export const generateSafeRoute = (path: typeof SAFE_ROUTES[keyof typeof SAFE_ROUTES], params: any): string => {
  const route =  getPrefixedSafeAddressSlug(params) ? generatePath(path, {
    [SAFE_ADDRESS_SLUG]: getPrefixedSafeAddressSlug(params),
  }): ''
  return route
}

// Singular tx route is excluded as it has a required safeTxHash slug
// This is to give stricter routing, instead of making the slug optional
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { TRANSACTIONS_SINGULAR: _hasRequiredSlug, ...STANDARD_SAFE_ROUTES } = SAFE_ROUTES
export const generatePrefixedAddressRoutes = (params: SafeRouteParams): typeof STANDARD_SAFE_ROUTES => {
  return Object.entries(STANDARD_SAFE_ROUTES).reduce<typeof STANDARD_SAFE_ROUTES>(
    (routes, [key, route]) => ({ ...routes, [key]: generateSafeRoute(route, params) }),
    {} as typeof STANDARD_SAFE_ROUTES,
  )
}
