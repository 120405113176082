import tokens from 'config/constants/tokens'

export const getAddress = (address): string => {
  const mainNetChainId = 8217
  const chainId = process.env.REACT_APP_CHAIN_ID // process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}

// export const getBeyondAddress = () => {
//   return getAddress(tokens.beyondfi.address)
// }

// export const getSmartContractAddress = () => {
//   return getAddress(tokens.beyondfi.vault)
// }

export const getProxyAdminAddress = () => {
  return getAddress(tokens.ProxyAdmin.address)
}
export const getMultiSigWalletAddress = () => {
  return getAddress(tokens.MultiSigWallet.address)
}

export const getSmartContractAddress = () => {
  return getAddress(tokens.klayTicketStaking.address)
}