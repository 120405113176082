/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  data: {
    step: 1,
  },
}

export const indicatorSlice = createSlice({
  name: 'CheckStep',
  initialState,
  reducers: {
    setCheckStep: (state, action?: any) => {
      state.data = action.payload
    },
  },
})

// Actions
export const { setCheckStep } = indicatorSlice.actions

// Thunks
export const fetchCheckStep = (data) => async (dispatch?: any) => {
  dispatch(setCheckStep(data))
}

export default indicatorSlice.reducer
