import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved

// import { GmarketSansTTFBold, GmarketSansTTFLight, GmarketSansTTFMedium } from 'assets/font/font'

// declare module 'styled-components' {
//   /* eslint-disable @typescript-eslint/no-empty-interface */
//   export interface DefaultTheme {}
// }

import Fonts from 'assets/font/font'

const GlobalStyle = createGlobalStyle`
 @font-face {
  font-family: 'OpenSans-Bold';
  src: url(${Fonts.OpenSansBold}) format('woff');
}
 @font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url(${Fonts.OpenSansExtraBold}) format('woff');
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url(${Fonts.OpenSansRegular}) format('woff');
}
@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url(${Fonts.OpenSansSemiBold}) format('woff');
}
@font-face {
  font-family: 'OpenSans-Italic';
  src: url(${Fonts.OpenSansItalic}) format('woff');
}
@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url(${Fonts.OpenSansBoldItalic}) format('woff');
}
@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  src: url(${Fonts.OpenSansExtraBoldItalic}) format('woff');
}
@font-face {
  font-family: 'SegoeUI-Regular';
  src: url(${Fonts.SegoeUIRegular}) format('woff');
}
@font-face {
  font-family: 'SegoeUI-Bold';
  src: url(${Fonts.SegoeUIBold}) format('woff');
}
@font-face {
  font-family: 'SegoeUI-Italic';
  src: url(${Fonts.SegoeUIItalic}) format('woff');
}

  body {
    background-color: #fff;
    color: #525356;
    flex-grow: 0;
    margin: 0px;
    font-family: 'OpenSans-Regular';
    /* background-color: #f5f9fc; */
    /* overflow: auto !important;
    padding-right: 0 !important; */
    &::-webkit-scrollbar {
      width: 0px;
    };
    img {
      height: auto;
      max-width: 100%;
    }
  }

  *{
    box-sizing: border-box;
  }

  //message
  .SnackbarItem-wrappedRoot {
    top: 0;
    right: -20px;
    left: unset;
    bottom: unset;
    .SnackbarContent-root {
      background-color: #fff;
      min-width: 400px;
      padding: 11px 20px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
      border-radius: 30px 0 0 30px;color: #525356;font-size: 16px;font-family: "OpenSans-Regular";
      .SnackbarItem-message {
        gap: 12px;
        .message-info path{
          fill: #b8b8b8;
        }
        .message-success path{
          fill: #547bfe;
        }
      }
      .SnackbarItem-action {
        .MuiButtonBase-root{
          min-width: unset;
          padding: 0;
        svg path{
          fill: #bebebf;
        }
      }
      }
    }
  }

`

export default GlobalStyle
