import MuiTextField from '@mui/material/TextField'
import { createStyles, makeStyles } from '@mui/styles'


// Neded for solving a fix in Windows browsers
const overflowStyle = {
  overflow: 'hidden',
  width: '100%',
  '& .MuiFormHelperText-root': {
    top: 2
  }
}

const styles = () =>
  createStyles({
    root: {
      lineHeight: 0,
      '& .MuiOutlinedInput-input': {
        padding: '12px 30px',
        fontFamily: "OpenSans-Regular",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.36,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#272727"
      }
    },
    helperText: {
      top:0,
      fontFamily: "OpenSans-Regular",
      fontSize: "11px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      marginLeft: 21,
    },
    defaultInput: {
      borderRadius: 30,
      // marginTop: 8,
      height: 45,
      '& .MuiOutlinedInput-input': {
        padding: '12px 35px',
        // padding: '12px 0 0 35px',
      },
      '& textarea': {
        '&.MuiOutlinedInput-input': {
          // paddingTop: '0',
        },
      },
      '&.isInvalid': {
        '& .MuiOutlinedInput-input': {
          padding: '0 35px 0 ',
        },
        '& textarea': {
          '&.MuiOutlinedInput-input': {
            paddingTop: '0',
          },
        },
      },
      '&.Mui-focused': {
        boxShadow: "none", outline: "none", border: "solid 1px transparent"
      },
      '& .MuiInputAdornment-root':{
        '& .MuiSvgIcon-root ':{
          color: '#547bfe'
        }
      }
    }
  })

const useStyles = makeStyles(styles)

type Props = {
  input: {
    name: string
    onChange?: () => void
    value?: string
    placeholder: string
    type: string
  }
  meta: {
    touched?: boolean
    pristine?: boolean
    valid?: boolean
    error?: string
    modifiedSinceLastSubmit?: boolean
    submitError?: boolean
    active?: boolean
  }
  inputAdornment?: { endAdornment: React.ReactElement } | undefined
  multiline: boolean
  rows?: string
  testId: string
  text: string
  disabled?: boolean
  rowsMax?: number
  className?: string
}

const TextField = (props: Props): React.ReactElement => {
  const {
    input: { name, onChange, value, ...restInput },
    inputAdornment,
    meta,
    multiline,
    rows,
    testId,
    text,
    ...rest
  } = props
  const classes = useStyles()
  const helperText = value ? text : undefined
  const showError = (meta.touched || !meta.pristine) && !meta.valid
  const hasError = !!meta.error || (!meta.modifiedSinceLastSubmit && !!meta.submitError)
  const errorMessage = meta.error || meta.submitError
  const isInactiveAndPristineOrUntouched = !meta.active && (meta.pristine || !meta.touched)
  const isInvalidAndUntouched = typeof meta.error === 'undefined' ? true : !meta.touched

  const disableUnderline = isInactiveAndPristineOrUntouched && isInvalidAndUntouched

  const inputRoot = helperText ? classes.root : ''
  const statusClasses = meta.valid ? 'isValid' : hasError && showError ? 'isInvalid' : ''
  const inputProps = {
    ...restInput,
    autoComplete: 'off',
    'data-testid': testId,
  }
  const inputRootProps = {
    ...inputAdornment,
    className: `${inputRoot} ${statusClasses} ${classes.defaultInput}`,
    disableUnderline: disableUnderline,
  }

  return (
    <MuiTextField
      error={hasError && showError}
      helperText={hasError && showError ? errorMessage : helperText || ' '}
      inputProps={inputProps} // blank in order to force to have helper text
      InputProps={inputRootProps}
      multiline={multiline}
      name={name}
      onChange={onChange}
      rows={rows}
      style={overflowStyle}
      value={value}
      variant="outlined"
      FormHelperTextProps={{
        classes: {
          root: classes.helperText
        }
      }}
      {...rest}
    />
  )
}

export default TextField
