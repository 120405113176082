import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="40.293" height="54.998" viewBox="0 0 40.293 54.998" {...props}>
      <g id="lock-correct-svgrepo-com" transform="translate(-73.773 -2.156)">
        <path
          id="Path_27"
          data-name="Path 27"
          d="M112.917,40.957a1.5,1.5,0,0,0-1.888.977,17.535,17.535,0,1,1,.094-10.334A1.5,1.5,0,1,0,114,30.742a20.7,20.7,0,0,0-6.167-9.586V16.073a13.925,13.925,0,0,0-18.886-13,1.5,1.5,0,1,0,1.074,2.807,10.919,10.919,0,0,1,14.807,10.2v2.895a20.522,20.522,0,0,0-21.823.506v-3.4A10.855,10.855,0,0,1,84.857,10a1.5,1.5,0,0,0-2.495-1.677A13.85,13.85,0,0,0,80,16.073V21.9a20.537,20.537,0,1,0,33.891,20.949A1.5,1.5,0,0,0,112.917,40.957Z"
          transform="translate(0)"
          fill="#547bfe"
        />
        <circle
          id="Ellipse_34"
          data-name="Ellipse 34"
          cx="12.052"
          cy="12.052"
          r="12.052"
          transform="translate(81.869 24.562)"
          fill="#90fee4"
        />
        <g id="Group_104" data-name="Group 104" transform="translate(80.365 23.059)">
          <path
            id="Path_28"
            data-name="Path 28"
            d="M146.947,230.705A13.555,13.555,0,1,1,160.5,217.15,13.57,13.57,0,0,1,146.947,230.705Zm0-24.1A10.549,10.549,0,1,0,157.5,217.15,10.561,10.561,0,0,0,146.947,206.6Z"
            transform="translate(-133.392 -203.595)"
            fill="#547bfe"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M211.993,291.248a1.5,1.5,0,0,1-.99-.372l-3.967-3.473a1.5,1.5,0,1,1,1.98-2.262l2.8,2.448,3.876-4.755a1.5,1.5,0,1,1,2.33,1.9l-4.859,5.962a1.5,1.5,0,0,1-1.039.548C212.077,291.247,212.035,291.248,211.993,291.248Z"
            transform="translate(-198.438 -273.582)"
            fill="#547bfe"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
