import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import CloseIcon from '@mui/icons-material/Close'
import * as S from './style'

export interface ModalProps {
  visible: boolean
  // onOk?: () => void;
  onCancel?: () => void
  // openModalSuccess?: () => void
  children?: ReactNode
}

const ModalMain = ({ visible, 
  // openModalSuccess, 
  onCancel, children }: ModalProps) => {
  return visible
    ? ReactDOM.createPortal(
        <>
          <S.ModalOverlay />
          <S.ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <S.ModalClose onClick={() => onCancel?.()} />
            <S.Modal>
              {onCancel && (
                <S.ModalCloseButton data-dismiss="modal" aria-label="Close" onClick={() => onCancel?.()}>
                  <CloseIcon />
                </S.ModalCloseButton>
              )}
              {children}
            </S.Modal>
          </S.ModalWrapper>
        </>,
        document.body,
      )
    : null
}

export default ModalMain
