// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mainAxios } from 'libs/axios'

interface dataCreate {
  chainId?:any;
  safeAddress?:any;
  type?: string;
  hash?: any;
  creatorAddress?: any;
  amount?: any;
  contractData?: any
}

interface dataHistory{
  chainId?:any;
  safeAddress?:string;
  status?: string;
  ownerAddress?: string;
}

interface dataDetailHistory{
  chainId?:any;
  safeAddress?:string;
  idTransaction?: any;
  ownerAddress?: string;
}

export const CreateTransaction = async (data?: dataCreate) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/transaction/create', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}

export const CreateTransactionOneOwner = async (data?: dataCreate) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/transaction/submit-one-owner', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}

export const ConfirmTransaction = async (data?: dataCreate) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/transaction/confirm', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}

export const ExecuteTransaction = async (data?: dataCreate) => {
  // eslint-disable-next-line no-console

  return mainAxios.post('/v1/transaction/execute', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}


export const GetHistoryTransaction = async (params?: dataHistory) => {
  return mainAxios.get('/v1/transaction/history', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateSafe", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.CreateSafe`, error.response);
  });
}

export const GetDetailHistoryTransaction = async (params?: dataDetailHistory) => {
  return mainAxios.get('/v1/transaction/detail', 
    {params}
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response GetDetail", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.GetDetail`, error.response);
  });
}

export const RejectTransaction = async (data?: dataCreate) => {
  return mainAxios.post('/v1/transaction/reject', 
  data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response GetDetail", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.GetDetail`, error.response);
  });
}
