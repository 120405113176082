import { Button } from '@mui/material'
import ModalMain from 'components/ModalMain'
import React, { useCallback } from 'react' //lazy
import { useTranslation } from 'react-i18next'
import { useSendModal } from 'components/ModalSend'


import * as S from './style'

interface Props {
  visible: boolean
  // onOk?: () => void;
  onCancel?: () => void
}

function ModalCollectibles({ visible, onCancel }: Props): React.ReactElement {
  const { t } = useTranslation('common')
  
  const { onPresentInteractionModal, onPresentSendFundsModal, onPresentSendCollectibletModal, onPresentDepositModal } = useSendModal()

  const handleDeposit = useCallback(() => {
    onPresentDepositModal()
    onCancel()
  }, [])

  const handleSendInteraction = useCallback(() => {
    onPresentInteractionModal()
    onCancel()
  }, [])

  const handleSendFunds = useCallback(() => {
    onPresentSendFundsModal()
    onCancel()
  }, [])

  
  const handleSendCollectiblet = useCallback(() => {
    onPresentSendCollectibletModal()
    onCancel()
  }, [])

  return (
    <S.ComponentWp>
      {visible && (
        <ModalMain visible={visible} onCancel={onCancel}>
          <S.ContainerModal>
            <div className="title">{t('Reuse.Send')}</div>
            <div className="container-button">
              <Button className="btn" 
              onClick={handleDeposit}
              > {t('Reuse.Deposit')}</Button>
              <Button className="btn" 
              onClick={handleSendFunds}
              >{t('Reuse.SendFunds')}</Button>
              <Button className="btn"
              onClick={handleSendCollectiblet}
              >{t('Reuse.SendCollectible')}</Button>
              <Button
                onClick={handleSendInteraction}
                className="btn-outline"
              >
              {t('Reuse.ContractInteraction')}
              </Button>
            </div>
          </S.ContainerModal>
        </ModalMain>
      )}
    </S.ComponentWp>
  )
}

export default ModalCollectibles
