import { useCallback } from 'react'
import { submitTransaction } from 'utils/callHelpers'
import { useUserData } from 'state/hooks'

const useContractInteractionEncode = (contractSafe?: any) => {
  // const contractSafe = useContract(abi,safeAddress, cav)
  // const contractDeploy = new cav.klay.Contract(deployAbi as any)
  const { account } = useUserData()
  const handleContractInteraction = useCallback(
    async (dataSelectAbi?: any,valueAddress?: any, dataParam?: string) => {
      try {
        // const data = await cav.abi.encodeFunctionCall(dataSelectAbi[0],dataParam)
       
        const tx =await submitTransaction(contractSafe,account,valueAddress,0 , dataParam)
       
        return {...tx, contractData: dataParam}
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error handleContractInteractionEncode', e)
      }
    },
    [account, contractSafe],
  )

  return { onContractInteractionEncode: handleContractInteraction }
}

export default useContractInteractionEncode
