import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useContractInteraction from 'hooks/useContractInteraction'
import useContractInteractionWrite from 'hooks/useContractInteractionWrite'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import useExecuteTransaction from 'hooks/useExecuteTransaction'

import { useCaver } from 'utils/useCaver'
import { useContract, useContractSafe } from 'hooks/useContract'
import { useGetSafeInfo, useGetBalanceSafe, useGetCurrency, useUserData } from 'state/hooks'
import { CreateTransaction } from 'apis'
import InputLabel from 'components/InputLabel'
import SelectComponent from 'components/Select'
import InfoAddress from 'components/InfoAddress'

import { ButtonFooter } from 'components/Modal'
import { Row } from 'style/DefaultStyled'

import { Modal } from '../Modal'
import { Title, TextToken,  } from './style'

interface Props {
  // eslint-disable-next-line react/require-default-props
  onDismiss?: () => void
}

const CTModal = styled(Modal)`
  @media screen and (max-width: 624px) {
    min-width: 100%;
  }
`

export const NO_DATA = 'no data'


const SendInteraction: React.FC<Props> = ({ onDismiss = () => null }) => {
  const { fiatTotal } = useGetBalanceSafe()
  const { currency } = useGetCurrency()
  const { address: safeAddress, chainId, threshold } = useGetSafeInfo()
  const { account } = useUserData()

  const [valueAddress, setValueAddress] = useState('0xEa0b7a5D2B7B3e86F225C70A8B8D13435C76C75F')
  const [valueABI, setValueABI] = useState('')
  // const [valueMethod, setValueMethod] = useState('')
  const [valueMethod, setValueMethod] = useState(null)
  const [valueCallSmart, setValueCallSmart] = useState<any>('')
  const [step2, setStep2] = useState(false)
  const [dataMethod, setDataMethod] = useState([])
  const [dataSubmit, setDataSubmit] = useState<any>({})

  const cav: any = useCaver()

  let dataAbi = []
  try {
    const objParse = JSON.parse(valueABI)
    const checkAbiArray = Array.isArray(objParse)
    dataAbi = checkAbiArray && objParse.filter((v) => v.type === 'function')
  } catch (error) {
    dataAbi = []
  }

  const checkError = valueABI && dataAbi.length === 0

  // const [checked, setChecked] = useState(false)
  const dataSelectAbi = useMemo(() => {
    // const data = dataAbi.filter((v) => v.name === valueMethod)
    // const data = dataAbi.findIndex((v) => valueMethod)
    const data = valueMethod !== null ? [dataAbi[valueMethod]] : []
    return data
  }, [dataAbi, valueMethod])

  // const dataSelectAbi = valueMethod ? dataAbi[valueMethod] : []

  const dataỊnput = useMemo(() => {
    return dataSelectAbi[0]?.inputs || []
  }, [dataSelectAbi])

  const dataOutputs = useMemo(() => {
    return dataSelectAbi[0]?.outputs || []
  }, [dataSelectAbi])

  const dataValueMethod = useMemo(() => {
    return dataMethod.filter((v) => v.value !== '') || []
  }, [dataMethod])

  const dataSortMethod = useMemo(() => {
    const dataSort =
  // eslint-disable-next-line func-names
      dataMethod.sort(function (a, b) {
        return a.id - b.id
      }) || []
    return dataSort
  }, [dataMethod])

  const dataConvertMethod = useMemo(() => {
    return dataSortMethod.map((v) => v.value)
  }, [dataSortMethod])

  const contractAddress = useContract(dataSelectAbi, valueAddress, cav)

  const contract = useContractSafe()

  const { onContractInteraction } = useContractInteraction(contractAddress)
  const { onContractInteractionWrite } = useContractInteractionWrite(contract)
  const { onConfirmTransaction } = useConfirmTransaction(contract,safeAddress, chainId, dataSubmit?.tx_index, dataSubmit?.id)
  const { onExecuteTransaction } = useExecuteTransaction(contract, safeAddress, chainId, dataSubmit?.tx_index, dataSubmit?.id,dataSubmit?.data)

  const handleContractInteraction = useCallback(async () => {
    try {
      const dataResult = await onContractInteraction(dataSelectAbi[0]?.name,dataConvertMethod)
      setValueCallSmart(dataResult)
    } catch (e) {
      console.error(e)
    }
  }, [dataSelectAbi,onContractInteraction,dataConvertMethod])

  const dataTransaction = {
    chainId,
    safeAddress,
    type: 'CONTRACT_INTERACTION',
    creatorAddress: account,
    contractAddress: valueAddress,
    contractAbi: JSON.stringify(dataAbi),
    contractFunction: valueMethod,
  }

  const handleContractInteractionWirte = useCallback(async () => {
    try {
      const dataResult: any = await onContractInteractionWrite(dataSelectAbi, valueAddress, dataConvertMethod)
      const result: any = await CreateTransaction({
        ...dataTransaction,
        hash: dataResult?.transactionHash,
        contractData: dataResult?.contractData,
      })
      if (result?.status === 200) setDataSubmit(result?.data)
    } catch (e) {
      console.error(e)
    }
  }, [dataSelectAbi, valueAddress, dataConvertMethod])

  useEffect(() => {
    const fetchConfirm = async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const dataConfirm: any = await onConfirmTransaction()
      if (threshold === 1 && dataConfirm?.status === 200) {
        await onExecuteTransaction()
      }
    }
    if (dataSubmit?.tx_hash_create) {
      fetchConfirm()
      setDataSubmit({})
    }
  }, [dataSubmit?.tx_hash_create, threshold])
  
  const contentStep2 = () => {
    return (
      <>
        <Row mb="25px">
          <Title>Sending from</Title>
          <InfoAddress
            // name="Sam"
            address={safeAddress}
            balance={`${fiatTotal} ${currency}`}
            width={42}
          />
        </Row>
        <Row mb="25px">
          <Title>Recipient</Title>
          <InfoAddress
            // name="Sam"
            address={valueAddress}
            // balance="0.005 ETH"
            width={42}
          />
        </Row>
        <Row mb="25px">
          <Title>Method</Title>
          <TextToken>{valueMethod}</TextToken>
          {/* <Row>
            <ImageToken src={kaikas} alt="" />
            <TextToken>(Token ID: 0)</TextToken>
          </Row> */}
        </Row>
        {dataSortMethod.map((v, i) => {
          return (
            <Row mb="25px" key={i}>
              <Title>{v.name}</Title>
              <TextToken>{v.value}</TextToken>
            </Row>
          )
        })}
      </>
    )
  }

  const handleChangeMethod = (e?: any, item?: any, index?: any) => {
    // const value = e.target.value
    const data = {
      ...item,
      value: e,
      id: index,
    }
    let dataV = []
    // const filterDataName = dataMethod.filter((v) => v.name === item.name) || []
    // if (filterDataName.length === 0) {
    //   dataV = [...dataMethod, data]
    // } else {
    //   const filterDataNotName = dataMethod.filter((v) => v.name !== item.name) || []
    //   dataV = [...filterDataNotName, data]
    // }

    const filterDataName = dataMethod.filter((v) => v.id === index) || []
    if (filterDataName.length === 0) {
      dataV = [...dataMethod, data]
    } else {
      const filterDataNotName = dataMethod.filter((v) => v.id !== index) || []
      dataV = [...filterDataNotName, data]
    }
    setDataMethod(dataV)
  }

  const checkReview =
    dataValueMethod.length !== dataỊnput.length || (dataỊnput.length === 0 && dataOutputs.length === 0)

  return (
    <CTModal
      title="Contract interaction"
      step={step2 ? '2 of 2' : '1 of 2'}
      onDismiss={onDismiss}
      footer={
        <ButtonFooter
          cancelButtonProps={{
            onClick: () => {
              return step2 ? setStep2(false) : onDismiss()
            },
            text: step2 ? 'Back' : 'Cancel',
          }}
          confirmButtonProps={{
            onClick: () => {
              if (dataOutputs.length > 0) {
                handleContractInteraction()
              } else {
                // eslint-disable-next-line no-lonely-if
                if (!step2) {
                  setStep2(true)
                } else {
                  handleContractInteractionWirte()
                }
              }
            },
            disabled: checkError || checkReview,
            //   submitting ||
            //   validating ||
            //   ((!valid || !!submitError) && !modifiedSinceLastSubmit) ||
            //   (requiresMethod && !method),
            // status: submitting || validating ? ButtonStatus.LOADING : ButtonStatus.READY,
            // testId: `${isReadMethod(method) ? 'call' : 'review'}-tx-btn`,
            text: dataOutputs.length > 0 || step2 ? 'Call' : 'Review',
          }}
        />
      }
      note={
        step2
          ? `
      You're about to create a transaction and will have to confirm it with your currently connected wallet.
      `
          : ''
      }
    >
      {step2 ? (
        contentStep2()
      ) : (
        <>
          <InfoAddress
            // name="Sam"
            address={safeAddress}
            balance={`${fiatTotal} ${currency}`}
            width={42}
          />

          <InputLabel valueInput={valueAddress} onChange={(e) => setValueAddress(e)} label="Contract address*" />

          <InputLabel
            onChange={(e) => setValueABI(e)}
            valueInput={valueABI}
            label="ABI*"
            type="textarea"
            error={checkError}
          />

          <SelectComponent
            dataSelect={dataAbi}
            onChange={(e) => {
              setValueMethod(e)
              setValueCallSmart('')
            }}
            type="abi"
          />

          {dataỊnput.map((v, i) => {
            return (
              <InputLabel key={i} onChange={(e) => handleChangeMethod(e, v, i)} label={v.name} placeholder={v.type} />
            )
          })}

          {valueCallSmart && <InputLabel label="Call result: " disabled valueInput={valueCallSmart} />}

          {/* <Flex style={{ marginTop: 16, alignItems: 'center' }}>
            <Switch {...label} defaultChecked onChange={handleChange} checked={checked} />
            <TitleCustom>Use custom data (hex encoded)</TitleCustom>
          </Flex> */}
        </>
      )}
    </CTModal>
  )
}

export default SendInteraction
