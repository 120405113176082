import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// import Button from '@mui/material/Button';
import Button from 'components/Button/Button'

import { borderSecondary, connected } from 'theme/variables'
import { ModalFooterProps } from './types'

const StyledModalFooter = styled.div<{ textAlign?: any }>`
  text-align: ${({ textAlign }) => textAlign || 'center'};
  border-top: solid 1px ${borderSecondary};
  padding: 0 24px;
  padding-top: 23px;
  margin-top: 40px;
  @media screen and (min-width: 768px) {
    padding-right: 42px;
    padding-left: 42px;
  }
`

const StyledButton = styled(Button)`
  border-radius: 30px;
  text-transform: capitalize;
  padding: 9px 31px;
  line-height: 1.36;
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  min-width: 110px;
  /* background-color: ${connected}; */
  &.button-cancel {
    margin-right: 10px;
  }
  &[color='error'] {
    background-color: #ef586b;
  }
`

const ModalFooter: React.FC<ModalFooterProps> = ({ children, textAlign }: any) => {
  // const handleDismiss = () => {
  //   setModalNode(undefined);
  //   setIsOpen(false);
  // };

  return (
    <StyledModalFooter textAlign={textAlign} className="modal-footer">
      {children}
      {/* <StyledButton onClick={onDismiss}>Cancel</StyledButton>
      <StyledButton variant="contained" onClick={onConfirm}>Review</StyledButton> */}
    </StyledModalFooter>
  )
}

export default ModalFooter

export enum ButtonStatus {
  ERROR = -1,
  DISABLED,
  READY,
  LOADING,
}

export const ButtonFooter = ({ cancelButtonProps = {}, confirmButtonProps = {}, textAlign }: any) => {
  const { t } = useTranslation('common')
  const {
    disabled: cancelDisabled,
    status: cancelStatus = ButtonStatus.READY,
    text: cancelText = ButtonStatus.LOADING === cancelStatus ? 'Cancelling' : t('Button.Cancel'),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    testId: cancelTestId = '',
    ...cancelProps
  } = cancelButtonProps

  const {
    disabled: confirmDisabled,
    status: confirmStatus = ButtonStatus.READY,
    text: confirmText = ButtonStatus.LOADING === confirmStatus ? t('Button.Submitting') : t('Button.Submit'),
    testId: confirmTestId = '',
    ...confirmProps
  } = confirmButtonProps
  return (
    <ModalFooter textAlign={textAlign}>
      <StyledButton
        variant="secondary"
        scale="md"
        className="button-cancel"
        disabled={cancelDisabled || [ButtonStatus.DISABLED, ButtonStatus.LOADING].includes(cancelStatus)}
        type={cancelProps?.onClick ? 'button' : 'submit'}
        {...cancelProps}
      >
        {cancelText}
      </StyledButton>

      <StyledButton
        type={confirmProps?.onClick ? 'button' : 'submit'}
        disabled={confirmDisabled || [ButtonStatus.DISABLED, ButtonStatus.LOADING].includes(confirmStatus)}
        data-testid={confirmTestId}
        variant="connected"
        {...confirmProps}
        // onClick={onConfirm}
      >
        {confirmText}
      </StyledButton>
    </ModalFooter>
  )
}
