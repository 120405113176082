const tokens = {
  ProxyAdmin: {
    symbol: 'ProxyAdmin',
    address: {
      1001: '0x431ffcD21a1038955EF896D4CaA8451f608a0d45', // testnet
      8217: '0x431ffcD21a1038955EF896D4CaA8451f608a0d45', // mainnet
    },
    decimals: 18,
    projectLink: '',
  },
  MultiSigWallet: {
    symbol: 'MultiSigWallet',
    address: {
      1001: '0x31F51886c5693F0bB2761cb5F72b7A1Ba56c0fF7', // testnet
      8217: '0x31F51886c5693F0bB2761cb5F72b7A1Ba56c0fF7', // mainnet
    },
    decimals: 18,
    projectLink: '',
  },
  klayTicketStaking: {
    symbol: 'KlayTicketStaking',
    address: {
      1001: '', // testnet
      8217: '', // mainnet
    },
    decimals: 18,
    projectLink: '',
  },
}

export default tokens
