import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetSafeInfo, useUserData, useGetNFTSafe } from 'state/hooks'
import InputAddress from 'components/InputAddress'
import SelectComponent from 'components/Select'
import InfoAddress from 'components/InfoAddress'
import useSubmitTransactionSelf from 'hooks/useSubmitTransactionSelf'
import useSubmitTransactionSelfOneOwner from 'hooks/useSubmitTransactionSelfOneOwner'
import { CreateTransaction,CreateTransactionOneOwner } from 'apis'
import { navigateToTx } from 'utils/transactions'
import { tolowercaseAddress } from 'utils/checksumAddress'
import { ButtonFooter } from 'components/Modal'

import NFTimage from 'assets/images/nft.png'

import { Modal } from '../Modal'
import { Title, Row, ImageToken, TextToken } from './style'

interface Props {
  // eslint-disable-next-line react/require-default-props
  onDismiss?: () => void
}

const CTModal = styled(Modal)`
  @media screen and (max-width: 624px) {
    min-width: 100%;
  }
`

const SendCollectiblets: React.FC<Props> = ({ onDismiss = () => null }) => {
  const { t } = useTranslation('common')
  const { account } = useUserData()
  const { nftActive, listFilterNFT, listAddressNFT } = useGetNFTSafe()
  const { address: safeAddress, nameSafe, chainId, threshold } = useGetSafeInfo()
  const [valueAddress, setValueAddress] = useState('')
  const [valueAsset, setValueAsset] = useState('')
  const [valueAssetToken, setValueAssetToken] = useState<any>({})
  const [step2, setStep2] = useState(false)
  const [dataSelect, setDataSelect] = useState<any>([])
  const [txPending, setTxPending] = useState(false)

  // const [checked, setChecked] = useState(false)

  // const dataValueAsset = Object.keys(valueAssetToken).length === 0 ? nftActive : valueAssetToken
  // console.log('dataValueAsset', dataValueAsset)

  const _type = 5
  // eslint-disable-next-line no-unneeded-ternary
  // const valueSymbol = valueAsset ? valueAsset : symbol
  const { onSubmitTransactionSelf } = useSubmitTransactionSelf(_type, valueAddress, valueAssetToken?.address, true)
  const { onSubmitTransactionSelfOneOwner } = useSubmitTransactionSelfOneOwner(_type, valueAddress, valueAssetToken?.address, true)


  const valueIndex = listAddressNFT.findIndex((v) => v === nftActive?.address)
  useEffect(() => {
    if (Object.keys(nftActive).length > 0) {
      const dataList = listFilterNFT[valueIndex]?.map((v: any) => {
        return {
          label: `token ID: ${v?.id}`,
          value: v,
          // value: v,
        }
      })
      setValueAsset(dataList)

      setValueAssetToken(nftActive)
    }
  }, [nftActive, listFilterNFT])

  useEffect(() => {
    const dataList = listFilterNFT.map((v: any) => {
      return {
        label: v[0]?.tokenName,
        value: v,
        // value: v,
      }
    })
    setDataSelect(dataList)
  }, [listFilterNFT])

  const dataTransaction = {
    chainId,
    safeAddress,
    type: 'SEND_NFT',
    creatorAddress: account,
    to: valueAddress,
    token_id: valueAssetToken?.id,
    tokenSymbol: valueAssetToken?.tokenSymbol,
    tokenContract: valueAssetToken?.address,
    token_image: valueAssetToken?.imageUri
  }

  const handleSendNFTOwner = useCallback(async () => {
    try {
      setTxPending(true)
      const dataResult = await onSubmitTransactionSelf(valueAssetToken?.id)
      setTxPending(false)
      if (dataResult?.status) {
        const result: any = await CreateTransaction({...dataTransaction, hash: dataResult?.transactionHash})
        if (result?.status === 200) {
          navigateToTx(safeAddress, result?.data?.id)
          onDismiss()
        }
        // setDataSubmit(result?.data)
      }

      // const dataResult = await onExecuteTransactionSelf()
    } catch (e) {
      console.error(e)
    }
  }, [valueAssetToken?.id, onSubmitTransactionSelf])

  const handleSendNFTOneOwner = useCallback(async () => {
    try {
      setTxPending(true)
      const dataResult = await onSubmitTransactionSelfOneOwner(valueAssetToken?.id)
      setTxPending(false)

      if (dataResult?.status) {
        const result: any = await CreateTransactionOneOwner({...dataTransaction, hash: dataResult?.transactionHash})
        if (result?.status === 200) {
          navigateToTx(safeAddress, result?.data?.id)
          onDismiss()
        }
        // setDataSubmit(result?.data)
      }

      // const dataResult = await onExecuteTransactionSelf()
    } catch (e) {
      console.error(e)
    }
  }, [valueAssetToken?.id, onSubmitTransactionSelfOneOwner])

  const handleSendNFT = threshold === 1 ? handleSendNFTOneOwner : handleSendNFTOwner
  
  const handleCollectble = (item) => {
    const dataList = item?.map((v: any) => {
      return {
        label: `token ID: ${v?.id}`,
        value: v,
        // value: v,
      }
    })
    setValueAsset(dataList)
    setValueAssetToken({})
  }

  const checkAddress = tolowercaseAddress(safeAddress) === tolowercaseAddress(valueAddress)

  const handleConfirm = () => {
    // eslint-disable-next-line no-unused-expressions
    !step2 ? setStep2(true) : handleSendNFT()
    // handleDeposit()
  }

  const contentStep2 = () => {
    return (
      <>
        <Row className="mb">
          <Title>{t('Reuse.SendingFrom')}</Title>
          <InfoAddress
            name={nameSafe}
            address={safeAddress}
            width={42}
          />
        </Row>
        <Row className="mb">
          <Title> {t('Transaction.Recipient')}</Title>
          <InfoAddress
            // name="Sam"
            address={valueAddress}
            width={42}
          />
        </Row>
        <Row className="mb">
          <Title>{valueAssetToken?.tokenName}</Title>
          <Row>
            <ImageToken src={valueAssetToken?.imageUri || NFTimage} alt="" />
            <TextToken>{valueAssetToken?.id}</TextToken>
          </Row>
        </Row>
      </>
    )
  }

  return (
    <CTModal
      title={t('Reuse.SendCollectible')}
      step={step2 ? `2 ${t('Reuse.of')} 2` : `1 ${t('Reuse.of')} 2`}
      onDismiss={onDismiss}
      footer={
        <ButtonFooter
          textAlign="right"
          cancelButtonProps={{
            onClick: () => {
              return step2 ? setStep2(false) : onDismiss()
            },
            text: step2 ? t('Button.Back') : t('Button.Cancel'),
          }}
          confirmButtonProps={{
            // onClick: () => {
            //   return !step2 ? setStep2(true) : onDismiss()
            // },
            onClick: handleConfirm,

            disabled: !valueAddress || !valueAssetToken?.id || txPending || checkAddress,
            //   submitting ||
            //   validating ||
            //   ((!valid || !!submitError) && !modifiedSinceLastSubmit) ||
            //   (requiresMethod && !method),
            // status: submitting || validating ? ButtonStatus.LOADING : ButtonStatus.READY,
            // testId: `${isReadMethod(method) ? 'call' : 'review'}-tx-btn`,
            text: step2 ? t('Button.Submit') : t('Button.Review'),
          }}
        />
      }
      // note={
      //   step2
      //     ? `
      // You're about to create a transaction and will have to confirm it with your currently connected wallet.
      // `
      //     : ''
      // }
    >
      {step2 ? (
        contentStep2()
      ) : (
        <>
          <Row className="mb">
            <InfoAddress
              name={nameSafe}
              address={safeAddress}
              width={42}
            />
          </Row>

          <Row className="mb">
            <Title> {t('Transaction.Recipient')}</Title>
            <InputAddress
              placeholder={t('Transaction.Recipient')}
              onChange={(e) => setValueAddress(e)}
              valueInput={valueAddress}
              // label="Recipient *"
            />
          </Row>

          <Row className="mb">
            <Title>{t('Reuse.Collectible')}</Title>
            <SelectComponent
              text={t('Reuse.Collectible')}
              dataSelect={dataSelect}
              onChange={(e) => handleCollectble(e)}
              defaultValue={listFilterNFT[valueIndex]}
            />
          </Row>

          <Row className="mb">
            <Title>{t('Reuse.TokenID')}</Title>
            <SelectComponent
            text={t('Reuse.TokenID')}
              dataSelect={valueAsset}
              onChange={(e) => setValueAssetToken(e)}
              defaultValue={valueAsset && nftActive}
            />
          </Row>
        </>
      )}
    </CTModal>
  )
}

export default SendCollectiblets
