import styled from 'styled-components'

export const ModalClose = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);
`

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding: 0px 50px;
`

export const Modal = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.26);
  background-color: #fff;
  z-index: 100;
  padding: 32px 24px 32px;
  max-width: 630px;
  min-width: 350px;
  min-height: 150px;
  vertical-align: middle;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    text-align: center;
  }
  @media (min-width: 768px) {
    padding: 41px 35px 50px;
  }
  @media (max-width: 360px) {
    max-width: 95%;
    min-width: auto;
  }
  @media screen and (max-width: 280px) {
    width: 100%;
    padding: 17px 20px 35px;
    /* border-radius: 0px; */
    /* max-width: 250px; */
  }
`

export const ModalCloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  path {
    fill: #bebebf;
  }
`
