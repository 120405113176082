/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = { currency: 'USD' }

export const getCurrencySlice = createSlice({
  name: 'getCurrency',
  initialState,
  reducers: {
    setCurrency: (state, action?: any) => {
      state.currency = action.payload
      // return action.payload
    },
  },
})

// Actions
export const { setCurrency } = getCurrencySlice.actions

export default getCurrencySlice.reducer
