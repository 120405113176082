import { mainAxios } from 'libs/axios'

interface dataAddressBook {
  chainId?:number;
  safeAddress?:string;
  name?:string;
  address?:string;
  ownerAddress?:string;
}

export const CreateAddressBook = async (data: dataAddressBook) => {

  return mainAxios.post('/v1/address/book/create', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response CreateAddressBook", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.Getaddress book`, error.response);
  });
}

export const UpdateAddressBook = async (data: dataAddressBook) => {
  return mainAxios.post('/v1/address/book/update', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response UpdateAddressBook", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.Getaddress book`, error.response);
  });
}

export const DeleteAddressBook = async (data: dataAddressBook) => {
  return mainAxios.post('/v1/address/book/delete', 
    data
  )
  .then((response) => {
    // eslint-disable-next-line no-console
    console.log("response UpdateAddressBook", response);
    return response;
  })
  .catch((error) => {
// eslint-disable-next-line no-console
console.log(`error.Getaddress book`, error.response);
  });
}
