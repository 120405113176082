/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Text } from 'style/DefaultStyled'

import { inputBorder } from 'theme/variables'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import tokenDefault from 'assets/images/token_default.png'
import { getConvertNumber } from 'utils/formatBalance'
import { InputLabelProps } from './types'

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 10px;
`

const StyledRight = styled.div`
  font-family: OpenSans-Regular;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.15;
  letter-spacing: normal;
  text-align: right;
  color: #b2b2b2;
`

const WrapperImageToken = styled.div`
  width: 28px;
  height: 28px;
`

const StyledImageToken = styled.img`
  height: 28px;
`

const WrapperContentToken = styled.div`
  margin-left: 8px;
`


const StyledSelect = styled(Select)`
  border-radius: 30px;
  border-color: ${inputBorder};
  min-height: 45px;
  &.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${inputBorder};
  }
  & .MuiSelect-select {
    padding: 5px 37px;
  }
`

const SelectComponent: React.FC<InputLabelProps> = ({ dataSelect, onChange, defaultValue, type, text }) => {
  const [value, setValue] = useState<any>('')

  useEffect(() => {
    if (defaultValue) {
      const valueDefault = dataSelect?.filter((item: any) => item?.value === defaultValue)
      setValue(valueDefault[0]?.value)
    }
  }, [dataSelect])

  const handleChange = (event: any) => {
    setValue(event?.target?.value)
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(event?.target?.value)
  }
  const renderContent = (item: any) => {
    if (type === 'token') {
      return (
        <Row >
          <WrapperImageToken>
            <StyledImageToken src={item?.tokenInfo?.logoUri || tokenDefault} />
          </WrapperImageToken>
          <WrapperContentToken>
          <Text fontSize='14px' mb='5px' >
             {item?.tokenInfo?.name}
            </Text>
            <Text fontSize='12px' >
             {getConvertNumber(item?.balance)} {item?.tokenInfo?.symbol}
            </Text>
          </WrapperContentToken>
       
        </Row>
      )
    }
    if (type === 'abi') {
      const stateMutability = item?.stateMutability

      return (
        <Row justifyContent="space-between">
          <div>{item.name}</div>
          <StyledRight>
            {stateMutability === 'payable' || stateMutability === 'nonPayable' || stateMutability === 'nonpayable'
              ? 'write'
              : 'read'}
          </StyledRight>
        </Row>
      )
    }
    return item?.label
  }
  return (
    <StyledFormControl>
      <StyledSelect
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        // renderValue={(selected: any) => {
        //   if (selected.length === 0) {
        //     return <div style={{ fontSize: '14px', color: "#c5c5c5" }}>{text}</div>;
        //   }
        //   return selected;
        // }}
      >
        {dataSelect &&
          dataSelect.map((item: any, index: number) => {
            // const dataInput = item?.inputs || []
            // const stateMutability = item?.stateMutability
            return (
              <MenuItem key={index} value={type === 'abi' ? index : item.value} >
                {/* {type === 'abi' ? <Row justifyContent='space-between'>
                  <div>
                  {item.name}

                  </div>
                  <StyledRight>
                  {stateMutability === 'payable' || stateMutability === 'nonPayable'|| stateMutability === 'nonpayable' ? 'write' : 'read'}

                  </StyledRight>
                  </Row> : item.label} */}
                {renderContent(item)}
              </MenuItem>
            )
          })}
      </StyledSelect>
    </StyledFormControl>
  )
}

export default SelectComponent
