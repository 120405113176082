
const getCaver = async () => {
  if (window.klaytn) {
    const accounts = await window.klaytn.enable()
    const networkVersion = await window.klaytn.networkVersion
    
    window.klaytn.autoRefreshOnNetworkChange = true
    return {accounts, networkVersion}
  } 
      return [];
  
}

export default getCaver
