/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import getCaver from 'kaikas'

// import { DataGasLimitState } from '../types'

const initialState: any = { account: '', networkVersion: null, nameOwner: '', linkScan: 'https://baobab.scope.klaytn.com' }

export const userCreateSlice = createSlice({
  name: 'DataUserCreate',
  initialState,
  reducers: {
    setDataUserCreate: (state, action) => {

      return { ...state, ... action.payload}
      // console.log('action', action)
      // state.account = action.payload.account
      // state.networkVersion = action.payload.networkVersion
      // state.nameOwner = action.payload.nameOwner
      // return action.payload
    },
  },
})

// Actions
export const { setDataUserCreate } = userCreateSlice.actions

// Thunks
export const fetchCreateUser = (address?: any) => async (dispatch?: any) => {
  if (address) {
    dispatch(setDataUserCreate({ account: address }))
  } else {
    let result: any = {
      accounts: [''],
      networkVersion: null,
    }
    try {
      result = await getCaver()
    } catch (error) {
      result = {
        accounts: [''],
        networkVersion: null,
      }
    }
    const account = result?.accounts?.[0].toLowerCase() ?? ''
    const networkVersion = result?.networkVersion ?? 1001
    const linkScan = result?.networkVersion === 8217 ? 'https://scope.klaytn.com' : 'https://baobab.scope.klaytn.com'
    dispatch(setDataUserCreate({ account, networkVersion,linkScan }))
  }
}

export default userCreateSlice.reducer
