import React from 'react'
import { Provider } from 'react-redux'
import ModalProvider from 'components/Modal/ModalContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'

// import { LanguageContextProvider } from 'contexts/Localisation/languageContext'
import store from 'state'

const Providers: React.FC = ({ children }) => {
  return (
    // <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <RefreshContextProvider>
          <ModalProvider>
            {children}
          </ModalProvider>
        </RefreshContextProvider>
      </Provider>
    //  </Web3ReactProvider>
  )
}

export default Providers
