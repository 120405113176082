import styled, { css } from 'styled-components'
import color from 'style/color'
import { background } from 'theme/variables'

interface Props {
  colorTab?: string
}

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  height: 100%;
  background-color: ${background};
  display: flex;
  flex-direction: row;
  @media (min-width: 768px) {
    min-height: calc(100vh - 55px);
  }
`
const ContentSideBar = styled.div`
  width: 195px;
  @media (max-width: 768px) {
    display: none;
  }
`

// Content
const Content = styled.div<{isOpenSideBar?: boolean}>`
  width: calc(100% - 195px);
  margin: 0 auto;
  height: auto;
  display: block;
  overflow: ${({isOpenSideBar}) => isOpenSideBar ? 'hidden' : 'auto'};
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const TabMenu = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 28px;
  background-color: ${color.tabNavigation};
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.32);
  margin-bottom: 26px;
  outline: none;

  @media screen and (max-width: 375px) {
    height: 50px;
  }
`

const TabItem = styled.div<Props>`
  min-width: 32%;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${color.tabNavigationHide};
  font-family: 'GmarketSansTTF-Medium';
  outline: none;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  ${({ colorTab }) =>
    colorTab
      ? css`
          background: radial-gradient(rgba(0, 0, 0, 0.32) 50px, ${colorTab});
          box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.48);
        `
      : '#212121'};

  @media screen and (max-width: 375px) {
    height: 30px;
  }
`

export { Wrapper, TabMenu, Content, TabItem, ContentSideBar }
