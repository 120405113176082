/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { GetByOwner } from 'apis'

const initialState: any = { dataList: [] }

export const getListOwnerSmartSlice = createSlice({
  name: 'getListOwnerSmart',
  initialState,
  reducers: {
    setListOwnerSmart: (state, action?: any) => {
    // console.log('action', action)
      state.dataList = action.payload
    },
  },
})

// Actions
export const { setListOwnerSmart } = getListOwnerSmartSlice.actions

// Thunks
export const fetchListOwnerSmart = (params) => async (dispatch?: any) => {
  // dispatch(setBalance(data))
  try {
    const result: any = await GetByOwner(params)
    const data = result?.data ?? []
    // console.log('data', data)
    dispatch(setListOwnerSmart(data))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`fetchListOwnerSmart`, error)
  }
}

export default getListOwnerSmartSlice.reducer
