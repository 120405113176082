/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = { chainId: '', name: '' }

export const getChainIdSlice = createSlice({
  name: 'getChainId',
  initialState,
  reducers: {
    setChainId: (state, action?: any) => {
      return action.payload
    },
  },
})

// Actions
export const { setChainId } = getChainIdSlice.actions

// Thunks
export const fetchChainId = () => async () => {
  // dispatch(setBalance(data))
}

export default getChainIdSlice.reducer
