import kaikas from "./icons/kaikas.png";
import { Config, ConnectorNames } from "./types";
import DCENT from "./icons/Full-colored_DCENT_mint_logo.png";

const connectors: Config[] = [
  {
    title: "KaiKas",
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: kaikas,
    connectorId: ConnectorNames.KAIKAS,
  },
  {
    title: "D'cent",
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: DCENT,
    connectorId: ConnectorNames.DECENT,
  },
  {
    title: "D'cent",
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: DCENT,
    connectorId: ConnectorNames.DECENT_APP,
  },
  // {
  //   title: "Klip",
  //   // eslint-disable-next-line react/react-in-jsx-scope
  //   icon: klip,
  //   connectorId: ConnectorNames.KLIP,
  // },
  // {
  //   title: "WalletConnect",
  //   icon: WalletConnect,
  //   connectorId: ConnectorNames.WalletConnect,
  // },
  // {
  //   title: "Binance Chain Wallet",
  //   icon: BinanceChain,
  //   connectorId: ConnectorNames.BSC,
  // },
  // {
  //   title: "SafePal Wallet",
  //   icon: SafePalWallet,
  //   connectorId: ConnectorNames.Injected,
  // },
];

export default connectors;
export const connectorLocalStorageKey = "connectorIdKlaySafe";
